.compRoot {
  display: flex;
  padding: 8em 4em;
  background: linear-gradient(
      rgba(50, 37, 46, 0.8),
      rgba(36, 51, 73, 0.9),
      rgba(67, 59, 53, 0.9)
    ),
    url(../../assets/jpeg/pexels-pixabay-301920.jpg);
  background-size: cover;
  justify-content: space-around;
  background-position: center;
  background-attachment: fixed;
  min-height: 100vh;
}
.leftWrapper {
  align-items: center;
  padding-top: 6%;
}

.rightWrapper h4 {
  font-size: 0.8em;
  font-weight: 600;
  color: var(--color-white);
}
.rightWrapper h2 {
  font-size: 1.3em;
  font-weight: 700;
  margin-bottom: 20px;
  color: var(--color-white);
}
.bannerImage {
  object-fit: contain;
  width: 100%;
}
/* -----------------------------------------------------------------------------
                          MEDIA QUARY
----------------------------------------------------------------------------- */

@media (max-width: 767.98px) {
  .compRoot {
    padding: 2em 1.5em;
  }
  .leftWrapper {
    display: none;
  }
  .rightWrapper {
    flex: 1;
  }
}

@media (min-width: 768px) and (max-width: 1199.98px) {
}

@media (min-width: 1200px) {
}
