.pageRoot {
  background-color: #ffffff;
  border-radius: 10px;
  width: 57vw;
  padding: 10px;
}
.title {
  font-size: 18px;
  font-weight: 700;
}
.termsBody {
  padding: 10px;
}
.termsBody p {
  font-size: 0.9em;
  font-weight: 400;
}

/* Media Queries */
@media (max-width: 767.98px) {
  .pageRoot {
    border-radius: 0;
    width: 100%;
    padding: 0;
  }
  .termsBody p,
  .title {
    margin-left: -10px;
  }
}

@media (min-width: 768px) and (max-width: 991.98px) {
}

@media (min-width: 992px) and (max-width: 1199.98px) {
}

@media (min-width: 1200px) and (max-width: 1400px) {
}
