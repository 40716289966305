@import url("https://fonts.googleapis.com/css2?family=DM+Sans&display=swap");
@import url("https://fonts.googleapis.com/css2?family=DM+Sans:wght@400;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Nunito:wght@300;400&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Fredoka+One:wght@300;400&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Oswald:wght@200;400;600&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Inter&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Asap:wght@600&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Quicksand&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Acme&family=Cabin+Sketch&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Montserrat+Alternates&display=swap");

* {
  margin: 0;
  padding: 0;
  font-family: "DM Sans", sans-serif;
}
html body {
  cursor: default;
  user-select: none;
}
/* * ::-webkit-scrollbar {
  display: none;
} */

/* For Webkit browsers (Chrome, Safari) */
::-webkit-scrollbar {
  width: 6px;
  height: 6px;
}

::-webkit-scrollbar-thumb {
  background-color: #888;
  border-radius: 3px;
}

/* Example styles for Firefox scrollbar */
* {
  scrollbar-color: #888 transparent;
  scrollbar-width: thin;
}

.under {
  text-decoration: none !important;
}
.App {
  position: relative;
}
.recharts-legend-wrapper {
  position: absolute;
  width: 370px;
  height: auto;
  left: 0px;
  bottom: 100% !important;
  top: 0px !important;
  display: flex;
  justify-content: flex-start;
}
