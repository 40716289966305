  .otpInput {
    width: 45px !important;
    border: 1px solid var(--color-neutral-black);
    height: 50px;
    border-radius: 5px;
    margin-right: 10px;
    font-weight: 500;
    font-size: 1.5em;
    text-align: center;
    /* background-color: , */
    color: #FFFFFF,
  }

  /* -----------------------------------------------------------------------------
                          MEDIA QUARY
----------------------------------------------------------------------------- */

@media  (max-width: 960px) {
.otpInput{
   width: 35px !important;
  height: 40px;
}
}





