.lg {
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: 20px 40px;
}

.title {
  font-size: 1.2em;
  text-transform: initial;
  font-weight: 600;
}
.pre {
  white-space: pre-wrap;
  word-wrap: break-word;
}
.img {
  width: 100%;
  max-height: 300px;
  overflow: hidden;
  object-fit: cover;
  margin-bottom: 10px;
}
.lbl {
  text-align: left;
}

.row {
  display: flex;
  gap: 20px;
}
.titleInput,
.passmark {
  height: 50px;
  text-indent: 10px;
  margin-bottom: 10px;
}
.passmark {
  width: 300px;
}
.addQuestionBtn {
  height: 50px;
  padding: 5px 30px;
  margin-top: 25px;
  border: none;
  background-color: #3e68b6;
  color: #fff;
}
.image {
  position: relative;
}
.imageUpload {
  font-size: 1.5em;
  position: absolute;
  top: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.688);
  padding: 2px 10px;
  color: #fff;
}
.imageUpload label {
  cursor: pointer;
}
.paragraphTextAare {
  height: 120px;
  width: 100%;
  padding: 10px;
  margin-bottom: 10px;
}
.button {
  margin-top: 20px;
  display: flex;
  justify-content: flex-end;
}
.btn {
  min-width: 120px;
  padding: 5px 10px;
  margin: 10px 0 10px 0;
  background-color: transparent;
  border: solid 2px #000;
  color: #000;
  font-size: 1em;
}

.btn:hover {
  transform: scale(1.1);
}

/* Question List */
.questionList {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 20px;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
}
.action {
  display: flex;
  width: 100%;
  justify-content: space-between;
}
.action p {
  font-weight: bold;
}
.action span {
  padding-left: 20px;
  font-size: 1.5em;
  cursor: pointer;
}
ul.options {
  list-style: none;
  width: 100%;
  text-align: left;
}

ul.options li {
  padding: 10px;
  border: solid 1px #ccc;
  margin-top: 5px;
}
ul.options li.selected {
  background-color: #5ba7ff;
  color: #000;
  border: none;
}

/* Partial form */
.formModal {
  top: 5px;
  left: 20px;
  position: absolute;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  background-color: #fff;
  width: 90%;
  padding: 20px;
}
.formClose {
  display: flex;
  justify-content: flex-end;
  font-size: 2em;
}
.formClose span {
  cursor: pointer;
}
.form {
  padding: 20px;
  width: 100%;
}
.saveQst {
  display: flex;
  justify-content: flex-end;
  margin-right: 1em;
}
.option {
  display: flex;
  align-items: center;
  gap: 20px;
  margin-top: 10px;
}
.option .label {
  font-size: 1.5em;
  font-weight: bold;
}
.option .input {
  height: 60px;
  width: 80%;
  text-indent: 10px;
}

/* Optionally, you can hide the default checkbox */
.customCheckbox input {
  position: relative;
  width: 40px;
  height: 40px;
}
.deleteOption {
  font-size: 2em;
  color: rgb(171, 59, 59);
  margin-top: -10px;
}
