.topbarField {
  display: flex;
  position: relative;
  justify-content: space-between;
  padding: 1em 5%;
  align-items: center;
  background: #f5f5f5;
  z-index: 10px;
}
.topbarContent {
  font-size: 2rem;
  font-weight: 800;
  color: #595798;
}
.leftTopbar {
  display: flex;
  align-items: center;
  gap: 2.2rem;
  cursor: pointer;
  flex: 1px;
}
.rightTop {
  display: flex;
  align-items: center;
  gap: 2em;
  position: relative;
  justify-content: flex-end;
  flex: 1;
}
.bell {
  position: relative;
}
.searchWrap {
  flex: 1px;
}
.searchInput {
  height: 45px;
  border-radius: 10px;
}

.profileCard {
  position: absolute;
  right: 2%;
  top: 90%;
  transition: all 0.5s ease-in-out;
}

.envelopeIcon {
  font-size: 1.5em;
}

@media screen and (max-width: 960px) {
  .topbarContent {
    font-size: 1.6rem;
    font-weight: 700;
  }

  .rightTop {
    padding-left: 2%;
    gap: 6px;
  }
  .profileCard {
    position: absolute;
    left: 38%;
    top: 100%;
  }
  .topbarField {
    padding: 1em 0 1em 1.2em;
    width: 100vw;
  }
}
