.modalBackdrop {
  height: 100vh;
  position: fixed;
  top: 0;
  width: 100%;
  background-color: rgba(255, 255, 255, 0.582);
}
.modal {
  position: absolute;
  top: 20%;
  left: 10%;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  border-radius: 6px;
  padding: 40px;
  background-color: #fff;
}
.formGroup {
  width: 300px;
  margin-bottom: 15px;
}
.cls {
  z-index: 100;
}
.subj {
  z-index: 90;
}
