.compRoot {
  position: fixed;
  right: 15px;
  top: 28%;
  max-height: 320px;
  width: 65px;
  background-color: #fff;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  border-radius: 4px;
  z-index: 2;
  transition: 0.6s ease-in-out;
}
.pathWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 10px 5px;
  justify-content: space-evenly;
  height: 100%;
  transition: 0.6s ease-in-out;
}
.menuIcon {
  font-size: 1.7em;
  color: #555;
}
.navPath:hover .menuIcon {
  color: #3c43cf;
}
.navPath:hover {
  background-color: #ececf7;
  border-radius: 5px;
}
.navPath {
  cursor: pointer;
  padding: 10px;
  position: relative;
}
.navName {
  position: absolute;
  left: -300%;
  background-color: #f7f7f7;
  top: -10px;
  padding: 25px 20px;
  color: #3c43cf;
  min-width: 130px;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  display: none;
  text-align: center;
}
.navPath:hover .navName {
  display: block;
}
.activeLink {
  background-color: #ececf7;
  border-radius: 5px;
  padding: 10px;
}
.activeLink .menuIcon {
  color: #3c43cf;
}
.menuToggleButton {
  font-size: 2em;
  background-color: transparent;
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding: 10px 5px;
  color: #222;
  background-color: #ececf7;
  border-radius: 5px;
  transition: 0.6s ease-in-out;
}

@media (max-width: 767.98px) {
  .compRoot {
    right: 5px;
    width: 60px;
  }
  .componentRootCollapse {
    right: -5px;
    width: 60px;
    background-color: #ffffff7d;
  }
  .menuIcon {
    font-size: 1.6em;
  }
  .navName {
    left: -340%;
    top: -10px;
    font-size: 14px;
    padding: 16px 10px;
    min-width: 120px;
  }
  .menuToggleButton {
    font-size: 1.7em;
  }
  .menuToggleCollapsed {
    justify-content: flex-start;
    padding: 10px 2px 10px 10px;
    transition: 0.6s ease-in-out;
    background-color: transparent;
  }
  .menuToggleCollapsed:hover {
    background-color: #ececf7;
  }
}

@media screen and (min-width: 767.99px) and (max-width: 1000px) {
}
