.rootComp {
  padding: 2em 4em;
  background-color: #f4f5f9;
  min-height: 100vh;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 20px;
}

.item {
  padding: 2em;
  border: 1px solid #cccccc;
  transition: 3ms;
  background-color: #fff;
}

.item h2 {
  font-size: 1.3em;
  font-weight: 700;
}

.item1 {
  background-color: #6155d7;
  color: #fff;
  grid-column: 1 / 3;
  grid-row: 1;
}

.item2 {
  grid-column: 3 / 4;
  grid-row: 1;
}

.item3 {
  grid-column: 1 / 2;
  grid-row: 2;
}

.item4 {
  grid-column: 2 / 3;
  grid-row: 2;
}

.item5 {
  grid-column: 3 / 4;
  grid-row: 2;
}

/* Media Queries */
@media (max-width: 767.98px) {
  .rootComp {
    padding: 0;
    grid-template-columns: 1fr;
    gap: 0;
  }
  .item {
    padding: 2em;
    border: none;
    border-bottom: 1px solid #cccccc;
  }
  .item1 {
    grid-column: 1;
    grid-row: 1;
  }
  .item2 {
    grid-column: 1;
    grid-row: 2;
  }
  .item3 {
    grid-column: 1;
    grid-row: 3;
  }
  .item4 {
    grid-column: 1;
    grid-row: 4;
  }
  .item5 {
    grid-column: 1;
    grid-row: 5;
  }
}

@media (min-width: 768px) and (max-width: 1199.98px) {
  .rootComp {
    padding: 2em;
    grid-template-columns: 1fr 1fr;
    gap: 20px;
  }
  .item {
    padding: 2em;
    border: 1px solid #cccccc;
  }
  .item1 {
    grid-column: 1 / 3;
    grid-row: 1;
  }
  .item2 {
    grid-column: 1 / 2;
    grid-row: 2;
  }
  .item3 {
    grid-column: 2 / 3;
    grid-row: 2;
  }
  .item4 {
    grid-column: 1 / 2;
    grid-row: 3;
  }
  .item5 {
    grid-column: 2 / 3;
    grid-row: 3;
  }
}

@media (min-width: 1200px) {
  .rootComp {
    padding: 2em 4em;
    grid-template-columns: 1fr 1fr 1fr;
  }
  .item {
    padding: 2em;
    border: 1px solid #cccccc;
  }
  .item1 {
    grid-column: 1 / 3;
    grid-row: 1;
  }
  .item2 {
    grid-column: 3 / 4;
    grid-row: 1;
  }
  .item3 {
    grid-column: 1 / 2;
    grid-row: 2;
  }
  .item4 {
    grid-column: 2 / 3;
    grid-row: 2;
  }
  .item5 {
    grid-column: 3 / 4;
    grid-row: 2;
  }
}
