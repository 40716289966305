.audioBook {
  background: white;
  padding: 50px;
  height: 100%;
}
.imgContainer {
  display: flex;
  justify-content: center;
  padding-top: 0.5em;
  padding-bottom: 1em;
}
.header{
    line-height: 10px;
}
.imgBook {
  width: 200px;
}
.waveFormContainer{
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  height: 100px;
  width: 100%;
  background: transparent;
  gap: 2rem;
}
.wave{
  width: 100%;
  height: 90px;
}
/* .playButton{
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  background: #efefef;
  border-radius: 50%;
  border: none;
  outline: none;
  cursor: pointer;
  padding-bottom: 3px;
} */

.audioKeys{
  display: flex;
  justify-content: space-between;
  padding: 20px;
}
.controlBtn{
  font-size: 18px;
  width: 40px;
  height: 40px;
  background: #efefef;
  border-radius: 50%;
  border: none;
  outline: none;
  cursor: pointer;
  text-align: center;
}
.title{
  padding-top: 20px;
  font-size: 1.2em;
  font-weight: 800;
  color: var( --color-black);
  /* margin-bottom: 0px; */
}
.controlBtn:hover {
  background: #ddd;
}
.loader{
  height: 100%;
  width: 100%;
}
