.container {
  padding: 1em 7%;
  background: #ffffff;
  width: 100%;
}

.header_container{
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 20px 0;
}

.header_container > h5{
  font-weight: bolder;
  font-size: 20px;
}

.header_container > span {
  cursor: pointer;
}

.image_container{
  background: #F5F5F5;
  padding: 15px;
  border-radius: 10px;
}

.image_container > img{
  width: 300px;
  height: 100%;
  object-fit: cover;
  border-radius: 10px;
}

.image_placeholder{
  position: relative;
  background: #203b52;
  width: 300px;
  min-height: 300px;
}

.image_container > div{
  position: absolute;
  color: white;
  font-size: 100px;
  text-transform: uppercase;
  top: 50%; /* Move the top of the div to the middle of the parent */
  left: 50%; /* Move the left side of the div to the middle of the parent */
  transform: translate(-50%, -50%);

}

.section{
  display: flex;
  gap: 20px;
}

.personal_profile_container{
  width: 100%;
}
.personal_profile_container > div{
  display: grid;
  gap: 30px;
  margin: 20px 0;
  justify-content: space-between;
  grid-template-columns: 1fr 1fr 1fr;
}

.personal_profile_container > h4 {
  font-weight: bolder;
}

.form_container > label{
  display: block;
  color: #444444;
  font-size: 14px;
}

.gap_30{
  gap: 40px;
}

.margin_30{
  margin: 30px 0;
}

.checkbox_container{
  display: flex;
  gap: 10px;
  align-items: center;
}


.checkbox_container > input[type="checkbox"]:checked{
  accent-color: #595798;
}

.form_container{
  width: 100%;
}

.input_container{
  padding: 10px 15px;
  border-radius: 5px;
  background: #F5F5F5;
  display: flex;
  align-items: center;
  gap: 10px;
  width: 100%!important;
}

.input_container > svg {
  font-size: 20px;
  color: #5A5858;
}

.input_container > input, .input_container > select {
  border: none;
  outline: none;
  padding: 5px 0px;
  width: 100%;
  background: none;
  font-weight: bold;
  color: #444444;
}

.action_btn{
  padding: 10px 25px;
  border-radius: 5px;
  border: none;
  cursor: pointer;
  background: #595798;
  color: #ffffff;
}

.btn_cancel{
  background: none;
  color: #595798;
  border: 1px solid #595798;
  margin: 0 10px;
}

.background_danger{
  background: #FF0000;
}

.profileContent {
  background: #ffffff;
  padding: 2em 4em;
  margin-bottom: 20px;
  min-width: 630px;
  border-radius: 10px;
  box-shadow: rgba(0, 0, 0, 0.15) 0px 5px 15px 0px;
  flex: 1 1;
}
.title {
  margin-bottom: 30px;
  font-family: "Fredoka One";

  color: var(--color-light-black);
}
.editBtn {
  cursor: pointer;
  color: #555;
  display: flex;
  justify-content: flex-end;
}
.bio {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
  font-size: 1.3em;
}
.bioTag {
  height: 100px;
  width: 100px;
  margin-right: 10px;
  font-size: 2em;
}
.surname,
.studentId {
  font-variant: small-caps;
  color: #918e8e;
}
.studentId {
  font-size: 0.8em;
}
.row {
  display: flex;
  justify-content: space-between;
}
.col {
  display: flex;
  flex-direction: column;
  margin: 10px;
}
.mb2 {
  margin-bottom: 10px;
  font-size: 1rem;
  color: var(--color-black);
}
.profileImg {
  height: 100px;
  width: 100px;
  border-radius: 50%;
}
.profilePage {
  flex: 1px;
}
.languageSelection {
  display: flex;
  justify-content: space-between;
}
.accountSetting {
  margin-bottom: 2.5rem;
}
.notification {
  font-size: 16px;
  font-weight: 600;
}
.notificationContent {
  display: flex;
  gap: 10px;
}
.link {
  text-decoration: none;
  padding-bottom: 5px;
}
.delete {
  color: var(--delete-color);
}

.settings {
  font-size: 14px;
}
.notificationToggle {
  width: 40px !important;
  height: 20px !important;
}
.textArea {
  outline: none;
  border-radius: 10px;
  width: 350px;
  text-indent: 8px;
  height: 100px;
}
.userDetails {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.userDetails h3 {
  font-size: 1.2rem;
  font-weight: 600;
}

.userDetails p {
  font-size: 1rem;
  color: var(--color-primary);
}
.aboutField h4 {
  font-size: 16px;
  font-weight: 300;
}
.courseList1 h4 {
  font-size: 16px;
  font-weight: 500;
  display: inline;
}
.courseList {
  gap: 10px;
  display: flex;
}
.courseBtn {
  padding: 8px;
  border: none;
  color: var(--color-white);
  background-color: var(--color-primary);
  border-radius: 5px;
  margin-top: 10px;
  margin-bottom: 5px;
  cursor: pointer;
}
.courseBtn:hover {
  transition: 0.7s;
}
.calenderField {
  display: flex;
  align-items: center;
  gap: 10px;
  padding-top: 12px;
  padding-bottom: 10px;
}
.contactInfo {
  display: flex;
  justify-content: space-between;
  padding-top: 1rem;
}

.contactInfo h4 {
  font-size: 16px;
  font-weight: 600;
}
.contactInfo p {
  color: var(--color-primary);
}
.userInfo {
  display: flex;
  gap: 10px;
}
.userInfo p {
  font-size: 16px;
}
.infoIcon {
  font-size: 20px;
}
.userProfile {
  display: flex;
  position: relative;
  margin: 50px;
  gap: 1.6rem;
}
.language {
  padding: 4px 0px 4px 30px;
  outline: none;
  border: 1px solid #ccc;
  border-radius: 3px;
}

.globe {
  position: absolute;
  font-size: 1.8rem;
  z-index: 100;
  padding-left: 6px;
  color: var(--color-black);
}
.topCourse {
  width: 270px;
  padding: 2em;
  border-radius: 10px;
  background: #ffffff;
  box-shadow: rgba(0, 0, 0, 0.15) 0px 5px 15px 0px;
  height: 100%;
  position: sticky;
  top: 0;
}

.back_btn{
    margin-right: auto;
    display: flex;
    gap: 10px;
    align-items: center;
    background-color: transparent;
    border: none;
    font-size: 0.95em;
}

@media screen and (max-width: 968px) {
  .topCourse {
    display: none;
  }
 .profileContent{
   padding: 2em 1em;
  min-width: 0px;
 }
}


@media screen and (max-width : 650px) {

  .section {
    display: block;
  }

  .personal_profile_container > div{
    display: block;
  }

  .personal_profile_container > h4 {
    font-size: 30px;
  }

  .form_container{
    margin: 20px 0;
  }

  .input_container{

    width: 100%!important;
  }

  .image_container{
    margin: 0 auto;
    display: none;
  }

  .action_section{
    text-align: center;
  }

  .action_section .action_btn{
    margin: 0 5px;
  }

  .action_btn{
    padding: 10px;
    border-radius: 5px;
  }

  .image_container{
    border-radius: 50%;
  }
}

@media screen and (min-width : 921px)  and (max-width : 1000px){
  .bio_section{
    display: block!important;
  }


  .image_container{

    border-radius: 50%;
    margin: 50px auto;
  }

  .personal_profile_container > div {
    display: grid;
    grid-template-columns: 1fr 1fr;
  }
}

@media screen and (min-width : 651px)  and (max-width : 920px){
  .bio_section{
    display: block!important;
  }


  .image_container{

    border-radius: 50%;
    margin: 50px auto;
  }

  .personal_profile_container > div {
    display: grid;
    grid-template-columns: 1fr 1fr;
  }
}