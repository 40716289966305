.note {
  width: calc(90% / 3);
  height: 260px;
  overflow: hidden;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  padding: 20px;
  border-radius: 5px;
  position: relative;
}
.title {
  font-weight: 600;
}
pre.content {
  white-space: pre-wrap; /* Allow text to wrap */
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 4; /* Number of lines to display before truncating */
  -webkit-box-orient: vertical;
  word-wrap: break-word; /* Optional: Wrap long words if needed */
}
.actions {
  display: flex;
  gap: 10px;
  justify-content: flex-end;
  position: absolute;
  bottom: 15px;
  right: 20px;
  cursor: pointer;
}
.actions i {
  padding: 5px;
  width: 35px;
  height: 35px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #fff;
  border-radius: 100px;
}

.actions i:hover {
  background-color: #d7d6d6;
}
.dateCreated {
  font-size: 12px;
  color: #333;
  margin-top: -10px;
}

/* Media Queries */
@media (max-width: 767.98px) {
  .note {
    width: 100%;
    height: 260px;
    overflow: hidden;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    padding: 20px;
    border-radius: 5px;
    position: relative;
  }
}

@media (min-width: 768px) and (max-width: 991.98px) {
  .note {
    width: 45%;
  }
}
