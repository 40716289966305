.inputField {
  border-radius: 4px;
  border: 1px solid var(--color-neutral-black);
  width: 100%;
  text-indent: 4px;
  padding: 4px;
  margin-bottom: 1rem;
  outline: none;
}
.header{
  line-height: 5px;
}
.editBio{
  font-size: 1.5rem;
  font-weight: 800;
}
/* .inputWrapper{
  margin-bottom: 8px;
} */
.inputWrapper{
  margin: 15px 0px;
}
.location{
    width: 100%;
    padding: 10px 3px;
    outline: none;
    border: 1px solid black;
    border-radius: 5px;
}
.countryCode{
  text-align: left;
   padding: 10px 5px;

}
.saveWrapper{
  margin-top: 2rem;
}
.save:hover {
  transform: scale(0.98);
  transition: all .3s ease;
}
.save{
  margin-bottom: 1rem;
}
