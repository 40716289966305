.pageRoot {
  display: flex;
  flex-direction: column;
  background-color: #fff;
  height: 88vh;
  overflow: scroll;
  padding: 0 5em;
  padding-bottom: 10px;
}
.searchNav {
  padding-top: 30px;
}
.pageBody {
  flex: 1;
  margin-right: 1em;
}

.catalogItems {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 20px;
}

.skeletonLogLoader {
  height: 250px;
}
.item {
  padding: 20px;
  min-width: calc(98% / 3);
  box-shadow: rgba(0, 0, 0, 0.05) 0px 6px 24px 0px,
    rgba(0, 0, 0, 0.08) 0px 0px 0px 1px;
  border-radius: 4px;
}
.row {
  display: flex;
  gap: 10px;
}
.termsLabel {
  font-size: 0.9em;
}
.subjectTitle {
  font-weight: 600;
}
.term,
.secondTerm,
.thirdTerm {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  padding: 8px 10px 5px 10px;
  font-weight: 800;
  font-size: 0.9rem;
  border: solid 1px #4f6486;
  cursor: pointer;
}
.secondTerm {
  border: solid 1px #775dd0;
}
.thirdTerm {
  border: solid 1px #c5674a;
}
.searchContainer {
  position: relative;
}
.term:hover,
.secondTerm:hover,
.thirdTerm:hover {
  transform: scale(1.1);
}

@media screen and (max-width: 650px) {
  .pageRoot {
    padding: 0 0 1em 1.5em;
  }
  .catalogItems {
    display: grid;
    grid-template-columns: 1fr;
  }
  .pageBody {
    margin-top: 1em;
  }
  .searchNav {
    width: 95%;
  }
}

@media screen and (min-width: 651px) and (max-width: 1000px) {
  .pageRoot {
    padding: 0 2em;
  }
  .catalogItems {
    display: grid;
    grid-template-columns: 1fr 1fr;
  }
}
