/* General */
.xs,
.lg {
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: 20px;
}
.xs .page,
.lg .page {
  display: flex;
  gap: 20px;
}
.xs .half {
}

.lg .half {
  flex: 1;
}
.xs ul.indicators li,
.lg ul.indicators li {
  height: 5px;
  background-color: #d6dded;
  border-radius: 100px;
  content: "";
}
ul.indicators li.answered {
  background-color: #5073f4;
}
ul.indicators {
  list-style: none;
  display: flex;
  gap: 10px;
}

.xs .option,
.lg .option {
  display: flex;
  align-items: center;
}

.xs .optionGroup {
  display: none;
}

/* Thumnail view */
.xs ul.indicators li {
  width: 25%;
}

.xs p {
  font-size: 0.7em;
  width: 100%;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
}
.xs .img {
  width: 90%;
  object-fit: cover;
  margin-bottom: 5px;
  margin: 10px;
}
.xs .option {
  gap: 10px;
  font-size: 0.8em;
}
.xs .optionLabel {
  padding: 3px 8px;
  background-color: #d6dded;
  color: #5073f4;
  border-radius: 100px;
}
/* Full page view */
.lg {
  padding: 20px 40px;
}
.lg .page {
  gap: 100px;
}
.lg ul.indicators li {
  width: 15%;
}
.lg p {
  font-size: 1em;
  width: 100%;
}
.lg .pre {
  white-space: pre-wrap;
  word-wrap: break-word;
}
.optionGroup {
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
  padding: 20px;
  min-height: 350px;
}
.lg .option {
  gap: 10px;
  font-size: 1em;
  /* font-weight: bold; */
  padding: 15px;
  border: 1px solid #ccc;
  margin-bottom: 10px;
  border-radius: 6px;
}
.lg .option:hover,
.lg .selected {
  background-color: #5073f4;
  color: #ffffff;
}
.lg .optionLabel,
.lg .optionLabel .selected {
  width: 40px;
  height: 40px;
  background-color: #d6dded;
  color: #496ae1;
  border-radius: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.lg .selected .optionLabel {
  background-color: #fff;
}
.lg .img {
  width: 80%;
  object-fit: cover;
  margin-bottom: 10px;
}
.lg ul.indicators {
  margin-left: -1.5em;
}
/* Media Queries */
@media (max-width: 767.98px) {
  .lg .page {
    flex-direction: column;
    gap: 20px;
  }

  .lg .half {
    flex: 1;
  }

  .lg ul.indicators {
    margin-left: -1.7em;
  }
  .lg {
    padding: 20px 2.2em;
  }
  .lg .page {
    gap: 2em;
  }

  .optionGroup {
    box-shadow: none;
    padding: 0;
  }

  .lg .img {
    width: 90%;
  }
}
