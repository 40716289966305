.reader {
  margin-left: 20px;
  width: 65vw;
  height: 550px;
  position: relative;
  font-size: 10px;
}
.header {
  margin-bottom: 10px;
  font-size: 1rem;
  font-weight: normal;
  text-transform: uppercase;
  color: #999;
}

.body {
  /* white-space: pre-wrap;
  word-wrap: break-word;  */
  height: 430px;
  overflow: auto;
  font-size: 1.3rem;
  text-align: justify;
  margin-left: -40px;
}
.footer {
  position: absolute;
  bottom: 1rem;
  right: 0;
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: center;
}
.pageHolder {
  margin-right: auto;
  font-size: 0.8rem;
  color: #333;
  display: flex;
  gap: 10px;
  align-items: center;
}
.pageHolder label {
  padding-top: 8px;
}
.menu {
  color: #c76b36;
  font-size: 1.8rem;
  cursor: pointer;
}
.menu:hover {
  transform: scale(1.2);
}

.btn,
.skip {
  padding: 5px 10px;
  width: 8rem;
  border: solid 2px #6b65af;
  color: #48437d;
  font-size: 1.1rem;
  background-color: transparent;
  margin-left: 10px;
}
.btn:hover {
  background-color: #6b65af;
  color: #fff;
}
.skip {
  background-color: rgba(209, 35, 35, 0.841);
  border-color: rgba(209, 35, 35, 0.841);
  color: #fff;
}
.menuUI {
  background-color: rgb(72, 67, 125);
  width: 280px;
  padding-left: 10px;
  position: absolute;
  height: 91.9vh;
  top: 0;
  left: 0;
  overflow-y: auto;
}
.closeMenu {
  color: #fff;
  text-align: right;
  font-size: 1.5rem;
  cursor: pointer;
}
ol.menuList {
  /* list-style: none; */
  padding: 20px;
  color: #d0cee3;
  margin-top: 20px;
}
ol.menuList li {
  margin-top: 10px;
  /* border-bottom: 1px dashed #999; */
  padding: 10px 0px;
  font-size: 0.9rem;
  cursor: pointer;
}

.end {
  font-size: 2.5em;
  display: flex;
  justify-content: center;
  margin-top: 3em;
  font-weight: bold;
  color: #6b65af;
}
.part {
  padding-left: 20px;
  /* color: #4f78f9; */
  color: #4f6486;
}
.dot {
  display: block;
  margin-left: -18px;
  margin-top: 5px;
  color: #d0cee3;
}
.manageNoteBtn {
  position: absolute;
  top: -57px;
  right: 50px;
  font-size: 20px;
  cursor: pointer;
  padding: 5px;
  width: 35px;
  height: 35px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #fff;
  border-radius: 100px;
}
.largeScreen {
  display: inline-block;
}
.mobile {
  display: none;
}

/* Media Queries */
@media (max-width: 767.98px) {
  .largeScreen {
    display: none;
  }
  .mobile {
    display: inline-block;
  }
  .reader {
    margin-left: 0;
    width: 100vw;
    height: 90vh;
    padding: 1.5em;
    overflow: auto;
  }
  i.manageNoteBtn {
    top: 15px;
    right: 55px;
    position: fixed;
  }
  .end {
    font-size: 1.8em;
  }
  .part {
    padding-left: 10px;
  }
  .dot {
    margin-left: -10px;
  }
  .body {
    height: 73vh;
    font-size: 1.1rem;
  }

  .footer {
    padding: 0 1.5em 2em 1.5em;
  }
  .menu {
    font-size: 1.6rem;
  }

  .btn,
  .skip {
    padding: 5px 10px;
    flex: 1;
    width: fit-content;
    justify-content: center;
    align-items: center;
    border-width: 1px;
    font-size: 1.5em;
  }
  .btn {
    width: 50px;
  }
  .skip {
    padding: 12px auto;
  }
  .menuUI {
    width: 100%;
    height: 100vh;
  }
}

@media (min-width: 450px) and (max-width: 768px) {
  .menuUI {
    width: 350px;
    height: 100vh;
  }
}

@media (min-width: 768px) and (max-width: 1004.98px) {
  .reader {
    margin-left: 0;
    width: 100vw;
    height: 90vh;
    padding: 0 4em;
  }
  .manageNoteBtn {
    top: -48px;
    right: 60px;
  }
  .footer {
    padding: 0 4em 2em 4em;
  }
  .body {
    height: 73vh;
    width: 100vw;
  }

  .menuUI {
    width: 350px;
    height: 100vh;
  }
}

@media (min-width: 1005px) and (max-width: 1199.98px) {
}

@media (min-width: 1200px) and (max-width: 1400px) {
}
