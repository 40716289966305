.pageRoot{
  display: flex;
  flex-direction: column;
  height: 100%;
}
.mainBody{
  flex: 1px;
  overflow-y: scroll;
  margin-top: 20px;
  padding: 10px 2.5em;
}
.subcategoryWrap{
    display: grid;
  grid-template-columns: repeat(2, minmax(0, 1fr));
  grid-gap: 20px;
}
.loaderWrapper{
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}

.backHomeButton{
  display: flex;
  align-items: center;
  gap: 7px;
  border: none;
  background-color: transparent;
  padding: 5px 15px;
  font-size: 1.3em;
}

.loader{
  font-size: 2em;
  height: 100px;
  width: 100px;
}
.emptyHistory {
  min-height: 50vh;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
}
.emptyHistory h3 {
  font-size: 1.4em;
  font-weight: 600;
  opacity: 0.7;
}
.pageBody{
  flex: 1px;
  margin-top: 3em;
  padding: 5px 2.5em;
}
.catalogItems{
  display: grid;
  grid-template-columns: repeat(3, minmax(0, 1fr));
    grid-gap: 20px;
}

@media screen and (max-width: 968px) {
.subcategoryWrap{
  grid-template-columns: repeat(1, minmax(0, 1fr));
}
.catalogItems{
  display: grid;
  grid-template-columns: repeat(2, minmax(0, 1fr));
    grid-gap: 20px;

}
}