.root {
  padding: 20px 40px;
}
.header {
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
}
.bookCounter {
}
.right {
  text-align: right;
  display: flex;
  align-items: center;
  gap: 20px;
}
.right i {
  font-size: 2em;
  cursor: pointer;
}
.right i:hover {
  transform: scale(1.15);
}
.searchIcon {
  color: #777;
  font-size: 1.3em;
}
a,
a:link,
a:hover {
  color: #222;
}
