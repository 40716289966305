.ratingStar {
  margin-bottom: 0px;
  font-size: 16px;
}
.starHalfIcon {
  font-size: 13.5px;
}
.starIcon,
.starHalfIcon {
  color: hsl(33, 85%, 53%);
}
.clickable {
  cursor: pointer;
}
.ratingScore {
  font-size: 0.8em;
  color: #999;
}
