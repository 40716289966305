.root {
  min-height: 100vh;
  background-color: #2b2b2b;
  color: #fff;
  display: flex;
  align-items: center;
  position: relative;
  overflow: hidden;
}

.elipseImage1,
.elipseImage2 {
  position: absolute;
}

.elipseImage1 {
  top: -16%;
  left: -12%;
  max-width: 350px;
}

.elipseImage2 {
  bottom: -60%;
  right: -35%;
  max-width: 1000px;
}

.banner {
  display: flex;
  z-index: 1;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 0 4em;
}

.banner .content {
  padding-top: 10em;
}

.banner h1 {
  font-weight: 700;
  font-size: 4.5em;
  color: var(--color-3);
}

.banner h1 i {
  font-style: normal;
  color: var(--color-2);
}

.banner .description {
  margin: 50px 0;
}

.banner .description h3 {
  font-weight: 700;
  font-size: 1.4em;
  color: #eeedf1;
}

.banner p {
  font-size: 1.2em;
  max-width: 600px;
  text-align: justify;
  color: #b5afc1;
}

.bannerImg {
  width: 650px;
  margin-top: 6em;
}

/* Media Queries */
@media (max-width: 767.98px) {
  .root {
    margin-top: 3em;
  }
  .banner {
    flex-direction: column;
    padding: 0 1.5em;
  }
  .banner .content {
    padding-top: 6em;
  }
  .banner h1 {
    font-size: 2em;
  }
  .banner .description {
    margin: 30px 0;
  }
  .banner .description h3 {
    font-size: 1.3em;
  }
  .banner p {
    font-size: 1.1em;
    max-width: 100%;
    text-align: left;
  }
  .bannerImg {
    width: 100%;
    margin: 2em;
  }
}

@media (min-width: 768px) and (max-width: 991.98px) {
  .banner {
    flex-direction: column;
    padding: 0 3em;
  }
  .banner .content {
    padding-top: 8em;
  }
  .banner h1 {
    font-size: 2.3em;
  }
  .banner .description {
    margin: 30px 0;
  }
  .banner .description h3 {
    font-size: 1.3em;
  }
  .banner p {
    font-size: 1.1em;
    max-width: 100%;
  }
  .bannerImg {
    width: 450px;
    margin: 2em;
  }
}

@media (min-width: 992px) and (max-width: 1199.98px) {
  .banner {
    gap: 20px;
  }
  .banner h1 {
    font-size: 3.2em;
  }
  .banner .description h3 {
    font-size: 1.5em;
  }
  .banner p {
    font-size: 1.15em;
    max-width: 500px;
  }
  .bannerImg {
    width: 400px;
  }
}

@media (min-width: 1200px) and (max-width: 1400px) {
  .banner {
    gap: 20px;
  }
  .banner p {
    max-width: 550px;
  }
  .bannerImg {
    width: 500px;
  }
}
