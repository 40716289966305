.close,
.content {
  display: flex;
}
.close {
  justify-content: flex-end;
}
.close button {
  border: none;
  background-color: transparent;
  font-size: 2.5em;
  padding-right: 10px;
  color: #777;
}
.content {
  padding: 20px 20px 20px 60px;
}
.left {
  flex: 2;
}
.left p {
  color: #777;
}
.right {
  flex: 1;
  padding-top: 5%;
}
.percentageScore {
  text-align: center;
}
.percentageScore h1 {
  font-size: 8em;
  font-weight: 600;
}
.percentageScore span {
  color: #999;
  font-size: 0.6em;
  font-weight: 200;
}
.percentageScore p {
  margin-top: -30px;
  text-transform: uppercase;
  font-weight: 600;
  font-size: 1.5em;
}
.totalPerformance {
  margin-top: 40px;
}
.totalPerformance p {
  margin-bottom: 10px;
}
.totalPerformance p span {
  font-weight: 600;
}
.correct {
  color: rgb(28, 69, 28);
}
.wrong {
  color: rgb(130, 46, 46);
}
.skipped {
  color: #777;
}

.topicsContainer {
  /* background-color: rgb(167, 162, 235); */
  width: 80%;
  padding: 20px;
  border-radius: 6px;
  /* margin-top: 50px; */
}
.topicsContainer h5 {
  color: #444;
}
.topics {
  max-height: 300px;
  overflow-y: auto;
  padding: 2px;
}
ul.topics {
  list-style: none;
}

ul.topics li {
  /* box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px; */
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  padding: 20px;
  margin-bottom: 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
li span {
  text-align: right;
  letter-spacing: 2px;
  font-weight: 600;
}

@media screen and (max-width : 700px){
  .topicsContainer {
    width: 100%;
  }
  .content {
    display: grid;
    grid-template-columns: 1fr;
  }
  .right{
    order: 1;
  }
  .left{
    order: 2;
    margin-top: 20px;
  }

  .percentageScore span {
    color: #999;
    font-size: 0.6em;
    font-weight: 200;
    margin-top: -120px;
  }

  .topicsContainer {

    padding: 0;
    overflow-y: scroll;
    height: 300px;
  }

  .left > h3 , .left > p{
    display: none;
  }

  .content {
    padding: 30px;
  }

  .percentageScore h1 {
    margin-top: -50px;
  }
}