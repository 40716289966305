.popup {
    height: fit-content;
    max-height: 80vh;
    background: #FFFFFF;
    top: 50px;
    left: 50px;
    outline: none;
    border-radius: 5px;
    padding: 15px;
    max-width: 500px;
    min-width: 300px;
    width: 90%;
    overflow-y: scroll;
    box-sizing: border-box;
  }
  .btnBox{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 15px;
    margin-top: 15px;
    margin-bottom: 15px;
  }
  .buttonConfirm{
    margin-left: 20px;
    font-size: .8em;
    height: 30px !important;
    max-width: 70px !important;
  }
  .close {
    position: absolute;
    top: 10;
    right: 10;
    cursor: pointer;
    height: 20px;
    width: 20px;
    display: grid;
    place-items: center;
    color: #000000;
  }
  .close2{
    position: absolute;
    top: 10;
    right: 10;
    cursor: pointer;
    height: 20px;
    width: 20px;
    display: grid;
    place-items: center;
    color: #FFFFFF,
  }
  .modal {
    border: none;
    place-items: center;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.435);
    z-index: 1000;
    display: none;
  }
  .showModal{
    display: grid;
  }
  .confirmHeaderText {
    font-weight: 600;
    font-size: 20px;
    margin-top: 5px;
    margin-bottom: 15px;
    color: #000;
  }
  .confirmBody {
    font-size: 14px;
    font-weight: 200;
    padding-bottom: 10px;
    color: #000;
    padding-top: 10px;
    padding-left: 10px;
  }
  .cancelText {
    color: #000
  }