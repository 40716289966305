.row {
  display: flex;
  align-items: center;
  gap: 5px;
}

.shelfSection {
  padding: 1em 5em;
  display: flex;
  flex-direction: column;
  height: 100%;
  background-color: #fff;
}
.header {
  font-size: 1em;
  font-weight: 500;
  margin-bottom: 2em;
  font-family: "Oswald, sans-serif";
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.header h6 {
  cursor: pointer;
  color: #29366c;
  margin-right: auto;
}
.header h6:hover {
  color: #29366c;
}
.tagWrapper {
  display: flex;
  padding: 5px 20px;
}
.bookMenu {
  display: flex;
  padding: 1em 0em;
  justify-content: space-between;
}
.bookList {
  font-size: 16px;
  font-weight: 300;
  cursor: pointer;
}
.titleWrapper {
  display: flex;
  gap: 5px;
  align-items: center;
  font-weight: 500;
}
.active {
  color: var(--color-transpirent-black);
  font-weight: 800;
}
.mainBody {
  display: flex;
  position: relative;
  flex: 1;
  overflow-y: scroll;
  padding-left: 1em;
}
.shelfBooksWrapper {
  flex: 1;
}
.loaderWrapper {
  display: flex;
  gap: 20px;
  flex-wrap: wrap;
}
.skeletonLogLoader {
  height: 250px;
  width: 250px;
}
.emptyHistory {
  margin: 0;
  padding: 0;
  left: 0;
  position: absolute;
}
.emptyHistory h3 {
  font-size: 1.4em;
  font-weight: 600;
  opacity: 0.7;
}

.bookSection {
  margin-top: 1em;
  grid-gap: 20px;
  display: flex;
  flex-wrap: wrap;
}
.emptyHistory > Img {
  margin-top: 2.2em;
  justify-content: center;
}
.myShelfImg {
  height: 300px;
  object-fit: contain;
}
.noBooks {
  font-size: 1.4em;
  font-weight: 600;
  opacity: 0.7;
  text-align: center;
}
.searchContainer {
  position: relative;
}

input.search {
  padding: 10px 8px;
  font-size: 0.85rem;
  border: solid 1px #eee;
  color: #888;
  margin-right: 5px;
  min-width: 300px;
}
.search:focus {
  outline: none;
}

.searchBtn {
  position: absolute;
  border: 1px solid #eee;
  background-color: #f9f9f9;
  color: #000;
  right: 10px;
  top: 5px;
  padding: 4px;
  font-size: 0.9em;
}
.search:focus.searchBtn {
  right: 50px;
}

@media (max-width: 767.98px) {
  .bookSection {
    grid-template-columns: repeat(1, minmax(0, 1fr));
    grid-gap: 20px;
    width: 100%;
  }
  .skeletonLogLoader {
    height: 250px;
    width: 90%;
  }
  .mainBody {
    padding: 0;
  }
  .shelfBooksWrapper {
    margin: 0 5px;
  }
  .shelfSection {
    padding: 2em 1.5em;
  }
  .bookMenu {
    display: none;
  }
}

@media (min-width: 768px) and (max-width: 970.98px) {
  .shelfSection {
    padding: 2em 1.5em;
  }
}

@media (min-width: 980px) and (max-width: 1200px) {
  .shelfSection {
    padding: 2em 3.5em;
  }
}
