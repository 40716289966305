.scroller {
  height: 90vh;
  overflow: auto;
  padding: 20px 20px 60px 20px;
}
.row {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  margin-bottom: 10px;
}

.card {
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
  padding: 20px;
  flex: 1;
  /* height: 350px; */
  margin: 10px 0;
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 300px;
}

.icon {
  font-size: 30px;
}

.title {
  font-size: 1em;
  font-weight: bolder;
}
.table {
  height: 80%;
  overflow: auto;
}
.add {
  padding: 3px 7px 4px 7px;
  border-radius: 100px;
  background-color: #000;
  color: #fff;
  position: absolute;
  top: 10px;
  right: 10px;
  cursor: pointer;
  font-size: 0.9em;
}
.add:hover {
  background-color: #444;
}
.form {
  margin: 20px 0;
}
.input {
  height: 40px;
  width: 80%;
  text-indent: 10px;
}
.btn {
  height: 39px;
  margin-left: 2%;
  width: 18%;
  font-size: 0.85em;
  border: none;
  background-color: rgb(39, 72, 138);
  color: #fff;
}

.previewSize {
  width: 55%;
  top: 5%;
  left: 20%;
}
