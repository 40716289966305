.clock {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 8px;
  font-size: 1em;
}
.clock span {
  width: 15px;
  text-align: right;
}
