.root {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  margin-bottom: 20px;
}

.row {
  display: flex;
  gap: 10px;
}
.nav {
  margin-right: auto;
  display: flex;
  gap: 10px;
  align-items: center;
  background-color: transparent;
  border: none;
  font-size: 0.95em;
}
/* Align items after .nav to the far right */
.root > *:not(.nav) {
  justify-self: end;
}
.mobile {
  display: none;
}
.desktop {
  display: flex;
}

.searchContainer {
  position: relative;
}
.search {
  width: 300px;
  height: 50px;
  outline: none;
  border: solid 1px #ccc;
  border-radius: 6px;
  text-indent: 10px;
  color: #555;
  z-index: 10;
}
.close
/* ,
.closeModal */ {
  display: none;
}
.send {
  cursor: pointer;
  font-size: 25px;
  position: absolute;
  top: 14px;
  right: 20px;
  color: #000;
}
input::placeholder {
  color: #999;
  font-style: italic;
}

.filterModal {
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
  width: 400px;
  position: absolute;
  right: 5%;
  z-index: 30;
  background-color: white;
  padding: 20px;
  display: flex;
  flex-wrap: wrap;
}

.closeModal {
  z-index: 20;
  font-size: 25px;
  position: absolute;
  color: #ccc;
  cursor: pointer;
  right: 5px;
  top: 5px;
  font-size: 20px;
}

/* Media Queries */
@media screen and (max-width: 900.98px) {
  .mobile,
  .close
  /* .closeModal  */ {
    display: inline-block;
  }
  .desktop {
    display: none;
  }
  .row {
    display: flex;
    gap: 10px;
    align-items: center;
  }
  .icon {
    cursor: pointer;
  }
  .searchContainer {
    width: 98%;
    left: 5px;
    position: absolute;
  }

  .search {
    width: 93%;
    height: 60px;
    position: absolute;
    top: -35px;
    left: 15px;
  }
  .close,
  .send,
  .closeModal {
    z-index: 20;
    font-size: 25px;
    position: absolute;
    right: 7%;
    top: -15px;
    color: #ccc;
    cursor: pointer;
  }
  .send {
    color: #000;
    font-size: 23px;
    position: absolute;
    right: 14%;
  }
  .filterModal {
    box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
    width: 400px;
    max-width: 90vw;
    position: absolute;
    left: 5%;
    z-index: 30;
    background-color: white;
    padding: 20px;
    display: flex;
    flex-wrap: wrap;
  }
  .closeModal {
    right: 5px;
    top: 5px;
    font-size: 20px;
  }
}

@media (min-width: 768px) and (max-width: 1199.98px) {
}

@media (min-width: 1200px) {
}
