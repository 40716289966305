.requestSection {
  padding: 10px;
  width: 600px;
  padding-bottom: 2em;
}

.inputField {
    height: 65%;
    border-radius: 4px;
    text-indent: 2px;
    justify-content: center;
}
.userName{
  font-size: 18px;
  font-weight: 800;
}
.inputField:focus{
  border: 1px solid #000;
}
.aboutBook{
  font-size: 20px;
  font-weight: 500;
}
.help{
  font-size: 34px;
  font-weight: 800;
  text-transform: capitalize;
}
.help span{
  color: hsl(22, 100%, 51%);
}
.inputColumn{
  flex: 1px;
}
.inputRow{
  display: flex;
  gap: 15px;
  width: 100%;
  margin: 20px 0;
}
.titleName{
  text-transform: capitalize;
}
.askQuestion{
outline: none;
padding: 4px;
border-radius: 5px;
}
.submitWrapper{
  margin-top: 10px;
}
.submitBtn{
  font-size: 20px;
  background: #000;
}

