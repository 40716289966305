.pageRoot{
  height: 100%;
  padding: 20px 2.5em;
  display: flex;
  flex-direction: column;
}

.inputColumn{
  margin-top: 1em;
  flex: 1px;
}
.inputLabel{
  font-size: .8em;
  font-weight: 600;
  margin-bottom: 5px;
}
.inputRow{
  display: flex;
  align-items: center;
  gap: 2em;
  width: 100%;
}
.saveButtonWrapper{
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 15px 0;
}
.backButton{
  border: none;
  background-color: transparent;
  display: flex;
  align-items: center;
  gap: 7px;
}
.backIcon{
  font-size: 1.5em;
}
.saveBtn{
  width: 180px;
}
.imageSectionWrapper{
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.coverImage{
  height: 200px;
  min-width: 200px;
  display: flex;
  align-items: center;
}
  .imageInput {
    display: none;
  }
  .coverImageUploader{
    display: flex;
    align-items: center;
    justify-content: center;
    width: 150px;
    height: 150px;
    border: 1px dashed #000000b9;
    border-radius: 5px;
  }
    .uploadedBookCover{
    position: relative;
    width: 150px;
    height: 150px;
    border-radius: 5px;
  }
  .uploadedBookCover img{
    object-fit: cover;
    height: 100%;
    width: 100%;
  }
  .uploadImageAction{
    height: 100px;
    width: 100px;
    cursor: pointer;
    flex-direction: column;
    display: flex;
    align-items: center;
    justify-content: center;
  }
    .removeImageBtn{
    top: 5px;
    right: 5px;
    position: absolute;
    height: 30px;
    width: 30px;
    border-radius: 50%;
    background-color: #FC0000;
    padding: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fff;
  }
  .addIcon{
    font-size: 2em;
    opacity: .7;

  }