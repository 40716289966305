.pageRoot {
  display: flex;
  flex-direction: column;
  height: 100%;
  padding: 0px 8% 0px 5%;
}
.goBackButton {
  display: flex;
  align-items: center;
  gap: 10px;
  border: none;
}
.pageHeader {
  font-size: 1.1em;
  font-weight: 500;
  margin: 0px;
}
.arrowLeft {
  font-size: 1.4em;
}
.billingInfo {
  display: flex;
  gap: 15px;
  margin-top: 20px;
}
.pageTitle {
  font-size: 1.5em;
  font-weight: 600;
}
.billingInfo > * {
  flex: 1px;
}
.autoRenewBtnWrap {
  display: flex;
  gap: 20px;
}
.sectionWrapper {
  margin-top: 20px;
  flex: 1px;
  /* overflow: scroll; */
  border: 1px solid #cccccc;
  padding: 20px;
  border-radius: 10px;
  background-color: #fff;
}
.sectionTitle {
  font-size: 1em;
  font-weight: 500;
}
.planActionsSec {
  display: flex;
  justify-content: space-between;
  margin: 2em 0px;
}
.planCompareBte {
  width: 200px;
}
.autoRenewText {
  font-size: 0.9em;
  font-weight: 400;
}
.paymentMethodsWrapper {
  display: flex;
  align-items: center;
  gap: 15px;
  flex-wrap: nowrap;
  overflow-x: scroll;
  padding: 10px;
  max-width: 80vw;
}
.tableWrapper {
  overflow-x: scroll;
}
.cardWrapper {
  height: 140px;
  width: 270px;
  min-width: 250px;
  padding: 13px;
  border: 1px solid var(--color-light-black);
  border-radius: 5px;
  background-color: var(--bg-color2);
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: all 0.5s ease;
}
.cardWrapper:hover {
  transform: scale(1.05);
  transition: all 0.5s ease;
}
.plusIcon {
  height: 30px;
  width: 30px;
  border-radius: 50%;
  background-color: #b3b8bd;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.5em;
  font-weight: 100;
  color: #fff;
}
.plusIcon span {
  margin-top: -3px;
}
.tableCell {
  font-size: 0.95em;
  font-weight: 500;
  text-transform: capitalize;
}
.paymentStatus {
  border: 1px solid #2c2c2c;
  padding: 2px 10px;
  border-radius: 2px;
  font-size: 0.9em;
  text-transform: lowercase;
  width: 100px;
}
.paymentStatus.paid {
  background-color: #beebc9;
  border: none;
}
.paymentStatus.failed,
.paymentStatus.declined {
  background-color: #ffd8d8;
  border: none;
}
.paymentLogLoader {
  height: 70px;
  margin: 15px 0;
}
.emptyHistory {
  min-height: 50vh;
  display: flex;
  align-items: center;
  justify-content: center;
}
.emptyHistory h3 {
  font-size: 1.4em;
  font-weight: 600;
  opacity: 0.7;
}

.manageUnitSection {
  height: 100px;
  padding: 20px 30px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #6a19ee;
  border-radius: 5px;
  margin-top: 20px;
  color: #fff;
}
.unitLeftText {
  font-size: 1.3em;
  font-weight: 500;
}
.cancelPlanButton {
  padding: 5px 15px;
  background-color: #ffd9d9;
  color: #d91b1b;
  font-size: 0.8em;
  font-weight: 400;
  border: none;
  border-radius: 2px;
}
.upgradeButton {
  padding: 5px 15px;
  background-color: #f7fff3;
  color: #1a4e02;
  font-size: 0.8em;
  font-weight: 400;
  border: none;
  border-radius: 2px;
}
.managePlanButtonWrapper {
  display: flex;
  align-items: center;
  gap: 15px;
}

.paymentModal {
  height: 100%;
  max-height: 80vh;
  background-color: transparent !important;
  right: 20% !important;
  left: 20% !important;
}
.dateTableHead {
  width: 100%;
  display: flex;
  justify-content: center;
}
.tHeader {
  width: 100%;
  display: flex;
  justify-content: center;
}
.tableHeader {
  background-color: transparent;
}

/* -----------------------------------------------------------------------------
                          MEDIA QUARY
----------------------------------------------------------------------------- */

@media (max-width: 960px) {
  .billingInfo {
    flex-direction: column;
  }
  .billingInfo > * {
    flex: none;
  }
  .pageRoot {
    display: block;
  }
}
