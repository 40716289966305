.rootPage {
  position: fixed;
  top: 0;
  width: 100vw;
  left: 0;
  z-index: 1000;
  height: 100vh;
  overflow-y: scroll;
  background-color: #fff;
  box-shadow: rgba(0, 0, 0, 0.05) 0px 6px 24px 0px,
    rgba(0, 0, 0, 0.08) 0px 0px 0px 1px;
}
.row {
  display: flex;
  gap: 20px;
  flex-wrap: wrap;
}
.close {
  cursor: pointer;
}
.header,
.contentHeader {
  display: flex;
  justify-content: space-between;
}

.header {
  padding: 20px 30px 0 30px;
  border-bottom: 1px solid #888;
}

.body {
  /* padding: 30px; */
  display: flex;
}
.menu {
  border-right: 1px solid #888;
  width: 200px;
  padding: 20px 30px;
  min-height: 70vh;
}
.menu ol {
  margin: -10px;
}
.menu ol li {
  cursor: pointer;
  padding-bottom: 10px;
}
.menu ol li:hover {
  color: blueviolet;
}

.content {
  flex: 1;
  padding: 20px 30px;
}
.contentHeader {
  margin-bottom: 20px;
}
.initials {
  border-radius: 100px;
  background-color: blueviolet;
  width: 50px;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 600;
  color: #fff;
}
.mtN20 {
  margin-top: -20px;
}
.mtN10 {
  margin-top: -10px;
}
.mtN20,
.mtN10 {
  color: #666;
}
.title {
  font-weight: 600;
  font-size: 1.1em;
  margin-top: 20px;
}
h4 .title {
  font-size: 1.5em;
}
.menu .title {
  color: #888;
  margin-bottom: 30px;
}
.content pre {
  white-space: pre-wrap;
  word-wrap: break-word;
  font-family: Inter;
  padding: 20px;
  font-size: 1.1em;
  color: #000;
  background-color: rgba(215, 234, 250, 0.259);
}

.learningObj {
  color: #000;
  background-color: rgba(250, 234, 215, 0.279);
}

.topicDropdown {
  /* width: 50px; */
  display: none;
}

@media screen and (max-width: 700px) {
  .header > div > h4 {
    font-size: 1.5em;
    font-weight: 800;
    font-family: "DM Sans";
  }
  .rootPage {
    width: 100vw;
    left: 0;
    /* margin: 0 20px; */
  }
  .menu {
    display: none;
  }
  .topicDropdown {
    display: block;
    max-width: 50%;
  }
}
