@import url("https://fonts.googleapis.com/css2?family=Signika+Negative&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Gantari:wght@400&display=swap");

.pageRoot {
  padding: 1em 2em;
  display: flex;
  /* height: 100%; */
  gap: 20px;
  background-color: #fff;
}

.left {
  flex: 1.5;
  margin-left: 2.8em;
}
.right {
  flex: 1;
  margin-right: 2.8em;
  padding-top: 2.5em;
}
.suggestionLabel {
  margin: 2em 0 -2em 0;
  font-weight: 600;
  color: #555;
}

/* Media Queries */
@media (max-width: 767.98px) {
  .pageRoot {
    padding: 0;
    flex-direction: column;
    gap: 0;
  }
  .left {
    flex: 1;
    margin: 0;
    padding: 1em;
  }
  .right {
    margin: 0;
    padding: 0;
  }
}

@media (min-width: 768px) and (max-width: 991.98px) {
  .pageRoot {
    padding: 0;
    flex-direction: column;
    gap: 0;
  }
  .left {
    flex: 1;
    margin: 0;
    padding: 0 7em 0 3.5em;
  }
  .right {
    margin: 0;
    padding: 0 7em 0 3.5em;
  }
}

@media (min-width: 992px) and (max-width: 1199.98px) {
}

@media (min-width: 1200px) and (max-width: 1400px) {
}
