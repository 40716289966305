.loader {
  position: absolute;
  top: 20%;
  left: 30%;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 200px;
  height: 80px;
  gap: 10px;
  background-color: rgba(251, 176, 63, 0.838);
  padding: 10px 20px;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  z-index: 9999999999!important;
}
.spinner {
  animation: spin 2s linear infinite;
  font-size: 2rem;
}
@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  50% {
    transform: rotate(50deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
