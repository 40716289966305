.viewPort {
  background-color: var(--bg-color1);
  box-sizing: border-box;
}
.rightView {
  display: flex;
  flex-direction: column;
}
.mainPage {
  flex: 1;
  box-sizing: border-box;
  position: relative;
}
