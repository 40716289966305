.container {
  width: 100%;
  margin: 0;
  box-sizing: border-box;
  display: flex;
  height: 100vh;
}

.sidebar {
  flex: 0.15;
  background: #eee;
  position: relative;
}
.sidebar_header {
  padding-top: 30px;
  padding-left: 30px;
  font-size: 1.5em;
}
.sidebar_body {
  padding: 20px;
}

.sidebar_body ul {
  padding: 0px;
}

.sidebar_body ul li {
  list-style-type: none;
  margin: 10px 0;
  cursor: pointer;
  padding: 10px;
}

.sidebar_body ul li a {
  text-decoration: none;
  color: black;
  display: flex;
  align-items: center;
  gap: 10px;
}

.sidebar_body ul li:hover {
  background: black;
  color: white;
  border-radius: 5px;
}

.sidebar_body ul li:hover > a {
  color: white;
}

.maincontainer {
  flex: 0.85;
}

.navbar {
  display: flex;
  justify-content: space-between;
  padding: 20px 15px;
  width: 100%;
  align-items: center;
  position: sticky;
  top: 0;
  box-shadow: 2px 2px 5px 2px #eee;
  z-index: 999 !important;
  background: #fff;
}

.image_card > img {
  border-radius: 100%;
  width: 35px;
  height: 35px;
}

.container__outlet {
  padding: 20px;
  height: 80vh;
  overflow-y: hidden;
}
