/* .pageRoot {
  min-height: 100vh;
  background-color: #fff;
  position: relative;
}

.welcomeView {
  background-color: rgb(71, 53, 190);
  color: #fff;
  background-repeat: no-repeat;
  min-height: 200px;
  flex: 1;
  background-position: right bottom;
  background-size: contain;
}
.welcomeView > p {
  font-size: 1.5em;
  padding-top: 2em;
  padding-left: 3.8em;
}

.welcomeView > p span {
  color: #cfcdfe;
  font-family: "Montserrat Alternates";

  font-weight: 600;
  display: block;
  font-size: 0.8em;
  margin-top: -2px;
}
.container {
  box-shadow: rgba(9, 30, 66, 0.25) 0px 4px 8px -2px,
    rgba(9, 30, 66, 0.08) 0px 0px 0px 1px;
  position: relative;
  top: -50px;
  background-color: #fff;
  width: 87%;
  margin-left: 5%;
  min-height: 300px;
  border-radius: 8px;
  padding: 50px;
  display: flex;
  justify-content: space-around;
}
.card {
  width: 390px;
  height: 250px;
  background-color: #000;
  color: #adaaaa;
  border-radius: 6px;
  padding: 20px;
  margin-right: 10px;
  box-shadow: rgba(0, 0, 0, 0.15) 0px 2px 8px;
  background-image: url(../../../assets/png/examprepOptions.png);
  background-repeat: no-repeat;
  background-position: right top;
  font-size: 0.9em;
  transition: transform 0.3s ease-in-out;
  cursor: pointer;
}
.card:hover {
  transform: scale(1.05);
}
.dot1,
.dot2,
.dot3 {
  width: 30px;
  height: 30px;
  background-color: rgba(160, 169, 199, 0.631);
  color: #2d3655;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 3.5em;
  border-radius: 60%;
}
.dot2 {
  background-color: rgba(227, 188, 129, 0.585);
  color: #543507;
}
.dot3 {
  background-color: rgba(190, 161, 205, 0.631);
  color: #3f1654e7;
}
.card h3 {
  color: #d5e4ea;
  font-size: 1.3em;
  padding-top: 70px;
}


@media screen and (max-width: 650px) {
  .container{
    display: grid;
    grid-template-columns: 1fr;
    padding: 15px;
    position: relative;
    top: 0;
    padding: 20px;
    background-color: none;
    box-shadow: none;
    border: none;
    border-radius: 0;
  }
  .card{
    margin: 10px 0;
    width: 100%;
  }


  .welcomeView > p {
    font-size: 1.5em;
    padding-top: 2.3em;
    padding-left: 50px;
    font-weight: 800;
    font-family: "DM Sans";
  }
  
}

@media screen and (min-width: 651px) and (max-width : 1150px) {
  .container{
    display: grid;
    grid-template-columns: 1fr 1fr;
    padding: 15px;
    position: relative;
    gap: 15px;
  }

  .card{
    margin: 10px 0;
    width: 100%;
  }
} */

.pageRoot {
  height: 100vh;
  position: relative;
}
.courseBanner {
  min-height: 200px;
  background: transparent linear-gradient(105deg, #5b57d5 0%, #312dad 100%) 0%
    0% no-repeat padding-box;

  color: #fff;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.courseBanner h2 {
  font-weight: 800;
}
.courseBanner p {
  color: #cfcdfe;
}

.cardWrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 20px;
  flex: 1;
  height: 60vh;
  padding: 2em 2em 4em 2em;
}

/* Media Queries */
@media (max-width: 767.98px) {
  .courseBanner {
    padding: 2em;
  }

  .cardWrapper {
    display: grid;
    grid-template-columns: 1fr;
    height: auto;
  }

  .cardWrapper > div {
    max-width: 100% !important;
    height: auto !important;
  }
}

@media (min-width: 768px) {
  .courseBanner {
    align-items: center;
    text-align: center;
  }
}
