.pageRoot{
  padding: 20px 5em;
  color: #000000;
}
.completionTop{
  display: flex;
  gap: 2.5em;
}
.completionTop > *{
  flex: 1px;
}
.courseTitle{
  font-size: 1.5em;
  font-weight: 600;
  font-family: 'Acme';
}
.completionTopAction{
  margin-top: 20px;
}
.resumeCourseBtn{
  width: 200px;
}
.courseDescription{
  margin-top: 2em;
  font-size: 1em;
  font-weight: 600;
  font-family: 'Quicksand';
}
.courseProgressTitle{
    font-size: 1.4em;
  font-weight: 500;
  font-family: 'Acme';
}
.progressBar{
  height: 15px;
  border-radius: 50px;
  flex: 1px;
}
.lineDiver{
  border-bottom: 1px solid #000;
}
.sylabusWrapper{
  margin-top: 2em;
  border: 1px solid #ccc;
  padding: 2em;
}
.archiementProgressWap{
  display: flex;
  gap: 20px;
  align-items: center;
}
.trouphyIcon{
  font-size: 2em;
}