.textInput {
  width: 100%;
  font-size: 1em;
  font-weight: 200;
  padding: 10px 20px;
}
.textInput::placeholder {
  font-size: 0.9em !important;
  font-weight: 100 !important;
  color: #888;
}
