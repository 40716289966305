.xs,
.lg {
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: 20px;
}
.lg {
  padding: 20px 40px;
}

/* Thumnail view */
.xs .title {
  font-size: 0.8em;
  text-transform: initial;
  font-weight: 600;
}

.xs p {
  font-size: 0.7em;
  width: 100%;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
}
.xs .img {
  width: 100%;
  height: 80px;
  object-fit: cover;
  margin-bottom: 5px;
  margin-top: -10px;
}

/* Full page view */
.lg .title {
  font-size: 1.2em;
  text-transform: initial;
  font-weight: 600;
}

.lg p {
  font-size: 1em;
  width: 100%;
}
.lg .img {
  width: 100%;
  max-height: 300px;
  overflow: hidden;
  object-fit: cover;
  margin-bottom: 10px;
}
.lg .lbl {
  text-align: left;
}

/* Form css */
.titleInput {
  height: 50px;
  text-indent: 10px;
  margin-bottom: 10px;
}
.image {
  position: relative;
}
.imageUpload {
  font-size: 1.5em;
  position: absolute;
  top: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.688);
  padding: 2px 10px;
  color: #fff;
}
.imageUpload label {
  cursor: pointer;
}
.paragraphTextAare {
  height: 120px;
  padding: 10px;
  margin-bottom: 10px;
}
.button {
  display: flex;
  justify-content: flex-end;
}
.btn {
  width: 120px;
  padding: 10px;
  margin: 20px 0 10px 0;
  background-color: transparent;
  border: solid 2px #000;
  color: #000;
}

.btn:hover {
  transform: scale(1.1);
}
.pre {
  white-space: pre-wrap;
  word-wrap: break-word;
}

/* Media Queries */
@media (max-width: 767.98px) {
  .lg {
    padding: 20px 1em 20px 2.2em;
  }
}
