.rootPage {
  height: 80vh;
  overflow: auto;
}
.top,
.row {
  display: flex;
  padding: 20px;
  justify-content: space-between;
  gap: 30px;
}
.top {
  justify-content: flex-end;
}
.flex1 {
  flex: 1;
  align-self: flex-start;
}
.flex2 {
  flex: 2;
  align-self: flex-start;
}
.addStudent {
  padding: 10px 15px;
  border-radius: 6px;
  border: solid 2px #999;
  background-color: #f9f9f9;
  color: #666;
  font-size: 0.8rem;
  font-weight: bold;
  display: flex;
  align-items: center;
  gap: 10px;
}
.addStudent:hover {
  transform: scale(1.03);
}
