.compRoot{
  min-height: 200px;
  border: 1px solid #b4b7b9;
  background-color: #f7f9fa;
  padding: 10px;
}
.pageNumber{
  font-size: 1em;
  font-weight: 600;
}
.pageTitleWrapper{
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 20px;
}
.removePageButton{
  border: none;
  background-color: transparent;
  font-size: .8em;
  font-weight: 600;
  color: #FC0000;
  padding: 2px 15px
}
.removePageButton:hover{
  transform: scale(1.09);
}
.CompContent{
  margin-left: 4em;
  margin-top: 1em;
}
.manageContentSelector{
  height: 50px;
  border: 1px solid #b4b7b9;
  background-color: #fff;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 2px 15px;
}
.addContentBtn{
  display: flex;
  gap: 7px;
  align-items: center;
  border: 1px solid #ccc;
  padding: 3px 18px;
  background-color: transparent;
}
.contentTypeSelection{
  min-height: 100px;
  border: 1px solid #ccc;
  border-top: none !important;
  background-color: #fff;
  padding: 20px;
  display: none;
}
.showcontentTypes{
  display: block;
}
.contentTypeNotice{
  position: relative;
  display: flex;
  align-items: center;
  gap: 7px;
  padding: 10px;
}
.contentTypeNotice span{
  font-size: .85em;
  font-weight: 400;
}
.contentTypeNotice button{
  font-size: .85em;
  font-weight: 400;
  border: none;
  background-color: transparent;
  color: #6c44d6;
  text-decoration: underline;
}
.selectorWrapper{
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 15px;
}
.contentSelectBtn{
  height: 80px;
  width: 80px;
  border: 1px solid #ccc;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.contentSelectBtn:hover{
  background-color: rgb(59, 57, 57);
  color: #fff;
}
.contentTypeText{
  padding: 3px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #d1d7dc;
  font-size: .8em;
}
.contentSelectBtn:hover .contentTypeText{
    background-color: rgb(59, 57, 57);
  color: #fff;
}
.contentTypeIcon{
  flex: 1px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.contentTypeIcon > *{
  font-size: 1.5em;
  opacity: .5;
}
.closeContentTypes{
  position: absolute;
  right: 15px;
  top: -59px;
  height: 40px;
  display: flex;
  align-items: center;
  gap: 10px;
  border: 1px solid #ccc;
  border-bottom: none !important;
  background-color: #fff;
  padding: 5px 20px;
}
.closeBtnIcon{
  font-size: 1.5em;
  font-weight: 600;
}
.closeContentTypes span{
  font-size: .9em;
  font-style: 600;
}
.addExerciseBtn{
  border: 1px solid #ccc;
  display: flex;
  align-items: center;
  gap: 7px;
  background-color: #fff;
  padding: 4px 20px;
  font-size: .9em;
  font-weight: 600;
}
.textEditorWrapper{
  margin-top: 20px;
  height: 200px;
}
.blockTextEdit{
 height: 100%;
}
.pageExerciseSection{
background-color: #fff;
margin-top: 20px;
  border: 1px solid #ccc;
}
.exerciseTopBar{
  height: 50px;
  display: flex;
  align-items: center;
  padding: 10px;
}
.pageQuestionWrapper{
  min-height: 200px;
  display: none;
}
.showExercise{
  display: block;
}
.questionWrapper{
  display: flex;
  flex-direction: column;
  gap: 1em;
}
.addCardBtn{
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px;
  border-radius: 6px;
  background-color: #fff;
  margin-bottom: 20px;
  cursor: pointer;
}
.addCardItem{
  display: flex;
  gap: 10px;
  align-items: center;
  font-size: 1em;
  font-weight: 600;
  background-color: #f7f9fa;
  padding: 5px 20px;
}
.pageTitleBox{
      display: flex;
                align-items: center;
                flex: 1px;
                position: relative;
                height: 100%;
}
.editTitleBox{
  display: flex;
  align-items: center;
  flex: 1px;
}
.titleEditInput{
  border: none;
  background-color: #f4f5f9;
}
.closeTitleEdit{
  border: none;
  background-color: #fff;
  color:#464343;
  font-size: 1.3em;
}
.titleEditButton{
  visibility: hidden;
  border: none;
  background-color: #fff;
  color:#7a7979;
  font-size: .9em;
  position: absolute;
  top: 30%;
  border: 1px solid #bbb;
  height: 18px;
  width: 18px;
  padding: 2px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 2px;
}
.pageTitleBox:hover .titleEditButton{
  visibility: visible;
}
.titleText{
  margin-left: 25px;
  font-weight: 600;
  font-size: .9em;
}
  .imageInput {
    display: none;
  }