footer {
  color: #eaf6fd;
  height: 100vh;
  width: 100%;
  background-color: var(--color-neutral-black);
  position: fixed;
  z-index: 1;
  bottom: 0;
  padding: 6em 0;
  overflow: auto;
}

.newsLetterWrapper {
  padding: 1em 4em;
}
.newsLetterWrapper h3 {
  margin-top: 4rem;
  text-align: center;
  text-transform: uppercase;
  letter-spacing: 0.05em;
  font-size: 1em;
}
.submitBtn {
  margin-top: 8px;
  position: absolute;
  right: 10px;
  top: 1px;
  border-radius: 20px;
  width: 150px;
  height: 40px;
  background-color: #6155d7;
  text-align: center;
  padding: 1px;
}
.submitBtn:hover {
  transform: scale(0.98);
  transition: all 0.3s ease;
}
.newsContent {
  margin-left: 5em;
  margin-top: 1rem;
  display: flex;
  justify-content: center;
  align-items: center;
}
.row {
  display: flex;
  align-items: center;
  position: relative;
  z-index: 2000;
}
.inputField {
  width: 600px;
  height: 60px;
  border-radius: 4px;
  border: 1px solid var(--color-neutral-black);
  outline: none;
  position: relative;
}

.footerMenu {
  display: flex;
  justify-content: space-between;
  align-items: self-end;
  padding: 4em;
  padding-bottom: 2em;
}
.footerItem {
  flex: 1;
}
.footerHeadings {
  margin: 25px 0;
  color: whitesmoke;
  font-size: 1.3em;
  font-weight: 600;
}

.footerItem p {
  padding-bottom: 10px;
}
.footerItem span {
  font-size: 1.5em;
  font-weight: 700;
  padding-right: 10px;
  color: #978cfa;
}
.navlink {
  text-decoration: none;
  color: var(--color-white);
  background-color: transparent;
  border: none;
}
.navLink:hover {
  transform: scale(1.2);
}

ul.list {
  margin: 0;
  padding-left: 12px;
}
ul.list li {
  margin-bottom: 10px;
  font-size: 0.9em;
}

.eachIcon {
  display: inline-block;
  height: 45px;
  width: 45px;
  margin: 0 5px;
  overflow: hidden;
  background: #ffff;
  color: #000;
  border-radius: 50px;
  cursor: pointer;
  box-shadow: 0px 10px 10px rgba(0, 0, 0, 0.1);
  transition: all 0.3s ease-out;
}
.icon {
  display: inline-block;
  height: 45px;
  width: 45px;
  text-align: center;
  border-radius: 50px;
  box-sizing: border-box;
  line-height: 45px;
}
.socialIcon {
  font-size: 20px;
  line-height: 45px;
}
.eachIcon span {
  font-size: 16px;
  font-weight: 500px;
  line-height: 45px;
  margin-left: 10px;
  transition: all 0.3s ease-out;
}

/* ---------------------------------------------------
    MEDIAQUERIES
----------------------------------------------------- */

@media (max-width: 767.98px) {
  footer {
    padding: 3em 0 1em 0;
  }

  .newsLetterWrapper {
    padding: 0;
  }
  .newsLetterWrapper h3 {
    font-size: 0.9em;
  }
  .submitBtn {
    border-radius: 20px;
    width: 80px;
    font-size: 0.7em;
  }
  .newsContent {
    margin-left: 0;
  }
  .row {
    flex: 1;
    margin: 0 1.5em;
  }
  .inputField {
    width: 100%;
    height: 60px;
  }

  .footerMenu {
    display: grid;
    grid-template-columns: 1fr;
    padding: 1.5em;
    padding-bottom: 0;
    margin-top: 1em;
  }
  .footerItem {
    flex: 1;
  }
  .footerHeadings {
    margin-top: 25px;
  }

  .footerItem span {
    font-size: 1.3em;
  }
  .lastItem {
    padding-top: 1em;
  }

  ul.list {
    padding-left: 1em;
  }
  ul.list li {
    margin-bottom: 10px;
    font-size: 0.9em;
  }

  .eachIcon {
    height: 45px;
    width: 45px;
    transition: all 0.3s ease-out;
  }
  .icon {
    height: 45px;
    width: 45px;
  }

  .socialIcon {
    font-size: 20px;
    line-height: 45px;
  }
  .eachIcon span {
    transition: none;
  }
}

@media (min-width: 768px) and (max-width: 991.98px) {
  .footerMenu {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 20px;
  }
  .lastItem {
    grid-column: 1/3;
    grid-row: 2;
  }

  .newsContent {
    margin-left: 0;
  }
}

@media (min-width: 1200px) and (max-width: 1400px) {
  .eachIcon {
    display: inline-block;
    height: 45px;
    width: 45px;
    margin: 0 5px;
    overflow: hidden;
    background: #ffff;
    color: #000;
    border-radius: 50px;
    cursor: pointer;
    box-shadow: 0px 10px 10px rgba(0, 0, 0, 0.1);
    transition: all 0.3s ease-out;
  }
  .eachIcon:hover {
    width: 150px;
    background-color: #bbb;
  }
  .icon {
    display: inline-block;
    height: 45px;
    width: 45px;
    text-align: center;
    border-radius: 50px;
    box-sizing: border-box;
    line-height: 45px;
  }
  .eachIcon:hover .icon {
    background-color: #222;
    color: #bbb;
    border: 1px solid #bbb;
  }
  .socialIcon {
    font-size: 20px;
    line-height: 45px;
  }
  .eachIcon span {
    font-size: 16px;
    font-weight: 500px;
    line-height: 45px;
    margin-left: 10px;
    transition: all 0.3s ease-out;
  }

  .eachIcon:hover span {
    color: #222;
  }
}
