.pageRoot{
  display: flex;
  flex-direction: column;
  padding: 0 2em;
  height: 100%;
  overflow: scroll;
}
.pageBody{
  display: flex;
  margin-top: 15px;
  flex: 1px;
  gap: 1.3em;
}
.leftView{
  flex: 1px;
  height: 100%;
}
.rightView{
  width: 250px;
  height: 95%;
}
.ebookBtn {
  margin-bottom: 15px;
}
.returnWrapper {
  display: flex;
}
.readBtn {
  background: #ffffff;
  color: var(--color-primary);
  border: 1px solid var(--color-primary);
  border-radius: 2px;
  border-top-right-radius: 0px;
  border-bottom-right-radius: 0px;
}
.audioBtn {
  background: #ffffff;
  color: var(--color-primary);
  border: 1px solid var(--color-primary);
  border-radius: 5px;
}
.headsetBtn {
  background: #ffffff;
  color: var(--color-primary);
  border: 1px solid var(--color-primary);
  border-radius: 2px;
  border-left: none;
  border-top-left-radius: 0px;
  border-bottom-left-radius: 0px;
}

.video{
  background: #ffffff;
  color: var(--color-primary);
  border: 1px solid var(--color-primary);
  border-radius: 2px;
  border-left: none;
  border-top-right-radius: 0px;
  border-top-left-radius: 0px;
  border-bottom-left-radius: 0px;
  border-bottom-right-radius: 0px;
}

.videoBtn{
  background: #ffffff;
  color: var(--color-primary);
  border: 1px solid var(--color-primary);
  border-radius: 2px;
  border-left: none;
  border-top-left-radius: 0px;
  border-bottom-left-radius: 0px;
}
.studyIcon{
  font-size: 1.6em;
}
.studyBtn{
  max-width: 100px;
}
.studyBtn:hover{
  background-color: #E6F1FB;
  color: var(--color-primary);
}
.activeNow{
  background-color: #0A5FFF;
  color: #ffffff;
}
.actionWrapper{
  display: flex;
  gap: 1em;
  align-items: center;
  justify-content: flex-end;
}
.requestHelp{
  width: 140px;
  height: 30px;
  font-size: .75em;
  font-weight: 400;
  background-color: #011B60;
  color: #ffffff;
  border-radius: 1px;
  white-space: nowrap;
  padding: 0 10px;
}
.bookMarkBtn{
  width: 140px;
  height: 30px;
  font-size: .75em;
  font-weight: 400;
  background-color: transparent;
  color: #ffff;
  background-color: #000;
  border: 1px solid #000;
  border-radius: 1px;
  white-space: nowrap;
  padding: 0 10px;
}
.returnBtn{
  width: 140px;
  height: 30px;
  font-size: .75em;
  font-weight: 400;
  background-color: #FC0000;
  color: #ffffff;
  border-radius: 1px;
  white-space: nowrap;
  padding: 0 10px;
}
.personalCopy{
  width: 140px;
  height: 30px;
  font-size: .75em;
  font-weight: 400;
  background-color: transparent;
  color: #000;
  border: 1px solid #000;
  border-radius: 1px;
  white-space: nowrap;
  padding: 0 10px;
}
.loader{
  height: 100%;
  width: 100%;
}
.backButton{
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 10px;
  font-size: 1.1em;
  font-weight: 500;
  border: none;
  background-color: transparent;
  font-family: 'Kanit', sans-serif;
}
.rightItemsMain{
  display: flex;
  flex-direction: column;
  height: 100%;
}
.noteWrapper{
  margin-top: 3em;
  flex: 1px;
  display: flex;
  flex-direction: column;
}
.blockInput{
  flex: 1px;
  overflow-y: scroll;
}
.sendNoteBtnWrapper{
  display: flex;
  align-items: center;
  justify-content: flex-end;
  border: 1px solid #ccc;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  border-top: none;
  padding: 0 10px;
  height: 60px;
}
.saveNoteBtn{
  border: none;
  background-color: white;
  color: #000;
  margin-top: 5px;
  width: 70px;
  font-size: 1.5em;
}
.nteTitle{
  background-color: transparent;
  border: 1px solid #ccc;
  border-bottom: none;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
}