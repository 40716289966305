/* Pagination.module.css */
.nav {
  margin: 1.25rem auto 0;
  width: fit-content;
}

.ul {
  display: flex;
  align-items: center;
  gap: 8px;
  height: 2.5rem;
  font-size: 1rem;
}

.button,
.buttonActive {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 1rem;
  height: 2.5rem;
  line-height: 2.5rem;
  text-align: center;
  background-color: white;
  border: 1px solid #d1d5db;
  border-radius: 0.375rem;
  cursor: pointer;
  transition: background-color 0.2s, color 0.2s;
}

.button:hover {
  background-color: #f3f4f6;
  color: #4b5563;
}

.buttonActive {
  background-color: #0305a6;
  color: #ffffff;
}

.previous,
.next {
  border-radius: 0.375rem;
}

.previous svg,
.next svg {
  width: 0.75rem;
  height: 0.75rem;
}

.previous:hover,
.next:hover {
  background-color: #f3f4f6;
  color: #4b5563;
}

.ellipsis {
  padding: 0 1rem;
}