.root {
  background: var(--color-transpirent-white);
  flex: 1px;
  padding: 2em 4%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: auto;
}
.logoWrapper {
  display: none;
}
.mainBody {
  height: 100%;
  display: flex;
  padding: 10px 3%;
  width: 100%;
  max-width: 450px;
}
.formContainer {
  width: 100%;
  background: var(--color-transpirent-white);
  flex: 1px;
  padding: 5em 4%;
  height: 100%;
}
.header {
  font-weight: 700;
  font-size: 2.5em;
  color: hsl(228, 58%, 20%);
  font-family: "DM Sans";
}
.formRootWrapper {
  flex: 1px;
  display: flex;
  align-items: center;
  width: 100%;
}

.inputRow {
  margin-top: 25px;
  width: 100%;
}
.inputField {
  border-radius: 2px;
  height: 55px;
  font-style: normal;
}
.actionsSection {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 0.8em;
}
.loginSubmit {
  font-weight: 600;
  height: 55px;
  font-size: 1rem;
  border-radius: 2px;
}

.socialSignUpButton {
  background-color: transparent;
  border: none;
}
.socialIcon {
  height: 40px;
  width: 40px;
  border-radius: 50%;
}
/* .socialSignUp{
  display: flex;
} */
.socialSignUpButton:hover {
  transform: scale(0.95);
}
.decision {
  margin-top: 15px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.iconWrapper {
  display: flex;
  gap: 8px;
  align-items: center;
}

.mySocialIcon {
  display: flex;
}
.forget {
  padding-top: 12px;
  color: hsl(219, 47%, 31%);
  font-size: 0.95em;
  font-weight: 400;
}
.routeLogin {
  margin: 1rem 0;
  font-size: 0.95em;
  color: hsl(219, 47%, 31%);
}
.routeLogin a:hover {
  color: rgb(188, 45, 45);
}
.checkboxField {
  display: flex;
  gap: 4px;
  align-items: center;
  font-size: 0.85em;
  font-weight: 400;
}
a {
  text-decoration: none;
}
.option {
  color: #4f5d788a;
}

.auth__modal {
  position: absolute;
  border: none;
  place-items: center;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  z-index: 200;
  background-color: rgba(0, 0, 0, 0.435);
  display: none;
}

.showModal {
  display: grid;
}

.auth__modal > .popup {
  background: #eeee;
  height: fit-content;
  overflow: auto;
  position: fixed;
  border-radius: 5px;
  box-sizing: border-box;
  padding: 30px 20px;
  margin: 0 auto;
  min-width: 600px;
  top: 15%;
}
.popup__body span {
  display: block;
  margin: 20px 0;
}

.auth__modal .popup__body .reg_btn {
  padding: 10px 20px;
  background: black;
  color: white;
}

.auth__modal .popup__body .reg_btn:hover {
  background: transparent;
  border: 0.1px solid black;
  color: black;
}
.heading {
  margin: 2em 0;
}
.popup__body input {
  margin: 10px;
}

.centerAll {
  text-align: center;
}

.popupLinkBtn1,
.popupLinkBtn2,
.popupLinkBtn3 {
  text-decoration: none;
  margin: 10px;
  padding: 10px 20px;
  border: none;
  outline: none;
  height: 150px;
  width: 150px;
}

/* .btnColorPrimary {
  color: white;
  background: var(--color-primary);
} */

.btnColorPrimary:hover {
  background: white;
  color: var(--color-primary);
}

.outlinePrimary {
  background: white;
  border: 1px solid var(--color-primary);
  border-radius: 5px;
  color: #28287d;
  transition: transform 0.3s ease-in-out;
}

.outlinePrimary:hover {
  background: var(--color-primary);
  color: white;
  transform: scale(1.1);
}

.subscription {
  background: #fff !important;
}

.subscription > h4 {
  font-weight: bolder;
}

.topUpBtn {
  padding: 10px 50px;
  margin-top: 30px;
  border-radius: 4px;
  background: var(--color-primary);
  outline: none;
  border: none;
  color: #fff;

  display: flex;
  align-items: center;
  justify-content: center; /* margin: 2em 30% 0 30%; */
  /* translate: 10%; */
  width: auto;
}

.btnContainer {
  margin: 15px 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

.sub_text_container {
  max-width: 500px;
  margin: 0 auto;
}

.sub_text_container > span {
  color: #858484;
}

.subIcon {
  color: #cdcdcd;
  font-size: 30px;
  cursor: pointer;
}
.mobile {
  display: none;
}

@media screen and (max-width: 960px) {
  .mobile {
    display: block;
  }
  .mainBody {
    flex-direction: column;
  }
  .header {
    font-weight: 700;
    font-size: 2.5em;
  }
  .formContainer {
    flex: 1px;
    padding: 2em 2%;
    height: 100%;
  }
  .formContainer form {
    margin-top: 3em;
  }
  root {
    flex: 0;
    padding: 1em 2%;
  }
  .logoWrapper {
    display: flex;
    align-items: center;
    margin-bottom: 2rem;
    position: relative;
    right: 2px;
  }

  .logoName {
    padding-top: 8px;
    font-weight: 800;
  }
  .logoName span {
    color: goldenrod;
    font-size: 1.3rem;
  }
  .forget {
    padding-top: 10px;
  }
  .back {
    background-color: transparent;
    border: none;
    outline: 0;
    text-align: left;
  }
}

/* Media Queries */
@media (max-width: 767.98px) {
  .showModal {
    display: flex;
    flex-direction: column;
  }

  .auth__modal > .popup {
    background: #fff;
    height: 100%;
    border-radius: 0;
    padding: 30px 20px;

    min-width: 100%;
    top: 0;
  }
  .regType {
    display: grid;
    grid-template-columns: 1fr 1fr;
  }

  .popupLinkBtn1,
  .popupLinkBtn2,
  .popupLinkBtn3 {
    width: 90%;
  }
  .regType {
    margin-top: 20px;
    display: grid;
    grid-template-columns: 1fr 1fr; /* Two equal columns */
    gap: 10px; /* Adjust the gap between grid items as needed */
  }

  .active {
    background: var(--color-primary);
    color: white;
    transform: scale(1.1);
  }
}

@media (min-width: 768px) and (max-width: 991.98px) {
  .mainBody {
    margin-top: 3em;
  }
}

@media (min-width: 992px) and (max-width: 1199.98px) {
}

@media (min-width: 1200px) and (max-width: 1400px) {
}
