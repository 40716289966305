.loaderRoot{
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: none;
    z-index: 10000;
    background-color: var(--color-white);
    opacity: 1;
    transition: all linear 1000ms;
}
  .showloader {
    display: flex;
    justify-content: center;
    align-items: center;
    opacity: 1;
    transition: all linear 800ms;
  }
  .loader{
    color: var(--color-primary);
  }