.root {
  box-shadow: 2px 5px 15px #d3d3d3;
  border-radius: 12px;
  margin-bottom: 3em;
  width: 90%;
}
.heading {
  margin-bottom: -5px;
  color: #777;
  font-size: 16px;
}
.review {
  display: flex;
  gap: 10px;
  padding: 20px;
}
.review:not(:first-child) {
  border-top: dotted 1px #ddd;
}
.user {
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 50px;
  height: 50px;
  font-weight: 600;
  font-size: 18px;
  background-color: #4d196f;
  color: #dacbe3;
  border-radius: 100px;
  margin-top: 20px;
  order: 1;
  text-transform: uppercase;
}
.rating {
  margin-top: -30px;
}
.review.odd {
  justify-content: flex-end;
}
.review.odd .user {
  order: 2;
}
.review.odd .content {
  text-align: right;
}
.review.even .user {
  order: 0;
}

.content,
.reviewForm {
  padding: 20px;
}
.reviews {
  max-height: 400px;
  overflow: auto;
}
.reviewForm {
  border-top: dashed 1px #ccc;
  background-color: #f9f9f9f9;
  border-bottom: dashed 1px #ccc;
}
.reviewForm textarea {
  padding: 20px;
  outline: none;
  box-shadow: none;
  border: solid 2px #eee;
  border-radius: 5px;
  width: 99%;
}
.ratingHolder {
  display: flex;
  gap: 20px;
  margin: 10px 0;
}
.action {
  display: flex;
  justify-content: flex-end;
}
.reviewForm button {
  padding: 5px 20px;
  border: 1.5px solid black;
  background: transparent;
  font-size: 14px;
  text-transform: uppercase;
}

.reviewForm button:hover {
  background: black;
  color: white;
}
.datecreated {
  font-size: 12px;
  color: #555;
  margin-bottom: 2px;
}
.comment::first-letter {
  text-transform: uppercase;
}
.name {
  text-transform: uppercase;
  font-size: 0.9em;
  font-weight: 600;
}

/* Media Queries */
@media (max-width: 767.98px) {
  .root {
    box-shadow: none;
    border-radius: 0;
    width: 100%;
    margin: 0;
    padding: 0;
  }
  .heading {
    font-weight: 600;
  }
  .action {
    display: flex;
    justify-content: flex-start;
    margin-top: 1em;
  }
  .reviews {
    max-height: 100%;
    overflow: hidden;
  }
}

@media (min-width: 768px) and (max-width: 991.98px) {
  .root {
    width: 100%;
  }
}
