.courseCardRoot{
  border: 1px solid rgba(0, 0, 0, 0.181);
  margin: 15px 0px;
  display: flex;
  height: 100px;
  cursor: pointer;
}
.imgThumbNail{
  height: 100%;
  width: 100%;
  object-fit: cover;
}
.cardBody{
  padding: 20px 30px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex: 1px;
}
.courseTitle{
  font-size: 1.2em;
  font-weight: 600;
  text-transform: capitalize;
}
.category{
  font-size: .9em;
  font-weight: 500;
  opacity: .7;
}
.reviewStatus{
  font-size: .85em;
  font-weight: 400;
  height: 30px;
  padding: 0px 15px;
  border-radius: 2px;
  width: 100px;
}
.deleteBtn{
  border: none;
  background-color: transparent;
  height: 30px;
  width: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  background-color: #FFD8D8;
  color: #0850D4;
}
.deleteBtn > *{
  width: 20px;
  height: 20px;
}
.deleteIcon{
  font-size: 1.3em;
  color: #000000;
}
.progressBar{
  display: flex;
  width: 50%;
  align-items: center;
  gap: 10px;
}
.uploadStatusText{
  font-size: .9em;
  font-weight: 400;
  opacity: .6;
}