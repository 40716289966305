.cardRoot{
  padding: .6em;
  color: #000000;
  display: flex;
}
.cardTopSect{
  display: flex;
  justify-content: space-between;
  cursor: pointer;
  padding: 1em;
}
.toggleIconWrap{
  font-size: 1.8em;
}
.topTextColumn{
  width: 60%;
}
.cardTitle{
  font-size: 1.3em;
  font-weight: 600;
  font-family: 'Acme';
}
.cardDescriptionText{
  font-size: .95em;
  font-weight: 600;
  font-family: 'Quicksand';
}
.cardBody{
  padding: 2em 1em;
  display: none;
  transition: 1s ease;
}
.openCardBody{
  display: block;
  transition: 1s ease;
}
.cardBodyItemRow{
  display: flex;
  align-items: center;
  cursor: pointer;
}
.cardBodyItemRow:hover{
  text-decoration: underline;
  color: #3b43d0;
}
.cardItemNameWrap{
  display: flex;
  align-items: center;
  gap: 15px;
  min-width: 200px;
}
.itemNameIcon{
  font-size: 1.3em;
  height: 45px;
  width: 45px;
  border-radius: 50%;
  border: 1px solid #000;
  display: flex;
  align-items: center;
  justify-content: center;
}
.itemName{
  font-size: 1em;
  font-weight: 600;
  font-family: 'Quicksand';
}
.lockIcon{
  width: 40px;
  height: 40px;
  border: 1px solid #aaa;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.5em;
}