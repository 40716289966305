.modalBodyWrapper{
  height: 78vh;
  display: flex;
  flex-direction: column;
}
.tableWrapper{
  flex: 1;
  overflow-y: auto;
}
.paymentLogLoader {
  height: 70px;
  margin: 15px 0;
}
.downnloadButton{
  background-color: transparent;
  height: 40px;
  width: 40px;
  font-size: 1.5em;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid rgba(0, 0, 0, 0.5);
  position: absolute;
  top: 0;
  right: 0;
  margin-top: 18px;
  margin-right: 70px;
}
.bookStatTab{
  position: absolute;
  top: 20px;
  display: flex;
}
.bookStatTab button {
  border: none;
  padding: 5px 20px;
  font-size: 1em;
  font-weight: 500;
  border-radius: 2px;
}

.activeBookStatTab{
   background-color: var(--color-primary);
   color: #ffffff;
}