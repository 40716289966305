.pageRoot{
  display: flex;
  height: 100vh;
}

/* -----------------------------------------------------------------------------
                          MEDIA QUARY
----------------------------------------------------------------------------- */

@media  (max-width: 960px) {
.pageRoot{
 flex-direction: column;
}
}