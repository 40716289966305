.title {
  text-align: start;
}

.custom-select-container {
  display: inline-block;
  min-width: 200px;
  width: 100%;
  text-align: start;
  position: relative;
  /* z-index: 2; */
}

.selected-text {
  background-color: #fff;
  padding: 6px 20px;
  border: 1px solid #000;
  border-radius: 3px;
  height: 45px;
  z-index: 2;
}

.selected-text::after {
  content: "";
  position: absolute;
  right: 10px;
  top: 16px;
  border: 7px solid transparent;
  border-color: #000 transparent transparent transparent;
}

.selected-text.active::after {
  top: 8px;
  border-color: transparent transparent #000 transparent;
}

.custom-select-container ul {
  margin: 0;
  padding: 0;
  text-align: start;
  z-index: 2;
  background-color: #ffffff;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}

.select-options {
  position: absolute;
  width: 100%;
  z-index: 2;
}

.custom-select-container li {
  list-style-type: none;
  padding: 6px 20px;
  cursor: pointer;
}

.custom-select-container li:hover {
  background-color: #ebebeb;
  color: #000;
}
.spinner {
  height: 15px;
  width: 15px;
}
.loader-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px;
}
