.bookWrapper {
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
  padding: 30px 20px 20px 40px;
  margin-top: 50px;
}
.scrollWrapper {
  height: 65vh;
  overflow: auto;
  padding-top: 20px;
}
.actions {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 8px;
  margin-right: 30px;
  margin-bottom: 20px;
  font-size: 1.6em;
}
.actions span:hover {
  transform: scale(0.9);
  color: #eea74a;
  cursor: pointer;
}
.fluid {
  display: flex;
  gap: 20px;
  flex-wrap: wrap;
  margin-bottom: 20px;
  margin-left: 5px;
}
.formGroup {
  width: 380px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
.bookType {
  z-index: 100;
}
.language {
  z-index: 90;
}
.class {
  z-index: 80;
}
.subject {
  z-index: 70;
}
.topic {
  z-index: 60;
}
.textarea {
  width: 380px;
  margin-top: 10px;
  font-size: 1em;
  padding: 10px;
}
.pages {
  display: flex;
  gap: 20px;
  margin-top: 10px;
}

/* .ml2 {
  margin-left: 20px;
} */

.btnBig {
  height: 118px;
  width: 130px;
  border: 1px dashed #222;
  font-size: 2em;
  background-color: rgba(183, 204, 221, 0.101);
}
.btnBig:hover {
  background-color: rgba(144, 180, 207, 0.145);
}
button i {
  font-size: 1.4em;
}
.btn,
.btnPublish {
  min-width: 150px;
  padding: 10px 15px;
  border-radius: 3px;
  background-color: #1e88e5;
  color: #f9f9f9;
  font-size: 0.95em;
  text-transform: uppercase;
  border: none;
}
.btn:hover,
.btnPublish:hover {
  background-color: #4da1ea;
  color: #fff;
  transform: scale(1.02);
}
.btnPublish {
  background-color: transparent;
  color: #0d0d0d;
  border: 2px solid #1e88e5;
}
.thumnailPreview {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
}
.thumbnail {
  width: 250px;
  height: 300px;
  border: 1px solid #ccc;
  box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.2);
  transform: scale(0.8);
  transform-origin: 0 0;
  overflow: hidden;
}
.previewSize {
  width: 55%;
  top: 5%;
  left: 20%;
}
.fullView {
  margin-top: -30px;
  border: 1px solid #ccc;
  min-width: 500px;
  transform: scale(0.85);
}
.thumbnailAction {
  display: flex;
  align-items: center;
  padding: 2px 10px;
  border: none;
  position: absolute;
  top: 0;
  right: 5px;
  font-size: 1.3em;
  gap: 10px;
  background-color: rgba(13, 13, 13, 0.796);
  z-index: 100;
}
.thumbnailAction i {
  cursor: pointer;
  color: #fff;
}
.thumbnailAction i:hover {
  color: #eea74a;
  transform: scale(1.2);
}
.thumbnailAction select {
  font-size: 0.9rem;
  font-weight: bold;
  background-color: #fff;
  color: #000;
  outline: none;
}
.thumbnailAction select option {
  border-radius: 0;
  text-align: center;
  padding: 0;
}

.checkbox {
  width: 20px;
  height: 20px;
  cursor: pointer;
}
.checkbox:checked {
  accent-color: #1b5a4e;
}

/* cover image */
.image {
  position: relative;
  margin-right: 20px;
}
.coverLbl {
  position: absolute;
  top: 10px;
  left: 10px;
}
.img {
  width: 240px;
  height: 240px;
  object-fit: cover;
}
.imageUpload {
  position: absolute;
  top: 0;
  right: 15px;
  font-size: 1.5rem;
}
.imageUpload label {
  cursor: pointer;
  background-color: rgba(70, 28, 159, 0.477);
  color: #fff;
  padding: 2px 10px 5px 10px;
  border-radius: 0 0 4px 4px;
}
