.root {
  padding: 20px;
  overflow: auto;
  height: 100%;
}

.row {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
}

/* Actions */
.actions {
  justify-content: flex-end;
  align-items: center;
  color: #999;
  padding: 10px 0;
}
.searchContainer {
  position: relative;
}
input.search {
  padding: 5px 8px;
  font-size: 0.85rem;
  border: solid 1px #eee;
  color: #888;
  margin-right: 5px;
  min-width: 200px;
}
.search:focus {
  outline: none;
}

.searchBtn {
  position: absolute;
  border: 1px solid #eee;
  background-color: #f9f9f9;
  color: #000;
  right: 10px;
  top: 4px;
  padding: 4px;
  font-size: 0.6em;
}
.search:focus.searchBtn {
  right: 50px;
}
/* Tabs */
ul.tabs {
  list-style-type: none;
  display: flex;
  align-items: center;
  gap: 20px;
  margin-left: 10px;
  border-bottom: 1px solid #eee;
  padding-bottom: 10px;
}

.tabs li {
  color: #aaa;
  cursor: pointer;
  min-width: 100px;
  display: flex;
  align-items: center;
  gap: 5px;
}
.tabs li.active {
  color: #5274a3;
  font-size: 1.1em;
}
