.root {
  background-color: #fff;
  color: #000;
  height: 100vh;
  overflow: scroll;
}
.navIcon {
  display: block;
  padding: 2em 4em;
}
.page {
  display: flex;
  flex: 1;
  padding: 1em 4em;
  gap: 20px;
}
.right {
  flex: 2;
  width: 80%;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  border-radius: 4px;
}
.left {
  flex: 1;
}
.strengthContainer {
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  width: 350px;
  height: 300px;
  margin-bottom: 4em;
  margin-right: 2em;
}
.tags {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  margin-bottom: 20px;
  margin-top: -10px;
}
.recommendations {
  padding: 1.5em;
}
.performanceWrapper {
  background-color: #3d2e96;
  color: #fff;
  padding: 20px;
}
.lineChart {
  /* width: 100%; */
  height: 250px;
  /* max-width: 100%; */
}
/* Media Queries */
@media (max-width: 991.98px) {
  .root {
    height: 90vh;
    overflow: scroll;
  }
  .sub_heading {
    font-size: 0.9em;
    font-weight: 500;
  }
  .navIcon {
    padding-left: 1.2em;
    margin-top: 1em;
    font-size: 0.9em;
  }
  .page {
    flex-direction: column;
    padding: 0;
  }
  .right {
    order: 1;
    box-shadow: none;
    border-radius: 0;
    width: 100%;
  }
  .left {
    order: 2;
    padding: 2em 1.2em;
    /* background-color: rgb(188, 206, 255); */
  }
  .tags {
    margin-bottom: 0;
    margin-top: 0;
  }
  .lineChart {
    width: 100%;
    height: 250px;
    /* max-width: 100%; */
  }
  .recommendations {
    margin: 1.5em 0 1.5em 0;
    padding: 0;
    padding-left: 1.1em;
  }
  .strengthContainer {
    height: 300px;
    color: #000;
    box-shadow: none;
    width: 100%;
    margin: 0;
  }
  .strengthContainer > * {
    transform: scale(1.1);
  }
  .performance_subject {
    /* background-color: rgb(188, 206, 255); */
  }
}

@media (min-width: 768px) and (max-width: 991.98px) {
}

@media (min-width: 992px) and (max-width: 1199.98px) {
}

@media (min-width: 1200px) and (max-width: 1400px) {
}
