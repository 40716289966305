.row,
.row2 {
  display: flex;
}

.column,
.column2 {
  display: flex;
  flex-direction: column;
  flex: 1;
}

.row2 {
  gap: 1em;
  flex-wrap: wrap;
  margin-top: 50px;
}
.column2 {
  padding: 20px 30px;
  margin: 0 3.4em;
}

.positionRight {
  display: flex;
  justify-content: center;
}

/* Media Queries */
@media (max-width: 767.98px) {
  .column2 {
    margin: 0;
  }
}

@media (min-width: 768px) and (max-width: 991.98px) {
  .column2 {
    margin: 0;
  }
}
