.root {
    padding: 20px;
    overflow: auto;
    height: auto;
  }

  .row {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
  }
  .page_layout{
    gap: 20px !important;
  }
  .formContainer{
    flex: 0.6;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 5px;
    border-radius: 10px;
    padding: 15px;
  }

  .questions_section{
    background: #f7f5fa;
    flex: 0.4;
    padding: 40px 30px;
  }

  .question_list_container{
    height: 45vh;
    overflow-y: scroll;
    padding-right: 20px;
  }

  /* Question Card */
.questionCard {
    box-shadow: rgba(27, 27, 27, 0.16) 0px 5px 4px;
    padding: 10px 20px 20px 20px;
    font-size: 14px;
    background: #F9F9F9;
    border-radius: 10px;
    margin-bottom: 20px;
  }
  
  .questions_card_header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 10px;
  }

  .questions_card_header > p {
    font-size: 13px;
    color: gray;
    margin: 0;
  }

  .card_actions{
    display: flex;
    gap: 5px;
    align-items: center;
  }

  .card_actions i:hover {
    color: rgb(178, 89, 5);
  }

  .checkbox, .card_icon{
    width: 20px;
    height: 20px;
  }
  .checkbox{
    margin-top: 5px;
  }

  .tooltip {
    font-size: 1.2em;
    text-transform: uppercase;
    letter-spacing: 0.09em;
  }
  .topic {
    font-size: 1.1em;
    font-weight: bolder;
    margin: 0px;
  }
  .topic,
  .question {
    hyphens: auto;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2; /* number of lines to show */
    line-clamp: 2;
    -webkit-box-orient: vertical;
    margin: 10px 0 15px 0;
  }
  .fluid {
    display: flex;
    gap: 20px;
    flex-wrap: wrap;
    margin-bottom: 20px;
    margin-left: 5px;
  }
  .formGroup {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    flex: 1;
  }
  .difficulty {
    z-index: 100;
  }
  .questionType {
    z-index: 95;
  }

  /* Question list */
  .bookWrapper {
    box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
    padding: 20px 20px;
    margin-top: 20px;
  }
  .scrollWrapper {
    height: 30vh;
    overflow: auto;
    padding-right: 30px;
  }

  .search_container{
    margin-bottom: 15px;
    display: flex;
    position: relative;
    height: 45px;
    width: 100%;
  }

  .search_container input{
    background: #fff;
    border: 1px solid #E9E9E9;
  }

  .search_container button{
    width: 50px;
    background: #cdcdcd;
    color: black;
    border: none;
    border-radius: 3px;
  }

  .addButton{
    background: #475987;
    text-align: center;
    border: none;
    outline: none;
    padding: 10px 20px;
    color: #ffffff;
    border-radius: 2px;
  }

  .root_header{
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 10px 0;
  }

  .root_header_title{
    display: flex;
    gap: 10px;
  }

  .root_header_title > h5{
    font-weight: bolder;
    text-transform: uppercase;
  }

  .actions {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: 8px;
    margin-right: 30px;
    font-size: 1.6em;
  }

  .search_input{
    font-size: 1.05em;
  font-weight: 400;
  width: 100%;
  height: 100%;
  text-indent: 10px;
  border: 1px solid var(--color-neutral-black);
  box-sizing: border-box;
  -webkit-transition: 0.5s;
  transition: 0.5s;
  outline: none;
  }

  .search_input:focus {
    border: 1px solid #006dee;
  }
  
  .search_input::placeholder {
    font-size: 0.84em;
    font-weight: 500;
  }

.question_card_container{
  margin: 20px;
}

.question_card_container .question_header{
  font-size: 30px;
  font-weight: bolder;
}

.question_card_container .question_desc{
  justify-content: space-between;
  align-items: center;
}

.badge{
  background: #ff0000;
  padding: 2px 10px;
  border-radius: 5px;
  color: white;
  font-weight: bold;
  font-size: 12px;
}

.question_section{
  margin: 15px 0;
  padding: 20px;
  /* background: #eee; */
  border-radius: 10px;
  overflow: auto;
  height: 50vh;
}

.question_section_header{
  font-weight: bolder;
  font-size: 18px;
}

.question_section_text, .question_text{
  color: red;
}

.label{
  font-weight: bolder;
  margin: 0 10px;
}

.answer{
  color: green;
  margin: 10px 0;
}



/* question */
.card {
  border: 1px solid #ddd;
  padding: 20px;
  margin: 20px;
}
.questionText {
  padding-left: 20px;
  padding-top: 40px;
}
.label {
  padding: 10px 20px;
  margin-right: 20px;
}
.check {
  background-color: green;
  color: floralwhite;
}