.pageRoot{
 width: 100%;
 height: 100%;
 display: flex;
 align-items: center;
 justify-content: center;
}
.pageBody{
  width: 40vw;
 background-color: white;
 border-radius: 6px;
 padding: 3px;
 height: 60vh;
}
.innerBody{
  border: 1px solid #000;
  height: 100%;
  padding: 20px;
  border-radius: 6px;
  display: flex;
  flex-direction: column;
}
.paymentGateways{
  display: flex;
  flex-wrap: wrap;
  gap: 15px;
  flex: 1;
}
.gatewayCard{
padding: 3px;
box-shadow: rgba(6, 24, 44, 0.4) 0px 0px 0px 2px, rgba(6, 24, 44, 0.65) 0px 4px 6px -1px, rgba(255, 255, 255, 0.08) 0px 1px 0px inset;
border-radius: 2px;
height: 70px;
width: 150px;
cursor: pointer;
display: flex;
align-items: center;
justify-content: center;
}
.active_gateway{
  border: 2px solid #2b4fe2;
}
.disabled_gateway{
  opacity: .5;
}
.gatewayCard img{
  object-fit: contain;
}
.gatewayIcon{
  height: 60px;
}
.bottomSection{
  display: flex;
  gap: 20px;
}

.cancel_payment_button {
  padding: 5px 20px;
  border: 1.5px solid black;
  background: transparent;
  color: black;
  font-size: 14px;
  text-transform: uppercase;
  margin-left: 10px;
  border-radius: 1px;
}
.continue_payment_button{
  padding: 5px 20px;
  color: white;
  font-size: 14px;
  text-transform: uppercase;
  margin-left: 10px;
  border-radius: 1px;
}
.header_section{
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  min-height: 120px;
}
.book_title{
  font-size: 20px;
  font-weight: 600;
  text-transform: capitalize;
  line-height: 35px;
}
.book_price{
  font-size: 2.5em;
  font-weight: 600;
  line-height: 35px;
  letter-spacing: 1px;
}
.payment_gateway_title{
  display: flex;
  align-items: center;
  gap: 15px;
  margin-bottom: 20px;
}
.payment_gateway_title hr{
  flex: 1;
}
.payment_gateway_title span{
  font-size: 1.2em;
  font-weight: 500;
  opacity: .8;
}