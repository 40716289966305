.cardWrapper{
background-color: #F4F4F4;
  height: 120px;
  padding: 15px;
  border-radius: 5px;
  border: 1px solid var(--border-color2);
  display: flex;
}
.leftSection{
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex: 2px;
}
.rightSection{
  flex: 1px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.invoiceAmount{
font-size: 1.2em;
font-weight: 600;
}
.currentPlanInfor{
  display: flex;
  justify-content: space-between;

}
.invoiceDuration{
  font-size: .8em;
  font-weight: 500;
  color: var(--transparent-black);
  opacity: .7;
  text-transform: uppercase;
}
.nextPaymetText{
  font-size: .8em;
  font-weight: 500;
  color: var(--transparent-black);
  opacity: .7;
  margin-top: 5px;
}
.currentPlanText{
  font-size: .9em;
  font-weight: 700;
}
.planName{
  font-size: .85em;
  font-weight: 500;
}
.nextPaymentDate{
  font-size: 1em;
  font-weight: 600;
}
.PlanAmout{
font-size: 1.2em;
font-weight: 600;
}
.planDuration{
  font-size: .9em;
  font-weight: 500;
  opacity: .7;
}
.leftTop{
  display: flex;
  align-items: center;
  gap: 3px;
}
.cardBottom{
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.cancelBtn{
  font-size: .75em;
  font-weight: 600;
  border: 1px solid var(--color-black);
  color: var(--color-black);
  border-radius: 2px;
  padding: 2px 10px;
  background-color: transparent;
  opacity: .8;
  transition: all .3s linear;
  background-color: #FFFFFF;
}
.cancelBtn:hover{
  transform: scale(.95);
}
.autoRenewWrap{
  display: flex;
  align-items: center;
}
.renewalLabel{
  font-size: .7em;
  font-weight: 500;
}
.switchBtnWrap{
  transform: scale(.8);
}