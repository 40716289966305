@import url('https://fonts.googleapis.com/css2?family=DM+Sans&display=swap');
.pageBody{
  display: flex;
  flex-direction: column;
  height: 100%;
  padding: 10px 2em;
}
.pageTop{
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.pageHeader{
  font-size: 1.7em;
  font-weight: 600;
  font-family: 'DM Sans', sans-serif;
}
.uploadCourseBtn{
  width: 200px;
}
.mainBody{
  flex: 1px;
  overflow-y: scroll;
}
.skeletonLogLoader{
  height: 80px;
  margin: 20px 0;
}
.emptyHistory{
  min-height: 50vh;
  display: flex;
  align-items: center;
  justify-content: center;
}
.emptyHistory h3{
  font-size: 1.4em;
  font-weight: 600;
  opacity: .7;
}