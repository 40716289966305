.root {
  min-height: 100vh;
  background-color: #fff;
  position: relative;
}

.page {
  margin: 0;
  padding: 1em 5em;
}

.topBar {
  display: flex;
  justify-content: space-between;
  padding: 30px 60px 0 20px;
  margin-bottom: -20px;
  align-items: center;
}

.subjectComponent {
  margin-left: auto;
}

.card {
  box-shadow: 0 2px 4px 0 rgba(14, 30, 37, 0.12),
    0 2px 16px 0 rgba(14, 30, 37, 0.32);
  margin-left: 10px;
  padding: 20px 30px 30px 30px;
  background: linear-gradient(to left, #5555ff, #7482d8);
  margin-bottom: 20px;
  /* width: 400px; */
  width: 100%;
  /* height: 350px; */
  border-radius: 10px;
  position: relative;
  background-image: url(../../../assets/png/reviewCard.png);
  background-position: right bottom;
  background-repeat: no-repeat;
}

.cardTop {
  display: flex;
  /* padding: 20px 30px; */
  justify-content: flex-end;
}

.cardTop {
  border-radius: 12px 12px 0 0;
  align-items: center;
  /* color: #fff; */
  gap: 5px;
}

.icon {
  font-size: 1.5em;
}

.cardContent {
  flex-direction: column;
  /* padding: 30px; */
}

.cardContent p {
  font-size: 0.8em;
  color: #777;
  margin-top: 10px;
  margin-bottom: -1px;
}

.cardContent h1 {
  font-weight: bold;
  font-size: 1rem;
}

.cardContent button {
  margin-top: 20px;
  padding: 5px 10px;
  font-size: 0.89em;
  background-color: #fff;
  color: #4a5494;
  border: 2px solid #4a5494;
  display: flex;
  align-items: center;
  gap: 10px;
}

.cardContent button:hover {
  background-color: #4a5494;
  color: #fff;
}

.loaderRow {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  margin-top: 30px;
}

.skeletonLogLoader {
  height: 175px;
  max-width: 300px;
  width: 100%;
}

.searchContainer {
  position: relative;
}

input.search {
  padding: 10px 8px;
  font-size: 0.85rem;
  border: solid 1px #eee;
  color: #888;
  margin-right: 5px;
  min-width: 300px;
}
.search:focus {
  outline: none;
}

.searchBtn {
  position: absolute;
  border: 1px solid #eee;
  background-color: #f9f9f9;
  color: #000;
  right: 10px;
  top: 5px;
  padding: 4px;
  font-size: 0.9em;
}
.search:focus.searchBtn {
  right: 50px;
}
.row {
  display: flex;
  align-items: center;
  gap: 15px;
}

.bucket_container {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 25px;
  margin: 50px 0;
}

@media screen and (max-width: 650px) {
  .page {
    padding: 2em 1.5em;
  }
  .bucket_container {
    grid-template-columns: 1fr;
  }
  .topBar {
    display: grid;
    grid-template-columns: 1fr;
  }
  .topBar > .row {
    display: grid;
    grid-template-columns: 1fr;
    margin: 10px 0;
  }
  .card {
    margin-left: 0;
  }
}

@media screen and (min-width: 651px) and (max-width: 1000px) {
  .page {
    padding: 2em 3.5em;
  }
  .bucket_container {
    grid-template-columns: 1fr 1fr;
  }
  .topBar {
    display: grid;
    grid-template-columns: 1fr;
  }
  .topBar > .row {
    margin: 10px 0;
  }
}
