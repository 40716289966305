.form_container{
  margin: 0 30px;
}
.fluid {
    display: flex;
    gap: 20px;
    flex-wrap: wrap;
    margin-bottom: 20px;
    margin-left: 5px;
  }

  .formGroup {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    flex: 1;
  }

  .row {
    display: flex;
    flex-direction: row;
    align-items: center;
    flex: 1;
    gap: 10px;
  }

  .term_container{
    background: #f2f5f8;
    /* box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px; */
    padding: 15px;
    border-radius: 5px;
    /* border: 1px solid #444; */
    /* background-color: #f2f5f8; */
    
  }

  .term_container input[type="checkbox"] {
    width: 30px;
    height: 30px;
    /* accent-color: rgb(70, 28, 159); */
    accent-color: black;
  }

  /* .term_name{
    color: ;
  } */


  .btn {
    border: 1px solid #444;
    padding: 5px 15px;
    cursor: pointer;
    border-radius: 4px;
    margin: 10px 0;
    padding: 10px 15px;
  }
  .btn:hover {
    transform: scale(1.1);
    background-color: #444;
    color: #fff;
  }

  .question {
    width: 100%;
  }

  .header_title{
    font-weight: bolder;
    text-transform: uppercase;
    /* text-align: center; */
    margin-bottom: 40px;
    font-size: 25px;
  }

  .term_section{
    margin: 20px 0 40px 0;
  }

  .actionGroup {
    justify-content: flex-start;
    margin-right: 30px;
  }