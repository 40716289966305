.rootPage {
  padding: 20px;
}
.topBar {
  display: flex;
  align-items: center;
}
.topBar h3 {
  font-size: 1.2em;
}
.topBar span {
  margin-left: auto;
}
.title {
  justify-content: center;
  padding: 20px 0;
  display: flex;
  justify-content: center;
  margin-top: 20px;
}
p.label {
  text-align: center;
  color: #444;
  margin-top: -20px;
  margin-bottom: 20px;
  font-size: 0.9em;
}
.content {
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
}
.graph {
  flex: 6;
  display: flex;
  justify-content: center;
  padding: 10px 30px;
}
.footer {
  display: flex;
  gap: 5px;
  justify-content: center;
  margin-top: 40px;
}
.footer button {
  padding: 10px 30px;
  background-color: #5e68ac;
  color: #fff;
  border: 0;
  font-weight: bold;
  border: none;
}
.footer button:last-child {
  background-color: transparent;
  border: solid 2px #5e68ac;
  color: #5e68ac;
}

.footer button:hover {
  background-color: #7482d8;
  color: #fff;
}
.footer button:last-child:hover {
  background-color: transparent;
  border: solid 2px #7482d8;
  color: #7482d8;
}

.aside {
  margin-left: 20px;
  position: relative;
  top: 0;
}

.aside button {
  color: #ae570b;
  background-color: transparent;
  border: none;
}

.aside button:hover {
  color: #252c53;
}



@media screen and (max-width: 650px) {
  .footer button {
    padding: 5px 20px;
    font-size: 14px;
  }
  .aside {
    margin: 0;
    text-align: center;
    position: relative;
    top: 0;
  }
}