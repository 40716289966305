.root {
  min-height: 100vh;
  background-color: #fff;
  position: relative;
}
.top {
  padding: 60px 60px 20px 60px;
}
.title {
  font-size: 1.4em;
  font-weight: 600;
  font-family: "DM Sans";
  color: #595798;
}
.loaderWrapper {
  display: flex;
}
.skeletonLogLoader {
  height: 250px;
  width: calc(100 / 3);
}
.centerText {
  text-align: center;
}
.studyTrend {
  color: #fff;
  padding: 1em 0 0 3.5em;
}
.indicator {
  color: #fff;
  text-align: center;
  font-size: 1.4em;
  font-weight: 600;
  font-family: "DM Sans";
}

.textWhite {
  color: white;
}

.emptyRecord {
  background-color: #fff;
  /* position: relative; */
  margin-bottom: 250px;
  text-align: center;
  color: lightgrey;
}

.emptyRecord svg {
  width: 150px;
  height: 150px;
}

.noPerformance {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.noPerformance h5 {
  font-weight: bolder;
  color: #ffe600;
}
.noPerformance p {
  color: white;
}
.indicatorChart {
  height: 250px;
  overflow: hidden;
}
.indicatorChart > * {
  transform: scale(1.5);
}
.greetingsColumn {
  flex: 2 !important;
}
@media (max-width: 767.98px) {
  .top {
    flex-direction: column;
    padding: 20px 30px;
  }
  .signalWrapper {
    display: flex;
    flex-direction: column;
    padding: 5px 10px;
    margin-top: 20px;
  }
  .indicatorChart {
    height: 300px;
  }
  .indicator {
    text-align: start;
    margin-left: -2px;
  }

  .studyTrend {
    /* color: #fff; */
    padding: 1em 0 0 1.2em;
  }
  /* .loaderWrapper {
    display: flex;
  }
  .skeletonLogLoader {
    height: 170px;
    width: calc(100 / 3);
  } */
}

@media screen and (min-width: 767.99px) and (max-width: 1000px) {
  .top {
    flex-direction: column;
    padding: 20px 30px;
  }

  .studyTrend {
    /* color: #fff; */
    padding: 1em 0 0 1.2em;
  }
  .indicator {
    text-align: start;
    margin-left: -2px;
  }
  .signalWrapper {
    display: flex;
    flex-direction: column;
    padding: 5px 10px;
    margin-top: 20px;
  }
  .indicatorChart {
    height: 300px;
  }
}
