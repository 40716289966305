.tableRoot{
  width: 100%;
   border-collapse: collapse;
   background-color: var(--bg-color);
}
.headerRow tr th{
  padding: 15px 0px;
  font-size: .85em;
  font-weight: 600;
  background-color: var(--color-primary);
  color: var(--color-white);
  margin: 0;
}
.headerRow tr th > *{
  width: 100%;
}
.tableBody tr:hover{
  cursor: pointer;
  background-color: var(--bg-color1);
}
.tableBody tr td{
  padding: 15px 5px;
  font-size: .9em;
  font-weight: 500;
}
.tableBody tr td > *{
  width: 100%;
}
.lineDivider{
  border-bottom: 1px dashed var(--color-light-black);
  width: 100%;
}
.tableBody{
  overflow-y: auto;
}