@import url('https://fonts.googleapis.com/css2?family=Roboto&display=swap');

.cardRoot{
  background-color: #FFFFFF;
  border-radius: 5px;
}
.cardTop{
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 2em;
}
.questionNumber{
  font-size: 1.2em;
  font-weight: 600;
}
.rightTop{
  display: flex;
  align-items: center;
  gap: 10px;
}
.dragHandlerBtn{
  padding: 5px;
  border: none;
  background-color: transparent;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  font-size: 1.5em;
  opacity: .8;
}
.deleteRowBtn{
  padding: 5px;
  border: none;
  background-color: transparent;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  font-size: 1.3em;
  opacity: .8;
}
.lineDivider{
  border-bottom: 1px solid #ccc;
}
.cardBody{
  display: flex;
  padding: 15px 2em;
  gap: 30px;
}
.textInput{
  border: none;
  border-bottom: 2.5px solid #293146ce;
  width: 100%;
  font-family: 'Roboto', sans-serif;
}
.textInput::placeholder{
  font-family: 'Roboto', sans-serif;
}
.textInput:focus {
  border: none;
  border-bottom: 2px solid blue;
}
.questionColumn{
  flex: 1px;
  margin-top: 2em;
}
.answerColumn{
  flex: 1px;
}
.answersColumnTop{
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.switchWrapper{
  display: flex;
  align-items: center;
  gap: 7px;
  white-space: nowrap;
}
.switchBtn{
  transform: scale(.7);
}
.switchBtnText{
  font-size: .8em;
  font-weight: 600;
}
.answerRow{
  display: flex;
  align-items: center;
  gap: 10px;
  width: 100%;
  margin: 10px 0;
}
.answerRowAction{
  display: flex;
  align-items: center;
  gap: 7px;
}
.addRowButtton{
  background-color: transparent;
  border: none;
  height: 15px;
  width: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #FFFFFF;
  background-color: var(--color-primary);
  border-radius: 50%;
  padding: 2px;
}
.removeRow{
  background-color: transparent;
  border: none;
  height: 15px;
  width: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #FFFFFF;
  background-color: rgb(190, 26, 26);
  border-radius: 50%;
  padding: 2px;
}
.makePraticequestion{
  display: flex;
  align-items: center;
  gap: 8px;
  font-size: 1.05em;
  font-weight: 500;
  padding: 10px 5px;
}