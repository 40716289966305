.root {
  min-height: 60vh;
  width: 100%;
  background-color: #fff;
  display: flex;
  justify-content: space-between;
}
.leftContainer,
.rightContainer {
  padding: 4em;
}
.leftContainer {
  flex: 1;
}
.rightContainer {
  flex: 2;
  background-color: #6155d7;
  background-image: url("../../../assets/png/contact2.png");
  background-repeat: no-repeat;
  background-size: contain; /* This ensures the image covers the entire div */
  background-position: center; /* This centers the image */
}
.leftContainer h2 {
  font-size: 1.3em;
  font-weight: 700;
}
.leftContainer p {
  font-size: 0.95em;
}
.form > div {
  margin-top: 20px;
}
.form > div:last-child {
  margin-top: 10px;
}
.form i {
  font-size: 0.9em;
  color: #666;
  font-style: normal;
}
.submitAction {
  display: flex;
}
.submitBtn {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0.7em 0;
  background-color: #6155d7;
  color: #fff;
  border-radius: 6px;
  flex: 1;
  border: none;
  font-size: 0.95em;
}

/* Media Queries */
@media (max-width: 767.98px) {
  .rightContainer {
    display: none;
  }
  .leftContainer {
    padding: 2em;
  }
}

@media (min-width: 768px) and (max-width: 991.98px) {
  .leftContainer {
    flex: 2;
    padding-bottom: 3em;
  }
  .rightContainer {
    flex: 1;
  }
}

@media (min-width: 992px) and (max-width: 1199.98px) {
  .leftContainer {
    flex: 2;
    padding-bottom: 3em;
  }

  .rightContainer {
    flex: 1;
  }
}

@media (min-width: 1200px) and (max-width: 1400px) {
}
