.itemRoot{
  display: flex;
  align-items: center;
  gap: 15px;
  padding: 15px 5px;
}
.itemText{
  font-size: 1em;
  font-weight: 600;
  font-family: 'Quicksand';
}
.itemIcon{
  font-size: 1.6em;
}