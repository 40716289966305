.rootPage {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.848);
  z-index: 1000;
}

.modal {
  color: #000;
  width: 70%;
  height: 80%;
  margin: 3% 15%;
  background-repeat: no-repeat;
  background-position: left bottom;
  background-color: rgb(250, 250, 252);
  border-radius: 15px;
  box-shadow: rgba(6, 24, 44, 0.4) 0px 0px 0px 2px,
    rgba(6, 24, 44, 0.65) 0px 4px 6px -1px,
    rgba(255, 255, 255, 0.08) 0px 1px 0px inset;
}

@media (max-width: 767.98px) {
  .modal {
    color: #000;
    width: 100%;
    height: 100%;
    margin: 0;
    border-radius: 0;
    box-shadow: none;
  }
}

@media (min-width: 768px) and (max-width: 991.98px) {
  .modal {
    color: #000;
    width: 100%;
    height: 100%;
    margin: 0;
    border-radius: 0;
    box-shadow: none;
  }
}

@media (min-width: 992px) and (max-width: 1199.98px) {
  .modal {
    color: #000;
    width: 100%;
    height: 100%;
    margin: 0;
    border-radius: 0;
    box-shadow: none;
  }
}
