.dialog {
  position: absolute;
  top: 10%;
  left: 30%;
  width: 400px;
  min-height: 180px;
  border-radius: 5px;
  background-color: #fff;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 20px;
  z-index: 1000;
}
.message {
  font-weight: 600;
  font-size: 1.1em;
  text-align: center;
}

.actions {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px;
  margin-top: 20px;
}
.btnCancel,
.btnContinue {
  padding: 5px 15px;
}

.btnCancel {
  background-color: transparent;
  border: 1px solid #222;
  color: #222;
}
.btnContinue {
  background-color: #222;
  color: #fff;
}

.btnCancel:hover {
  background-color: #e9e9e9;
  border: 1px solid #222;
  color: #222;
}
.btnContinue:hover {
  background-color: #3a3838;
  color: #fff;
}
/* Media Queries */
@media (max-width: 767.98px) {
  .dialog {
    top: 10%;
    left: 0;
    width: 90%;
    min-height: 180px;
    margin: 10px;
    border-radius: 5px;
    padding: 1.5em;
  }

  .btnCancel,
  .btnContinue {
    font-size: 0.85em;
  }
}
