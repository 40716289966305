.pageRoot{
  display: flex;
  height: 100%;
  padding: 0 20px;
}
.navigationPaths{
  width: 200px;
  min-width: 200px;
  height: 100%;
}
.contentuploader{
  flex: 1px;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 10px 36px 0px, rgba(0, 0, 0, 0.06) 0px 0px 0px 1px;
  height: 97%;
  background-color: #ffffff ;
  border-radius: 2px;
  display: flex;
  flex-direction: column;
}
.linedivider{
  width: 100%;
  border-bottom: 1px solid #ccc;
}
.pageHeader{
  padding: 20px 40px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.pageTitle{
    font-size: 1.4em;
  font-weight: 500;
  text-transform: capitalize;
  font-family: 'Quicksand';
}