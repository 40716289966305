.searchBox {
  position: relative;
  width: 100%;
}
.inputSearch {
  background: #ffffff;
  box-shadow: 0px 8px 20px rgba(22, 22, 17, 0.04);
  border-radius: 0 !important;
  width: 100%;
  padding: 6px;
  border: none;
  outline: none;
  text-indent: 10px;
  height: 60px;
  padding-right: 30px;
  border: 1px solid #ccc;
}
.searchIcon {
  position: absolute;
  right: 15px;
  top: 30%;
  font-size: 1.5em;
  /* opacity: 0.8; */
  cursor: pointer;
}
.searchIcon:hover {
  transform: scale(1.3);
}
