.cardWrapper {
  display: flex;
  gap: 1.1em;
  max-width: 400px;
  cursor: pointer;
  transition: right ease 0.4s;
  position: relative;
  padding: 10px;
  right: 0;
  font-size: 1.2em;
}
.cardWrapper:hover {
  transition: ease-in 0.4s;
  right: -15px;
}
.cardWrapper:hover .featureIcon {
  transition: ease-in 0.4s;
}
.cardWrapper:hover .cardTitle,
.cardWrapper:hover .featureIcon {
  color: #ffb300;
}
.cardTitle {
  font-size: 1.3rem;
  font-weight: 700;
  color: var(--color-white);
}
.cardBody {
  font-size: 0.9rem;
  font-weight: 400;
  color: #f5f5f5;
}
.featureIcon {
  font-size: 25px;
  color: var(--color-2);
}

/* -----------------------------------------------------------------------------
                          MEDIA QUARY
----------------------------------------------------------------------------- */

@media (max-width: 767.98px) {
  .cardWrapper {
    max-width: 100%;
    cursor: default;
    transition: none;
  }
  .cardWrapper:hover {
    transition: none;
    right: 0;
  }
  .cardWrapper:hover .featureIcon {
    transition: none;
  }
  .cardWrapper:hover .cardTitle {
    color: var(--color-white);
  }
  .cardWrapper:hover .featureIcon {
    color: var(--color-2);
  }
  .cardTitle {
    font-size: 1.5em;
    font-weight: 700;
    color: var(--color-white);
  }
}

@media (min-width: 768px) and (max-width: 1199.98px) {
}

@media (min-width: 1200px) {
}
