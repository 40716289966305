.cardWrapper{
  background-color: #EFFFF2A1;
  height: 120px;
  padding: 15px;
  border-radius: 5px;
  border: 1px solid var(--border-color2);
  display: flex;

}
.currentPlanInfor{
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex: 2px;
}
.planPricing{
  flex: 1px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.currentWrapper{
  display: flex;
  align-items: center;
  gap: 7px;
}
.currentPlanText{
  font-size: 1.1em;
  font-weight: 700;
}
.planName{
  font-size: .9em;
  font-weight: 500;
  text-transform: uppercase;
}
.timeLeft{
  font-size: .8em;
  font-weight: 500;
  color: var(--transparent-black);
  opacity: .7;
}
.PlanAmout{
font-size: 1.2em;
font-weight: 600;
}
.planDuration{
  font-size: .9em;
  font-weight: 500;
  opacity: .7;
}
.cardBottom{
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.cancelBtn{
  font-size: .75em;
  font-weight: 600;
  border: 1px solid #FC0000;
  color: #FC0000;
  border-radius: 2px;
  padding: 2px 10px;
  background-color: transparent;
  opacity: .8;
  transition: all .3s linear;
}
.cancelBtn:hover{
  transform: scale(.95);
}
