.root {
  width: 450px;
  position: relative;
  top: 7%;
  left: 35%;
}
.actions,
.actions i {
  display: flex;
  justify-content: center;
}
.actions {
  margin-bottom: 10px;
  gap: 10px;
}
.actions i {
  width: 30px;
  height: 30px;
  border-radius: 100px;
  align-items: center;
  font-size: 20px;
  cursor: pointer;
  background-color: #333;
  color: aliceblue;
}
.actions i:hover {
  background-color: aliceblue;
  color: #000;
}

.form {
  padding: 20px;
  border-radius: 5px;
  background-color: aliceblue;
  height: 450px;
}

.title,
.content {
  width: 100%;
  padding: 10px;
  resize: none;
  outline: none;
  border: 1px dashed #111111;
  background-color: transparent;
}
.title {
  height: 60px;
  overflow-y: hidden;
}
.content {
  height: 280px;
  overflow-y: hidden;
}
.pageAction {
  display: flex;
  align-items: baseline;
  justify-content: space-between;
  margin-top: 10px;
}
.pageAction span {
  font-size: 13px;
}
i.delete {
  margin-left: auto;
  font-size: 20px;
  cursor: pointer;
}
.pages {
  display: flex;
  justify-content: flex-end;
  gap: 5px;
  position: absolute;
  bottom: 10px;
  right: 20px;
}
.pages i {
  background-color: #000;
  color: #fff;
  width: 20px;
  height: 20px;
  font-size: 13px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  font-style: normal;
  border-radius: 3px;
}
.pages i:hover {
  background-color: #444;
}
textarea::placeholder {
  color: #222;
}

p.noteTitle {
  font-weight: 600;
}
pre.noteContent {
  white-space: pre-wrap; /* Allow text to wrap */
  word-wrap: break-word; /* Wrap long words if needed */
  max-height: 310px;
  overflow: auto;
}

/* Media Queries */
@media (max-width: 767.98px) {
  .root {
    width: 90%;
    top: 0;
    left: 0;
    margin: 10% 10px;
  }
}
