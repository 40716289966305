.inputField {
  font-size: 1.05em;
  font-weight: 400;
  width: 100%;
  height: 100%;
  text-indent: 10px;
  border: 1px solid var(--color-neutral-black);
  box-sizing: border-box;
  -webkit-transition: 0.5s;
  transition: 0.5s;
  outline: none;

}
.inputField:focus {
  border: 1px solid #006dee;
}

.inputField::placeholder {
  font-size: 0.84em;
  font-weight: 500;
}

.inputWrapper {
  position: relative;
  height: 45px;
  width: 100%;
}

.passwordEndIcon {
  position: absolute;
  top: 50%;
  right: 10px;
  transform: translateY(-50%);
  z-index: 1;
  width: 20px;
}

.passIcon {
  color: var(--color-light-black) !important;
}

.startIcon {
  position: absolute;
  top: 50%;
  left: 10px;
  transform: translateY(-50%);
  width: 20px;
  z-index: 10;
}

.passwordEndIcon > * {
  font-size: 23px;
  font-weight: 200;
}
/* @media screen and (max-width: 960px) {
  .inputField {
    font-size: 1em;
    height: 80%;
  }
} */
