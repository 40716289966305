.nav-section {
  width: 100%;
  position: fixed;
  top: 0;
  z-index: 10;
  color: var(--color-white);
  font-family: "Quicksand";
}
.nav-section-bg {
  background-color: #000;
}
.login-btn {
  color: var(--color-white);
  text-decoration: none;
}
.nav-content {
  display: flex;
  align-items: baseline;
  justify-content: space-between;
  padding: 20px 5%;
}

.logo {
  font-size: 2rem;
  font-weight: 600;
  cursor: pointer;
}
nav ul li {
  list-style-type: none;
  display: inline;
  padding: 8px 15px;
  cursor: pointer;
}
nav ul li a {
  transition: 0.4s;
  color: #262626;
  text-decoration: none;
  letter-spacing: 0.05em;
  font-family: "Nunito";
}
nav ul li a:hover {
  color: #777;
  transform: scale(1.1);
}

.nav-btn {
  transition: 0.4s;
  border-radius: 2px;
  width: auto;
  color: #fff !important;
  padding: 5px 10px;
  text-align: center;
  border: 1px solid #fff;
  letter-spacing: 0.05em;
  font-family: "Quicksand";
  font-size: 0.9em;
}
.nav-btn:hover {
  transform: scale(1.1);
  cursor: pointer;
}
.mobile {
  display: none;
}
.hambuger {
  display: none;
}
.aside-nav {
  display: flex;
  align-items: center;
  gap: 3rem;
}

.aside-nav h4 {
  font-size: 1.2rem;
  cursor: pointer;
}
.aside-nav h4:hover {
  color: #777;
  transition: 0.3s;
}
.aside-nav1 {
  display: none;
}

ul li a {
  cursor: pointer;
}

@media (max-width: 767.98px) {
  .nav-section {
    background-color: #000;
  }
  .nav-content {
    display: flex;
    justify-content: space-between;
    align-items: left;
    padding: 1em 1.5em;
  }
  .mobile {
    display: block;
  }
  .logo {
    font-size: 1.6rem;
    padding: 10px 20px;
  }
  .hambuger {
    display: inline;
    font-size: 1.6rem;
  }
  .nav-menu-list {
    padding-top: 40px;
    list-style-type: none;
    display: block;
    text-align: left;
    position: absolute;
    top: 70px;
    left: 0;
    width: 100%;
    background-color: black;
    color: #ccc;
    height: 100vh;
    padding-bottom: 20px;
    transition: all 0.5s ease;
    z-index: 10;
  }
  nav ul li {
    display: flex;
    align-items: center;
    gap: 20px;
    margin: 0;
    padding-top: 20px;
    letter-spacing: 0.05em;
  }
  nav ul li a {
    color: #ccc;
  }
  nav ul li a:hover {
    color: hsl(0, 0%, 97%);
    transition: 0.3s;
  }

  .aside-nav1 {
    display: block;
    padding-left: 3rem;
    padding-right: 3rem;
  }
  .aside-nav1 h4 {
    font-size: 1.4rem;
    text-align: center;
  }
  .aside-nav {
    display: none;
  }
  .nav-btn {
    transition: 0.4s;
    border-radius: 0;
    width: auto;
    color: #efc36a !important;
    padding: 0;
    text-align: left;
    border: none;
    letter-spacing: 0.05em;
    display: flex;
    align-items: center;
    gap: 20px;
  }
  .nav-btn:hover {
    transform: scale(1.1);
    cursor: pointer;
  }

  .hide-menu-list {
    padding-top: 50px;
    list-style-type: none;
    display: block;
    text-align: center;
    position: absolute;
    top: 70px;
    width: 100%;
    left: -100%;
    color: #ccc;
    height: 100vh;
    padding-bottom: 20px;
    transition: all 0.5s ease;
    z-index: 10;
  }
}
@media screen and (min-width: 767.99px) and (max-width: 1000px) {
  .nav-content {
    padding-left: 3%;
    padding-right: 3%;
  }
  nav ul li a {
    font-size: 1.2em;
  }
  .logo {
    font-size: 1.6rem;
  }
  .hide-menu-list {
    font-size: 0.9rem;
  }
  .aside-nav1 {
    font-size: 0.9rem;
  }
}
