.root {
  min-height: 100vh;
  background-color: #fff;
  position: relative;
}
.top {
  background-color: rgb(61, 43, 162);
  color: #eff9ff;
  padding: 40px 80px;
}

.featuredTop {
  display: flex;
  gap: 40px;
}

.solvingProblem {
  flex: 1;
}
.subMenu {
  flex: 0.9;
}
.row {
  display: flex;
  height: 125px;
  gap: 5px;
}
.row div {
  border-radius: 4px;
  border: solid 1px #cdd0d2;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: 2.5em;
  padding-top: 20px;
  transition: transform 0.3s ease-in-out;
}
.row div:hover {
  transform: scale(0.98);
}
.row div p {
  font-size: 16px;
  margin-top: 10px;
}
.row:first-child {
  margin-bottom: 5px;
}
.catalogue,
.notes,
.bookmark {
  flex: 1;
  cursor: pointer;
}
.catalogue {
  background-color: rgb(119, 46, 138);
}
.self {
  flex: 1.5;
  background-color: rgb(62, 62, 202);
  cursor: pointer;
}
.notes {
  background-color: rgb(221, 149, 15);
}
.bookmark {
  background-color: rgb(20, 111, 20);
}

.mainContent {
  padding: 2.3em 0 2.3em 5em;
  min-height: 100vh;
  font-family: "Inter", sans-serif;
}

.userWelcome {
  font-size: 24px;
  font-weight: 500;
  padding-bottom: 30px;
  color: #5761a4;
}
.userWelcome > * {
  font-family: "Asap", sans-serif;
}
.bookSampleImg {
  width: 400px;
  height: 220px;
  border-radius: 5px;
}
.progressContent {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-right: 5em;
}

.learningHead {
  font-size: 17px;
  font-weight: 800;
  font-family: "Asap", sans-serif;
}
.learning {
  display: flex;
}
.progressContainer {
  position: relative;
  height: 150px;
  width: 150px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.checkContainer {
  margin-left: 125px;
}

.check {
  border-radius: 50%;
  color: var(--color-black);
  background-color: #ccc;
}
.calenderProgress {
  display: flex;
  gap: 25px;
  margin-left: 60px;
}

.calenderWrapper {
  text-align: center;
}
.flash {
  color: #ccc;
}
.instruction2 {
  font-size: 20px;
  font-weight: 800;
  color: #e9cab2;
}
.instruction3 {
  font-size: 16px;
  color: #eff9ff;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 8; /* number of lines to show */
  line-clamp: 8;
  -webkit-box-orient: vertical;
}
.header {
  margin-bottom: 20px;
  font-size: 1.5em;
  font-weight: 800;
  font-family: "DM Sans";
  color: #595798;
}
.bookSection {
  padding: 10px;
  display: flex;
  overflow: scroll hidden;
  margin-bottom: 16px;
  cursor: pointer;
}
.bookWrap {
  display: flex;
  gap: 30px;
}
.sectionContent {
  border: 1px solid #ccc;
  border-radius: 5px;
  display: flex;
  gap: 2em;
  padding: 15px;
  width: 700px;
  margin-bottom: 28px;
}
.bookSampleHeader {
  font-size: 1.5em;
  /* font-weight: 800; */
  padding-top: 20px;
  color: #eff9ff;
}
.bookSampleHeader span {
  color: #cfcdfe;
  /* font-size: 1.1rem; */
}

.myQuestion {
  position: relative;
  width: 100%;
}
.startButtonWrap {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.courseBtn {
  /* margin-top: 3.4em; */
  /* position: absolute; */
  bottom: 0;
  background-color: transparent;
  color: #eff9ff;
  border: 1px solid #eff9ff;
  border-radius: 0px;
  width: fit-content !important;
  padding: 0 30px;
}
.courseBtn:hover {
  transform: scale(0.98);
  transition: all 0.3s ease;
}
.libraryBanner {
  margin: 20px auto;
}
.topComponent {
  margin-bottom: 1em;
}
.scrollbtn {
  position: relative;
  top: 120px;
}
.scrollbtn i {
  font-size: 24px;
}
.swiperContainer {
  width: 100%;
  margin-left: 5px !important;
}
.loaderWrapper {
  display: flex;
  gap: 20px;
}

.skeletonLogLoader {
  height: 250px;
  width: calc(100 / 3);
}

.emptyHistory {
  color: #aaa;
  height: 25vh;
}
@media screen and (max-width: 968px) {
  .sectionContent {
    display: inline;
    border: none;
    padding: 0px;
  }
  .bookSampleImg {
    width: 300px;
    border: none;
  }
  .myQuestion {
    margin-top: 16px;
  }
  .solveProblem {
    width: 100%;
  }
}
@media screen and (min-width: 1200px) {
  .discoverPage {
    max-width: 94.8%;
  }
}

.courseCover {
  width: 35%;
  height: 20%;
  object-fit: cover;
  padding: 2px;
  border: solid 2px #eee;
  border-radius: 4px;
}
.imageWrapper {
  height: 210px;
}
img.courseCover {
  border-radius: 4px;
  height: 100%;
}
.featureImageSectLoader {
  width: 300px;
  height: 100%;
}
.featureImageSectLoader > * {
  height: 200px;
}
.featureTextLoader {
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 10px;
}
.textLoader {
  height: 7px;
}
.noFeatureDisplay {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.5em;
  font-weight: 600;
  opacity: 0.7;
  flex: 1;
}

@media screen and (max-width: 600px) {
  .featuredTop {
    display: grid;
    grid-template-columns: 1fr;
  }
  .top {
    padding: 40px 30px;
  }
  .solveProblem {
    width: 100%;
    order: 2;
  }

  .subMenu {
    flex: 1;
    order: 1 !important;
  }
  .catalogue,
  .self,
  .notes,
  .bookmark {
    flex: 1;
    cursor: pointer;
  }

  .subMenu > .row {
    gap: 15px;
    margin: 15px 0;
  }

  .discoverPage {
    width: 100% !important;
  }
  .mainContent {
    padding: 2.3em 30px;
  }
  .bookSampleHeader {
    font-size: 1.2em;
    /* font-weight: 800; */
    padding-top: 0;
  }
}

@media screen and (max-width: 600px) {
  .bookSampleHeader {
    font-size: 1.5em;
    font-weight: 800;
    font-family: "DM Sans";
  }
  .bookSampleHeader > span {
    display: none;
  }
}

@media screen and (min-width: 601px) and (max-width: 1150px) {
  .top {
    padding: 40px 40px 100px 40px;
  }
  .catalogue,
  .self,
  .notes,
  .bookmark {
    flex: 1;
    cursor: pointer;
    display: flex !important;
    flex-direction: row !important;
    gap: 20px;
    align-items: center !important;
    height: auto !important;
    padding: 10px !important;
  }

  .subMenu > .row {
    gap: 15px;
    margin-bottom: 15px;
    display: grid;
    height: auto;
  }
  .featuredTop .solveProblem {
    flex: 0.6;
  }

  .featuredTop .subMenu {
    flex: 0.4;
  }

  .bookSampleHeader {
    font-size: 1.2em;
    padding: 0;
  }
}

@media screen and (min-width: 969px) and (max-width: 1150px) {
  .sectionContent {
    width: 100%;
  }
  .catalogue,
  .self,
  .notes,
  .bookmark {
    flex: 1;
    cursor: pointer;
    display: flex !important;
    flex-direction: row !important;
    gap: 20px;
    align-items: center !important;
    height: auto !important;
    padding: 10px !important;
  }
}
