.compWrapper {
  display: flex;
  min-height: 90vh;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}
.leftWrapper {
  display: flex;
  flex-direction: column;
  padding: 4em 2.5em 4em 3em;
  /* max-width: 450px; */
  /* width: 100%; */
  color: #ffffff;
  background: linear-gradient(
      rgba(57, 57, 57, 0.92),
      rgba(57, 57, 57, 0.92),
      rgba(57, 57, 57, 0.92)
    ),
    url(../../../assets/jpeg/pexels-liza-summer-6347901.jpg) no-repeat;
  background-size: contain;
}
.rightWrapper {
  flex: 1;
  padding: 3em 7em;
  background-color: #ffffff;
  color: #505050;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.aside {
  flex: 1;
}
.iframeMap {
  /* filter: grayscale(50%); */
}
.compTitle {
  font-size: 1.8em;
  font-weight: 600;
  padding-bottom: 40px;
}
.contactInfoBody {
  margin-top: 3em;
}
.contactText {
  font-size: 15px;
  font-weight: 400;
  margin-bottom: 30px;
}
.infoTitle {
  font-size: 35px;
  font-weight: 400;
  color: #fef7f7;
}
.lineDivider {
  border-top: 1px solid #ede3e3;
  width: 100%;
}
.formInputRow {
  display: flex;
  align-items: center;
  gap: 10px;
}
.textAreaWrapper {
  /* padding-right: 20px; */
  margin-top: 20px;
  margin-bottom: 3em;
}
.textInputWrapper {
  width: 100%;
}
.inputLabel {
  font-size: 15px;
  font-weight: 700;
  margin-bottom: 8px;
  margin-left: 5px;
}
.sendMessageBtn {
  max-width: 170px;
  background-color: transparent;
  color: #222;
  border: 2px solid #222;
  border-radius: 0;
  margin-top: -1em;
}
.inputField {
  height: 50px;
  border-radius: 3px;
}

/* -----------------------------------------------------------------------------
                          MEDIA QUARY
----------------------------------------------------------------------------- */

@media (max-width: 970px) {
  .compWrapper {
    flex-direction: column;
  }
  .leftWrapper {
    max-width: 100%;
  }
}
@media (max-width: 680px) {
  .leftWrapper {
    padding: 3em 1em;
  }
  .rightWrapper {
    padding: 5em 1em;
  }
}
