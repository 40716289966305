@import url('https://fonts.googleapis.com/css2?family=Kanit&display=swap');

.pageRoot{
  padding: 10px 2em;
  display: flex;
  flex-direction: column;
  height: 100%;
}

.backButton{
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 10px;
  font-size: 1.1em;
  font-weight: 500;
  border: none;
  background-color: transparent;
  font-family: 'Kanit', sans-serif;
  margin-top: 2em;
}

.pageBody{
  flex: 1px;
  padding: 20px 0;
}
.booksWrapper{
  display: grid;
  grid-template-columns: repeat(4, minmax(0, 1fr));
  grid-gap: 20px;
}
.skeletonLogLoader{
  height: 250px;
}
.emptyHistory{
  min-height: 50vh;
  display: flex;
  align-items: center;
  justify-content: center;
}
.emptyHistory h3{
  font-size: 1.4em;
  font-weight: 600;
  opacity: .7;
}
.subjectsWrapper{
  display: flex;
  gap: 15px;
  flex-wrap: wrap;
  margin: 2em 0;
}
.subjectskeletonLoader{
  width: 100px;
}
.subjectButton{
  padding: 5px 15px;
  border-radius: 5px;
  font-size: 1em;
  font-weight: 500;
}
.selectedSubject{
  background-color: #1d1d1d;
  color: #fff;
  border: none;
}
@media screen and (max-width: 968px) {
.booksWrapper{
  grid-template-columns: repeat(3, minmax(0, 1fr));
}
}
@media screen and (max-width: 650px) {
.booksWrapper{
  grid-template-columns: repeat(2, minmax(0, 1fr));
}
.pageTop{
  flex-direction: column;
}
.pageTop > *{
  width: 100%;
}
}
@media screen and (max-width: 450px) {
.booksWrapper{
  grid-template-columns: repeat(1, minmax(0, 1fr));
}
}