.mainBody{
    display: grid;
  grid-template-columns: repeat(2, minmax(0, 1fr));
  grid-gap: 20px;
}

.skeletonLogLoader{
  height: 200px;
}

@media screen and (max-width: 968px) {
.mainBody{
  grid-template-columns: repeat(1, minmax(0, 1fr));
}

}