.root {
  padding: 20px;
}

.customTable {
  width: 100%;
}
.label {
  font-size: 0.85em;
  color: #444;
}
td.label,
div.label {
  width: 150px;
}
td .up,
div .up,
td .down,
div .down,
td .side,
div .side {
  padding: 15px;
  margin: 4px;
  text-align: center;
  border-radius: 5px;
  font-weight: 600;
  box-shadow: rgba(0, 0, 0, 0.18) 0px 2px 4px;
}
td .up,
div .up {
  background-color: #f1fdf1;
  color: #4a8944;
}

td .down,
div .down {
  background-color: #fff7f1;
  color: #a0592a;
}
td .side,
div .side {
  background-color: #ebf1ff;
  color: #43537d;
}
.pagination {
  padding: 20px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 5px;
}

.pagination button {
  border-radius: 3px;
  width: 25px;
  height: 25px;
  background-color: transparent;
  border: solid 1px #444362;
  font-size: 0.9em;
  color: #444362;
}
.pagination button:hover,
button.active {
  background-color: #444362;
  color: #fff;
  font-weight: bold;
}
.difficultyLevel {
  text-transform: capitalize;
  font-size: 9px;
}
.mobileOnly {
  display: none;
}
.desktopOnly {
  display: block;
}
/* Media Queries */
@media (max-width: 991.98px) {
  .mobileOnly {
    display: block;
  }
  .desktopOnly {
    display: none;
  }
  .sub_heading {
    font-size: 0.9em;
    font-weight: 500;
    color: #000;
  }
  .score {
    display: flex;
    flex-wrap: wrap;
  }
  div.label {
    width: 100%;
    padding-left: 0.5em;
  }
  div .up,
  div .down,
  div .side {
    padding: 10px 30px;
  }
}

@media (min-width: 768px) and (max-width: 991.98px) {
}

@media (min-width: 992px) and (max-width: 1199.98px) {
}

@media (min-width: 1200px) and (max-width: 1400px) {
}
