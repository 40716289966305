.row {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 15px;
}

.accordion_container{
    margin: 10px 0px;
    background: #ffffff;
    padding: 20px;
    border-radius: 10px;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
}

.accordion_title{
    font-weight: bolder;
    font-size: 15px;
    margin: 0;
    flex: 0.95;
}

.accordion_header{
    cursor : pointer
}

.accordion_content{
    text-align: justify;
    overflow: hidden;
    max-height: 0px;
    transition: max-height 0.3s ease-in;
    overflow-y: auto;
}

.display_content{
    max-height: 230px;
    transition: max-height 0.3s ease-in;
}

.accordion_text{
    margin-top: 10px;
}

.tooltip{
    margin: 0 5px;
}

.active{
    color: blueviolet;
}

.content {
    flex: 1;
    padding: 20px 30px;
  }
  .contentHeader {
    margin-bottom: 20px;
  }
  .initials {
    border-radius: 100px;
    background-color: blueviolet;
    width: 50px;
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 600;
    color: #fff;
  }
  .mtN20 {
    margin-top: -20px;
  }
  .mtN10 {
    margin-top: -10px;
  }
  .mtN20,
  .mtN10 {
    color: #666;
  }
  .title {
    font-weight: 600;
    font-size: 1.1em;
    margin-top: 20px;
  }
  h4 .title {
    font-size: 1.5em;
  }
  .menu .title {
    color: #888;
    margin-bottom: 30px;
  }
  .content pre {
    white-space: pre-wrap;
    word-wrap: break-word;
    font-family: Inter;
    padding: 20px;
    font-size: 1.1em;
    color: #000;
    background-color: rgba(215, 234, 250, 0.259);
  }
  
  .learningObj {
    color: #000;
    background-color: rgba(250, 234, 215, 0.279);
  }

  .row_beside{
    display: flex;
    gap: 15px;
  }

  .row_resources{
    display: flex;
    gap: 15px;
    flex-wrap: wrap;
  }