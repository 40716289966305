.paragraphDiv {
  max-width: 100%;
  max-height: 300px;
  border: solid 1px #999;
  /* background-color: rgb(248, 250, 255); */
  font-size: 1.1em;
  padding: 15px;
  margin-bottom: 20px;
  padding-bottom: 20px;
}
.paragraph {
  max-width: 100%;
  max-height: 220px;
  overflow-y: auto;
  /* padding: 5px 15px 5px 5px; */
  padding-right: 10px;
}
.question {
  font-weight: 600;
  font-size: 1.1em;
  max-height: 130px;
  overflow-y: auto;
  color: #444;
}
.question h5 {
  font-weight: 600;
  color: rgb(22, 19, 70);
}
.question p {
  color: #333;
}
