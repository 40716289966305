.root {
  padding: 20px 40px;
}
.header {
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
}
.right {
  text-align: right;
  display: flex;
  align-items: center;
  gap: 20px;
}
.right i {
  font-size: 2em;
  cursor: pointer;
}
.right i:hover {
  transform: scale(1.15);
}
.searchIcon {
  color: #777;
  font-size: 1.3em;
}
.title {
  font-weight: bold;
  font-size: 1.1em;
  margin-bottom: 40px;
}
.formGroup {
  margin-bottom: 15px;
}
a,
a:link,
a:hover {
  color: #222;
}

.bookWrapper {
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
  padding: 20px 40px;
  margin-top: 20px;
}
.scrollWrapper {
  height: 400px;
  padding: 10px 40px;
  overflow: auto;
}
.bookCover {
  width: 40px;
  border-radius: 5px;
  margin-top: 10px;
}

table.bookList {
  width: 100%;
  /* margin-top: 20px; */
}

.bookList thead {
  height: 60px;
}
.actions i {
  font-size: 1.3em;
  cursor: pointer;
}

.actions {
  display: flex;
  gap: 20px;
  padding-top: 10px;
}

.actions i:hover {
  color: rgb(178, 89, 5);
}

.btn {
  height: 39px;
  margin-top: 24px;
  width: 30%;
  font-size: 15px;
  border: none;
  background-color: rgb(39, 72, 138);
  color: #fff;
  cursor: pointer;
  align-items: center;
  display: flex;
  justify-content: center;
  gap: 5px;
  text-transform: uppercase;
}

.btn svg {
  width: 15px;
  height: 15px;
}

.previewSize {
  width: 40%;
  top: 5%;
  left: 20%;
  height: auto !important;
}
