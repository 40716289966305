.root {
  padding: 20px;
  overflow: auto;
  height: 100%;
}
.row {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
}
/* .searchGroup {
  margin: 2em 10em;
  gap: 0;
  border: solid 1px #ccc;
}
.search {
  flex: 1;
}
.searchActions {
  width: 60px;
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.5em;
  cursor: pointer;
  background-color: #7482d8;
}
.searchActions:hover {
  transform: scale(1.1);
  background-color: #775dd0;
} */

/* Actions */
.searchActions {
  justify-content: flex-end;
  align-items: center;
  color: #999;
  padding: 10px 0;
}
.searchContainer {
  position: relative;
}
input.search {
  padding: 5px 8px;
  font-size: 0.85rem;
  border: solid 1px #eee;
  color: #888;
  margin-right: 5px;
  min-width: 200px;
}
.search:focus {
  outline: none;
}

.searchBtn {
  position: absolute;
  border: 1px solid #eee;
  background-color: #f9f9f9;
  color: #000;
  right: 10px;
  top: 4px;
  padding: 4px;
  font-size: 0.6em;
}
.search:focus.searchBtn {
  right: 50px;
}

/* Question Card */
.questionCard {
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
  padding: 10px 20px 20px 20px;
  margin: 10px;
  width: calc(90% / 3);
  font-size: 14px;
}

.skeletonLogLoader {
  height: 175px;
  width: 180px;
}

.actions {
  display: flex;
  justify-content: flex-end;
}
.actions span {
  padding-left: 5px;
}
.actions span:hover {
  transform: scale(0.9);
}
.tooltip {
  font-size: 1.2em;
  text-transform: uppercase;
  letter-spacing: 0.09em;
}
.topic {
  font-size: 1.1em;
}
.topic,
.question {
  hyphens: auto;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 4; /* number of lines to show */
  line-clamp: 4;
  -webkit-box-orient: vertical;
  margin: 10px 0 15px 0;
}
.paragraph {
  border-left: solid 4px #969dc8;
  padding-left: 5px;
}
.image {
  display: flex;
  justify-content: center;
}
.image img {
  width: 100%;
  height: 180px;
  object-fit: cover;
}
.number {
  margin-top: -10px;
  color: #888;
}
