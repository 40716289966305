.root {
  position: relative;
}
.colorPicker {
  width: 30px;
  height: 30px;
  border-radius: 100px;
  cursor: pointer;
}
.colorPickerContainer {
  display: flex;
  flex-direction: column;
  gap: 10px;
  position: absolute;
  left: 40px;
  top: -10px;
  background-color: rgba(37, 37, 38, 0.727);
  border-radius: 8px;
  padding: 10px;
}
