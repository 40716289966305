.root {
  background-color: #fff;
  display: flex;
  justify-content: center;
}

.card {
  position: absolute;
  background-color: rgba(255, 255, 255, 0.974);
  border-radius: 12px;
  padding: 30px 25px;
  margin: 10px;
  max-width: 600px;
  width: 100%;
  height: 250px;
  box-shadow: rgba(0, 0, 0, 0.25) 0px 14px 28px,
    rgba(0, 0, 0, 0.22) 0px 10px 10px;
}

.pack {
  position: relative;
  width: 500px;
  height: 400px;
}
.div1 {
  right: 0;
  top: 0;
  z-index: 3;
}
.div2 {
  right: 80px;
  top: 40px;
  z-index: 2;
  background-color: rgba(5, 24, 45, 0.974);
}
.div3 {
  right: 160px;
  top: 80px;
  z-index: 1;
  background-color: rgba(248, 162, 3, 0.974);
}
.bottom {
  position: absolute;
  right: -10px;
  bottom: 0px;
  z-index: 4;
  border: 1px solid #999;
  padding: 8px 20px;
}

.bottom button {
  background-color: transparent;
  border: none;
  outline: none;
  color: #18222fd0;
  font-weight: 600;
  font-size: 0.9em;
  text-transform: uppercase;
  display: flex;
  gap: 10px;
  align-items: center;
}

.cardContainer {
  display: flex;
  height: 100%;
}
.cardBody {
  flex: 1
}
.cardImg {
  margin-right: 20px;
}
.cardImg img {
  object-fit: cover;
  object-position: top;
  width: 200px;
  height: 180px;
  border-radius: 8px;
  padding: 2px;
  border: 1px solid #ccc;
  box-shadow: rgba(0, 0, 0, 0.25) 0px 25px 50px -12px;
}
.div3 .cardImg img {
  border-color: #f4e7cd;
}
.cardTitle {
  font-size: 1.2em;
  font-weight: 800;
  padding: 20px 0 10px 0;
  color: #5761a4;
}
.div3 .cardTitle {
  color: #1a1101;
}
.div2 .cardTitle {
  color: #d5d6e2;
}
.div2 .cardDetails {
  color: #e4e6f7;
}
.cardDetails {
  height: 3em; /* Set the desired fixed height */
  overflow: hidden; /* Hide overflowing text */
  display: -webkit-box; /* Create a flexible box layout */
  -webkit-line-clamp: 2; /* Limit the text to 2 lines */
  -webkit-box-orient: vertical; /* Set vertical orientation */
  text-overflow: ellipsis; /* Display ellipsis for truncated text */
  font-weight: 100;
  font-size: 1em;
}
.resumeCourseBtn {
  display: inline-block;
  margin: 10px 0;
  border: 1px solid #222;
  color: #000;
  font-weight: 300;
  padding: 6px 15px;
  font-size: 0.9em;
  cursor: pointer;
}
.progressBar {
  background-color: #e1e1e1;
  height: 8px;
  padding: 2px;
  border-radius: 20px;
  width: 80%;
}
.div2 .resumeCourseBtn {
  border-color: #f7f7f7;
  color: #f9f9f9f9;
}

@media (max-width: 767.98px) {
.root {
  padding: 0px;
  margin-top: 40px;
}
.pack {
  width: 100%;
}
.cardImg img {
  width: 100px;
  height: 80px;
  min-width: 100px;
}
.card{
  padding: 10px;
  border-radius: 8px;
  margin: 0px;
  max-width: 500px;
  height: 160px;
}
.pack{
  height: 320px;
}
.div1{
  right: -10px;
}
.div2{
right: 25px;
top: 40px;
}
.div3{
  right: 35px;
  top: 80px;
}
.cardTitle {
  padding: 0px 0 5px 0;
}
.resumeCourseBtn{
  font-size: .8em;
  padding: 4px 15px;
}
.cardTitle{
  font-size: 1em;
  font-weight: 700;
    margin: 0px;
}
.cardDetails{
  font-size: .8em;
  margin: 0px;
}
}