.root {
  background-color: #000;
  color: rgba(234, 206, 161, 0.826);
  padding: 20px 2em;
  position: absolute;
  bottom: 0;
  flex: 1;
  width: 100%;
  font-size: 0.85em;
  font-family: "Inter";
  z-index: 1000;
}
.FooterMargin {
  padding-top: 3em;
}

@media screen and (max-width: 650px) {
  .root {
    position: relative;
  }
}
