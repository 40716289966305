.pageRootWrap{
    padding: 3em 7em;

}


/* Banner Styles */

.detailsBannerWrap{
  min-height: 250px;
  border-radius: 5px;
  padding: 2em;
  margin-bottom: 3.5em;
  background-color: #312dad ;
  background-image: radial-gradient(#7a78a4  .7px, transparent 1px); /* Create a repeating radial gradient for the dots */
  background-size: 6px 6px; /* Adjust the size of the dots */
  color: #fff;
}
.bannerTopWrapper{
  display: flex;
  align-items: center;
  gap: 10px;
}
.bannerDetailsItems{
  max-width: 55%;
}
.courseTypeText{
  padding: 8px 20px;
  border: none;
  border-radius: 20px;
  background-color: #100f0f;
  color: #fff;
  font-size: .8em;
  font-weight: 600;
  display: flex;
  align-items: center;
  gap: 5px;
}
.bannerTopText{
  font-size: 1em;
  font-weight: 600;
}
.courseTitleBannerText{
  font-size: 2em;
  font-weight: 600;
  margin-top: 15px;
}
.courseDetailsBannerText{
  font-size: 1em;
  font-weight: 500;
}
.courseRatingWraper{
  display: flex;
  align-items: center;
  gap: 5px;
  margin-top: 1.5em;
}
.ratingsValue{
  margin: 0;
  font-weight: 600;
}
.ratingsCount{
  font-size: .8em;
  font-weight: 500;
  text-decoration: underline;
}
.courseStudyInfo{
  border: 1px solid #000;
  min-height: 100px;
  display: flex;
  padding: 10px 15px;
  color: #000;
}
.courseStudyInfo > *{
  flex: 1px;
  gap: 15px;
  display: flex;
  align-items: center;
  gap: 15px;
}
.infoTextBody{
  font-size: 1.4em;
  font-weight: 600;
}
.infoIcon{
  font-size: 1.6em;
}

.pageBody{}



.sylabusWrapper{
  margin-top: 2em;
  border: 1px solid #000;
}
.backIcon{
  font-size: 1.5em;
}