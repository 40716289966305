.modal {
  position: absolute;
  top: 5%;
  left: 30%;
  width: 25rem;
  height: 450px;
  padding: 30px;
  background-color: rgb(68, 31, 138);
  color: aliceblue;
  border-radius: 15px;
  box-shadow: rgba(6, 24, 44, 0.4) 0px 0px 0px 2px,
    rgba(6, 24, 44, 0.65) 0px 4px 6px -1px,
    rgba(255, 255, 255, 0.08) 0px 1px 0px inset;
}

.title {
  font-size: 0.8em;
}
.intro {
  font-size: 2.5em;
  font-weight: bold;
  position: relative;
  color: aliceblue;
}

.intro span {
  font-size: 0.4em;
  position: absolute;
  top: 5px;
}

ul.list {
  list-style: none;
  color: #ecc88c;
  font-size: 0.75em;
  height: 230px;
  overflow: auto;
  padding-left: 0;
}

ul.list li {
  padding: 10px 15px 5px 0;
  display: flex;
  justify-content: space-between;
  border-bottom: 1px dashed #bbb;
}
.good {
  color: green;
}
.bad {
  color: red;
}
.msg {
  font-size: 14px;
  text-align: center;
}
.msg span {
  color: rgb(249, 167, 167);
  padding-left: 5px;
}
.msg span:hover {
  text-decoration: underline;
  cursor: pointer;
}

/* Media Queries */
@media (max-width: 767.98px) {
  .modal {
    top: 0;
    left: 0;
    width: 96%;
    height: 500px;
    padding: 1.5em 1em;
    margin: 10px;
  }

  ul.list {
    font-size: 0.8em;
    height: 300px;
    padding-left: 10px;
  }

  ul.list li {
    display: flex;
    justify-content: space-between;
    border-bottom: 1px dashed #bbb;
  }
}
