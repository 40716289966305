.compWrapper {
  display: flex;
  align-items: center;
  gap: 1em;
  padding: 1.5em 0;
}
.title {
  font-size: 18px;
  font-weight: 800;
  color: #bbd3fe;
}
.text {
  font-size: 15px;
  font-weight: 400;
  color: #eee;
}
