.button {
  width: 100%;
  height: 40px;
  border-radius: 5px;
  background-color: var(--color-primary);
  color: var(--color-white);
  border: none;
  font-weight: 500;
  font-size: 1em;
  cursor: pointer;
}
.btnWhite {
  background-color: #cad2fc;
  color: #465192;
}
