.compRoot{
  flex: 2px;
  padding: 2em 4%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #f4f5f9;
}
.mainBody{
  max-width: 450px;
  width: 100%;
  padding: 2em;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  background-color: #ffffff;
  border-radius: 10px;

}
.formTitle{
  font-size: 1.6em;
  font-weight: 200;
}
.formText{
  font-size: .85em;
  font-weight: 400;
  margin: 10px 0px;
}
.otpInputContainer{
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 10px 0px;
}
.actionBtnWrapper{
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: .5em;
  margin-bottom: 25px;
  gap: 15px;
}
.verifyBtn{
  max-width: 150px;
}
.backTologin{
  border: none;
  background-color: transparent;
  padding: 3px 0px;
  display: flex;
  gap: 6px;
  align-items: center;
}
.backTologin span{
font-size: .9em;
white-space: nowrap;
}
.arrowBack{
  font-size: 1.3em;
  font-weight: 200;
}
.infoText{
  font-size: .85em;
  font-weight: 400;
}
.requestNewCode{
  color: #FD6920;
  cursor: pointer;
}
.inputRow {
  margin-top: 25px;
   width: 100%;
}
.inputField {
  border-radius: 3px;
}