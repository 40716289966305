@import url("https://fonts.googleapis.com/css2?family=Roboto&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Kreon&display=swap");
.cardRoot {
  border: 1px solid #ccc;
  border-radius: 5px;
  height: 280px;
  /* max-width: 410px; */
  width: 100%;
  padding: 3px;
  font-family: "Roboto", sans-serif;
  background-color: #fff;
  position: relative;
  perspective: 1000px;
}
.categoryDescription {
  font-family: "Quicksand", sans-serif;
  font-weight: 400;
  color: #111;
  line-height: 24px;
}
.lineDivider {
  border-bottom: 1px solid #ccc;
  margin: 10px 0;
}
.subCategoryRow {
  display: flex;
  gap: 10px;
  align-items: center;
  /* justify-content: space-between; */
  margin: 5px 0;
  font-family: "Kreon", serif;
  font-weight: 400;
  cursor: pointer;
}
.subCategoryName {
  font-weight: 500;
  font-size: 1em;
  text-transform: capitalize;
  font-family: "Inter";
}
.viewBooksBtn {
  border: none;
  background-color: transparent;
  /* color: #00a1de; */
  font-size: 0.9em;
  font-weight: 600;
  display: flex;
  gap: 5px;
  align-items: center;
  font-family: "Kreon", serif;
}
.viewBooksBtn:hover {
  text-decoration: underline;
}
.frontCardBottom {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.frontFlipBtn {
  border: none;
  background-color: transparent;
  font-size: 0.75em;
  /* color: #00a1de; */
  font-weight: 600;
}
.card {
  /* position: absolute; */
  width: 100%;
  height: 100%;
  transform-style: preserve-3d;
  transition: transform 0.6s;
  border-radius: 5px;
}
.cardFront {
  padding: 20px;
}

.cardFront,
.cardBack {
  position: absolute;
  width: 100%;
  height: 100%;
  backface-visibility: hidden;
}
.card.flipped {
  transform: rotateY(180deg);
}

.cardBack {
  transform: rotateY(180deg);
  display: flex;
  flex-direction: column;
  color: #fff;
  padding: 30px 20px 20px 20px;
}
.cardBackItems {
  /* flex: 1px; */
  overflow-y: scroll;
  display: grid;
  grid-template-columns: repeat(2, minmax(0, 1fr));
  grid-gap: 15px;
}
/* .cardBackItems::-webkit-scrollbar {
  display: block;
    width: 5px;
  height: 10px;
  background-color: rgb(243, 237, 237);
  border-radius: 5px;
} */
.cardBackItems > * {
  font-size: 1em;
  font-weight: 400;
  font-family: "Roboto", sans-serif;
  text-align: center;
  /* color:  #00a1de; */
}
.subCategoryWrap {
  cursor: pointer;
  display: flex;
  gap: 10px;
  align-items: center;
}
.subCategoryWrap > * {
  font-family: "Inter";
  letter-spacing: 0.9px;
  text-transform: capitalize;
}
.backFlip {
  border: none;
  background-color: transparent;
  width: 100px;
  font-size: 1.4em;
  cursor: pointer;
  margin-bottom: -5px;
  color: #fff;
  display: flex;

  align-items: center;
  justify-content: flex-end;
}
.backFlipWrapper {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  position: absolute;
  bottom: 15px;
  right: 15px;
}
