:root {
  --color1: #da7638;
  --color2: #f2ac80;
  --color3: #242538;
  --color4: #e7e7e7e7;
  --color5: #c3c0c0;
  --color6: #fdf6f2;
}
.assessmentPage {
  display: flex;
  height: 100%;
}
.assessmentQuestions {
  flex: 1;
  padding: 20px;
  height: 95%;
  padding: 20px;
  overflow: hidden;
}
.assessmentOptions {
  background-color: var(--color3);
  color: var(--color4);
  flex: 1;
  border-radius: 0 15px 15px 0;
  margin: 0.05em;
  height: 99.6%;
  padding: 20px;
}
.optionView {
  margin-top: 45px;
  margin-bottom: 10px;
  height: 75%;
  overflow-y: auto;
  padding-right: 12px;
}
.footer {
  display: flex;
  justify-content: flex-end;
  gap: 10px;
}
.footer button {
  padding: 5px 20px;
  border: solid 2px var(--color5);
  background-color: transparent;
  color: white;
  text-transform: uppercase;
  font-size: 1.5em;
  display: flex;
  align-items: center;
}
.footer button:hover {
  color: var(--color2);
  border: solid 2px var(--color2);
}
button.submit {
  background-color: var(--color1);
  border: solid 2px var(--color1);
  color: var(--color6);
  font-size: 0.9em;
}

button:hover.submit {
  background-color: transparent;
  border: solid 2px var(--color2);
  color: var(--color2);
  font-size: 0.9em;
}
.bucket {
  font-size: 14px;
  margin-bottom: 20px;
  display: flex;
  justify-content: space-between;
}
.questionCount {
  text-align: right;
}
.label {
  font-size: 0.8em;
  color: #777;
  margin-top: -13px;
}
.optionHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.optionHeader span {
  margin-right: auto;
  cursor: pointer;
}

/* Option */
.option {
  display: flex;
  align-items: center;
  gap: 10px;
  font-size: 1em;
  /* font-weight: bold; */
  padding: 15px;
  border: 1px solid #ccc;
  margin-bottom: 10px;
  border-radius: 6px;
  cursor: pointer;
}
.option:hover,
.selected {
  background-color: #496ae1;
  color: #ffffff;
}
.optionLabel,
.optionLabel .selected {
  width: 40px;
  height: 40px;
  background-color: #d6dded;
  color: #496ae1;
  border-radius: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.selected .optionLabel {
  background-color: #fff;
}


.mobile_options{
  display: none;
  margin-bottom: 10px;
}

@media screen and (max-width : 700px) {
  .assessmentPage {
    display: block;
    height: 100vh;
  }
  .assessmentQuestions{
    height: 40vh;
    padding: 20px;
    
  }

  .question_container{
    overflow-y: scroll;
    height: 160px;
  }
  .assessmentOptions{
    height:60vh;
    border-radius: 0;
  }
  
  .optionView {
    margin-top: 30px;
    height: 70%;
    margin-bottom: 0px;
    overflow-y: auto;
    padding-right: 12px;
  }

  .optionHeader{
    display: none;
  }
  .mobile_options{
    display: flex!important;
    margin-bottom: 10px;
  }
}