.tag,
.button {
  border-radius: 4px;
  padding: 5px 10px 5px 3px;
  margin: 5px;
  font-size: 13px;
  cursor: pointer;
  display: flex;
  align-items: center;
  white-space: nowrap;
}
.button,
.clear {
  background-color: #111530;
  color: #fff;
  gap: 5px;
  padding-left: 10px;
}
.icon {
  font-size: 18px;
}
.clear {
  background-color: #fff;
  color: #000;
  box-shadow: rgba(0, 0, 0, 0.02) 0px 1px 3px 0px,
    rgba(27, 31, 35, 0.15) 0px 0px 0px 1px;
}
/* Style for letter A */
.a {
  background-color: #ff5733;
  color: #ffffff;
}

/* Style for letter B */
.b {
  background-color: #f26666;
  color: #ffffff;
}

/* Style for letter C */
.c {
  background-color: #ff8c42;
  color: #ffffff;
}

/* Style for letter D */
.d {
  background-color: #ffb84d;
  color: #000000;
}

/* Style for letter E */
.e {
  background-color: #ffca5a;
  color: #000000;
}

/* Style for letter F */
.f {
  background-color: #ffee93;
  color: #000000;
}

/* Style for letter G */
.g {
  background-color: #9ceaef;
  color: #000000;
}

/* Style for letter H */
.h {
  background-color: #42d9c8;
  color: #000000;
}

/* Style for letter I */
.i {
  background-color: #29b5b5;
  color: #ffffff;
}

/* Style for letter J */
.j {
  background-color: #84a59d;
  color: #000000;
}

/* Style for letter K */
.k {
  background-color: #b5838d;
  color: #000000;
}

/* Style for letter L */
.l {
  background-color: #a17c7a;
  color: #ffffff;
}

/* Style for letter M */
.m {
  background-color: #dc758f;
  color: #ffffff;
}

/* Style for letter N */
.n {
  background-color: #ff6b6b;
  color: #000000;
}

/* Style for letter O */
.o {
  background-color: #ff9a8b;
  color: #000000;
}

/* Style for letter P */
.p {
  background-color: #ffc3a0;
  color: #000000;
}

/* Style for letter Q */
.q {
  background-color: #ffe6a7;
  color: #000000;
}

/* Style for letter R */
.r,
.success {
  background-color: #8ed081;
  color: #000000;
}
.success {
  padding: 5px 10px;
  margin-left: 0;
}

/* Style for letter S */
.s {
  background-color: #3f7a89;
  color: #ffffff;
}

/* Style for letter T */
.t {
  background-color: #314e52;
  color: #ffffff;
}

/* Style for letter U */
.u {
  background-color: #8a9b0f;
  color: #000000;
}

/* Style for letter V */
.v {
  background-color: #6aab9c;
  color: #000000;
}

/* Style for letter W */
.w {
  background-color: #87bcde;
  color: #000000;
}

/* Style for letter X */
.x {
  background-color: #fc9d9a;
  color: #000000;
}

/* Style for letter Y */
.y {
  background-color: #f9cdad;
  color: #000000;
}

/* Style for letter Z */
.z {
  background-color: #f96e46;
  color: #ffffff;
}
