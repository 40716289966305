@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;500&display=swap');

.pageRoot{
  display: flex;
  flex-direction: column;
  height: 100%;
}
.pageTitle{
  font-size: 1.2em;
  font-weight: 500;
  padding: 10px 2em;
  font-family: 'Roboto', sans-serif;
}
.titleInputField{
  width: 100%;
  text-indent: 10px;
  padding: 7px 0;
  border: none;
  border-bottom: 1px solid #707070;
  background-color: transparent;
}
.inputRow{
  display: flex;
  gap: 20px;
}
.inputCulumn{
  flex: 1px;
}
.praticeInfo{
  margin-bottom: 3em;
}
.praticeInfo > *{
  margin-bottom: 15px;
}
.pageContent{
  width: 100%;
  flex: 1px;
  padding: 10px 2em;
  overflow-y: scroll;
}
.questionWrapper{
  display: flex;
  flex-direction: column;
  gap: 2em;
}
.addCardBtn{
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px;
  border-radius: 6px;
  background-color: #fff;
  margin-bottom: 20px;
  margin-top: -10px;
  cursor: pointer;
}
.addCardItem{
  display: flex;
  gap: 10px;
  align-items: center;
  font-size: 1em;
  font-weight: 600;
}
.inputLabel{
  font-size: .9em;
  font-weight: 600;
  margin: 10px 0;
}
.dropdown{
  background-color: transparent;
  border-color: #707070;
  border-radius: 2px;
}
.pageFooter{
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 1em 3em;
}
.saveButton{
  width: 200px;
  background-color: transparent;
  border: 1px solid #000000;
  color: #000000;
  border-radius: 2px;
  font-weight: 600;

}
.questionText{
  display: flex;
  align-items: center;
  gap: 6px;
}