.profileCard {
  width: 250px;
  border: 1px solid var(--border-color2);
  z-index: 10;
  border-radius: 5px;
  background: linear-gradient(to bottom, #fff, #d5d7ef);
  box-shadow: rgba(0, 0, 0, 0.15) 0px 5px 15px 0px;
  padding-bottom: 20px;
  position: absolute;
  right: 0;
  display: none;
  transition: all 0.3s ease;
}
.userInfoWrapper {
  display: flex;
  align-items: center;
  gap: 7px;
  cursor: pointer;
  padding: 8px 20px;
  border-radius: 5px;
}
.userWelcome {
  font-size: 1em;
  font-weight: 500;
}
.userWelcome b,
.name {
  text-transform: capitalize;
}
.name span {
  text-transform: uppercase;
}
.name,
.email {
  width: 150px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  text-align: center;
}
.email {
  width: 200px;
}
.onOpen {
  display: block;
  transition: all 0.3s ease;
}
.dropDownWrapper {
  position: relative;
}
.profileContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 30px 30px 0 30px;
  color: #2d2b76;
  font-weight: bold;
  font-size: 1.5em;
  margin: 0;
  font-family: "Acme";
}
.bio {
  display: flex;
  align-items: center;
}

.studentId {
  color: #000;
  font-size: 0.8rem;
  font-family: "Quicksand";
  font-weight: normal;
}
.userImg {
  width: 34px;
  height: 34px;
  font-size: 1em;
}
.userCard {
  width: 68px;
  font-size: 2em;
  height: 68px;
  margin-right: 10px;
}
.profileWrapper {
  padding: 1em 4%;
  display: flex;
  gap: 10px;
}
.profilePage {
  text-decoration: none;
  padding: 5px 15px;
  border-radius: 30px;
  color: #555;
  border: 1px solid #afb6fd;
  font-size: 0.8em;
}

.profilePage:hover {
  background-color: #c6cafa43;
  color: #555;
  box-shadow: 1px 1px 1px #d5d9fc;
}
.profileIcon {
  font-size: 9px;
  position: relative;
  right: -5px;
  top: -1px;
  padding: 3px 4px;
  color: #fff;
  background-color: #363fa1;
  border-radius: 100px;
}
.userDetails {
  padding-top: 1rem;
  line-height: 8px;
  color: var(--color-neutral-black);
}
.userDetails h3 {
  font-size: 16px;
  font-weight: 600;
}

.profileContent {
  font-size: 14px;
  font-weight: 600;
  cursor: pointer;
  padding: 0px 40px;
  line-height: 14px;
  color: var(--color-neutral-black);
  text-decoration: none;
  display: flex;
  flex-direction: column;
}
.navPath {
  padding: 10px 0px;
  text-decoration: none;
  color: var(--color-neutral-black);
  font-weight: 600;
}
a:hover.navPath {
  color: #a55f2c;
}
.activeLink span {
  color: #ff6900;
}
.logoutContainer:hover {
  transform: scale(0.98);
}
.logoutContainer {
  text-align: center;
  border: none;
  background-color: #880e4f;
  color: #fff;
  padding: 15px;
  font-size: 1.1em;
  font-weight: 600;
  border-radius: 6px;
}
.navIcon {
  font-size: 1.5em;
}
@media screen and (max-width: 960px) {
  /* .profileCard {
    width: 185px;
  }
  .userDetails {
    line-height: 15px;
  }
  .userImage {
    width: 50px;
    height: 50px;
  }

  .userDetails h3 {
    font-size: 14px;
  }
  .userDetails p {
    font-size: 14px;
  }
  .profileWrapper {
    height: 70px;
  } */
}

/* Media Queries */
@media (max-width: 767.98px) {
  .userWelcome {
    display: none;
  }
}

@media (min-width: 768px) and (max-width: 991.98px) {
}

@media (min-width: 992px) and (max-width: 1199.98px) {
}

@media (min-width: 1200px) and (max-width: 1400px) {
}
