.pageWrapper {
  padding: 2em 6em 2em 4em;
  display: flex;
  height: 100%;
  flex-direction: column;
  background-color: #fff;
  position: relative;
}
.header {
  font-size: 1em;
  font-weight: 500;
  margin-bottom: 2em;
  font-family: "Oswald, sans-serif";
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.header h6 {
  cursor: pointer;
  color: #29366c;
  margin-right: auto;
}
.header h6:hover {
  color: #29366c;
}
.addBtn {
  position: absolute;
  top: 20px;
  right: 6em;
  width: 35px;
  height: 35px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #000;
  color: #fff;
  font-size: 24px;
  border-radius: 100px;
  border: 3px solid #bbb;
  cursor: pointer;
}
.addBtn:hover {
  background-color: #444;
  border-color: #959494;
}
.notes {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  margin-top: 3em;
}
.loaderWrapper {
  display: flex;
  gap: 20px;
}
.skeletonLogLoader {
  height: 175px;
  width: 180px;
}

/* Media Queries */
@media (max-width: 767.98px) {
  .pageWrapper {
    padding: 2em 1em;
  }
  .header h6 {
    display: flex;
    align-items: center;
    gap: 5px;
    font-size: 0.9em;
  }
  .addBtn {
    right: 1em;
  }
  .loaderWrapper {
    display: flex;
    flex-direction: column;
    gap: 10px;
  }
  .skeletonLogLoader {
    height: 175px;
    width: 100%;
  }
  .notes {
    flex-direction: column;
    gap: 20px;
    margin: 0;
    padding-bottom: 3em;
  }
}
