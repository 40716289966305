.root {
  height: 100vh;
  padding: 20px 40px;
  overflow: auto;
}

.back {
  background-color: transparent;
  border: none;
  outline: 0;
  text-align: left;
}
.header {
  margin-bottom: 20px;
}
.header h2 {
  font-size: 2.5em;
  font-weight: 700;
  font-family: "DM Sans";
}
.header p {
  margin-top: -5px;
}

ul.pages {
  list-style: none;
  display: flex;
  gap: 10px;
  padding: 0;
}
li.page {
  border: solid 1px #6155d7;
  border-radius: 100px;
  padding: 6px 15px;
  cursor: pointer;
}
li.page:hover,
.active {
  background-color: #6155d7;
  color: #fff;
}
.root form {
  width: 90%;
  display: grid;
  grid-template-columns: 1fr;
}
.formGroup {
  background-color: #fff;
  grid-column: 1;
  grid-row: 1;
}
.formGroup > div {
  margin-bottom: 15px;
}
div label {
  font-size: 0.9em;
  color: #555;
}

.termCondition {
  display: flex;
  align-items: center;
  gap: 10px;
}
.termCondition label span {
  color: #6155d7;
  padding-left: 5px;
}
.hide {
  display: none;
  transition: 2s ease-in-out;
}
.formBtn {
  display: flex;
  gap: 5px;
  justify-content: flex-end;
  margin-top: 20px;
}
.formBtn > button {
  padding: 5px 15px;
  border: 1px solid #000;
  background-color: #fff;
  font-size: 0.9em;
}
.submitBtn {
  font-weight: 600;
  font-size: 1rem;
  max-width: 200px;
  background-color: #6155d7 !important;
  color: #fff;
  border: none !important;
  border-radius: 0;
}
.mobile {
  display: none;
}
button.nextBtn,
button.previousBtn {
  padding: auto 30px;
  display: flex;
  gap: 10px;
  align-items: center;
}
/* Media Queries  */
@media (max-width: 767.98px) {
  .root {
    padding: 1.5em;
  }
  .root form {
    width: 100%;
    /* display: grid;
    grid-template-columns: 1fr; */
  }
  .mobile {
    display: block;
  }
}

@media (min-width: 768px) and (max-width: 959.98px) {
  .root {
    padding: 2em 10em;
  }
  .root form {
    width: 100%;
    /* display: grid;
    grid-template-columns: 1fr; */
  }
  .mobile {
    display: block;
  }
}

@media (min-width: 959.99px) and (max-width: 1199.98px) {
  .root form {
    width: 100%;
    /* display: grid;
    grid-template-columns: 1fr; */
  }
}

@media (min-width: 1200px) and (max-width: 1400px) {
}
