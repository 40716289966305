.editUserWrapper{
  padding: 15px;
}
.inputField {
  border-radius: 4px;
  border: 1px solid var(--color-neutral-black);
  width: 100%;
  text-indent: 4px;
  padding: 4px;
  margin-bottom: 1rem;
  outline: none;
}
.header{
  line-height: 5px;
}
.editBio{
  font-size: 1.5rem;
  font-weight: 800;
}
.inputWrapper{
  margin: 15px 0px;
}
.language {
  padding: 4px 10px;
  width: 100%;
  outline: none;
  border: 1px solid black;
  border-radius: 3px;
  margin-bottom: 2rem;
}
.indicate{
  padding-bottom: 0.6rem;
  font-size: .8em;
  font-weight: 600;
}
.save:hover {
  transform: scale(0.98);
  transition: all .3s ease;
}
