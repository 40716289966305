/* Question Card */
.row {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
}
.questionCard {
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
  padding: 10px 20px 20px 20px;
  margin: 10px;
  width: calc(90% / 3);
  font-size: 14px;
}

.actions {
  display: flex;
  justify-content: flex-end;
}
.actions span {
  padding-left: 5px;
}
.actions span:hover {
  transform: scale(0.9);
}
.tooltip {
  font-size: 1.2em;
  text-transform: uppercase;
  letter-spacing: 0.09em;
}
.topic {
  font-size: 1.1em;
  font-weight: bolder;
  margin-top: 10px;
}
.topic,
.question {
  hyphens: auto;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 4; /* number of lines to show */
  line-clamp: 4;
  -webkit-box-orient: vertical;
  margin: 10px 0 15px 0;
}
.question {
  border-left: solid 4px #969dc8;
  padding-left: 5px;
}

.sub_category {
  background: red;
  width: 50px;
  padding: 5px;
  border-radius: 7px;
  color: #ffffff;
  font-weight: bold;
  font-size: 8px;
}
.bucketcard_header_container {
  gap: 0;
  align-items: center;
  justify-content: space-between;
}

.bucket_info {
  align-items: center;
  justify-content: space-between;
}

.bucket_content {
  align-items: center;
  gap: 3px;
}
.skeletonLogLoader {
  height: 175px;
  width: 180px;
}
