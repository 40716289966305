.title {
  font-weight: 600;
}
.borrow_details,
.subtitle {
  color: #555;
}
.root_borrow_details {
  margin: 2em 4em;
}
.borrow_details {
  text-align: center;
}
.borrow_details p {
  margin-bottom: 5px;
  font-size: 0.85em;
  font-style: italic;
}
.subtitle {
  font-size: 0.9em;
  margin-top: -5px;
}
/* Media Queries */
@media (max-width: 767.98px) {
  .book__left {
    margin-top: 20px;
    height: 250px;
    position: relative;
    margin-bottom: 10px;
  }
  .back__btn {
    position: absolute;
    top: 15px;
    left: 15px;
    width: 50px;
    height: 30px;
    cursor: pointer;
    color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgba(11, 11, 11, 0.679);
    border: solid 1px #a4a4a4;
  }

  .book__right__top {
    display: flex;
    gap: 20px;
    justify-content: right;
    align-items: center;
  }
  .book__right__content {
    margin-top: 2em;
  }

  .book__right__bottom {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-top: 1em;
  }

  .rating {
    display: flex;
    align-items: center;
    gap: 10px;
  }
  .rating span {
    font-size: 0.9em;
    color: #555;
    display: block;
    margin-top: 4px;
  }
  .book_price {
    font-size: 1.4em;
    font-weight: 700;
    margin: 10px 0;
  }

  .right__btn,
  .right__btn_danger {
    margin-top: 10px;
    margin-right: 10px;
    padding: 5px 20px;
    border: 1.5px solid black;
    background: transparent;
    font-size: 14px;
    text-transform: uppercase;
  }

  .right__btn:hover {
    background: black;
    color: white;
  }
}

@media (min-width: 768px) {
  .book__container {
    display: flex;
    margin: 40px auto;
    padding: 15px;
    border-radius: 10px;
    position: relative;
    box-shadow: 2px 5px 15px #d3d3d3;
    position: relative;
  }

  .book__left,
  .book__right {
    position: relative;
  }
  .book__left {
    flex: 0.3;
    background-position: center;
    background-repeat: no-repeat;
    background-clip: content-box;
    border-radius: 10px;
    background-size: 200%;
  }
  .book__right {
    flex: 0.7;
    padding: 10px 15px 40px 14px;
    text-align: justify;
    hyphens: auto;
  }

  .back__btn {
    position: absolute;
    top: 15px;
    width: 30px;
    height: 25px;
    left: 15px;
    cursor: pointer;
    color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgba(11, 11, 11, 0.679);
    border: solid 1px #a4a4a4;
  }
  .book__right__top {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 10px 0;
    color: #a4a4a4;
  }

  .book__right__content {
    width: 100%;
  }
  .title {
    text-transform: uppercase;
    margin-top: 20px;
    font-size: 20px;
  }
  .subtitle {
    /* font-size: 12px; */
    margin-bottom: 20px;
    /* margin-top: -5px; */
  }
  .book__right__bottom {
    position: absolute;
    bottom: 5px;
    right: 5px;
  }

  .book__right__bottom > button {
    padding: 5px 20px;
    border: 1.5px solid black;
    background: transparent;
    color: black;
    font-size: 14px;
    text-transform: uppercase;
    margin-left: 10px;
    border-radius: 1px;
  }

  .book__right__bottom > button:hover {
    background: black;
    color: white;
  }

  .courseCover {
    width: 100%;
    height: 100%;
    object-fit: cover;
    padding: 2px;
    border: solid 2px #eee;
    border-radius: 4px;
  }
  img.courseCover {
    border-radius: 4px;
  }
  .notBookmarked,
  .bookmarked {
    font-size: 1.5em;
  }
  .bookmarked {
    color: #465192;
  }

  .rating {
    display: flex;
    gap: 10px;
    align-items: center;
  }

  .bottom_40 {
    margin-bottom: 40px;
  }

  .rating span {
    font-size: 12px;
    padding-top: 5px;
  }

  .authorSection {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .book_price {
    font-size: 1.4em;
    font-weight: 700;
  }
}
