.loaderRoot{
  padding: 2em;
}
.topSection{
  display: flex;
  gap: 2em;
}
.loaderWrapper{
  flex: 1px;
}
.bookCover{
  height: 250px;
}
.aboutBook{
  height: 350px;
}
.bookInfoWrapper{
  display: flex;
  flex-direction: column;
  gap: 1em;
  width: 1;
  flex: 1px;
}
.bookInfo{
height: 25px;
}
.bookTopics{
  padding: 20px;
  background-color: #ffffff;
  margin-top: 10px;
}