.compRoot {
  position: relative;
  text-align: left;
}
.itemBagde {
  top: 2px;
  font-size: 0.65em;
  background-color: #222 !important;
  color: #fff !important;
}
.bellIcon {
  font-size: 1.7em;
}
.dropDownWrapper {
  position: relative;
  top: 15px;
}
.dropDownCard {
  position: absolute;
  padding: 25px 20px;
  width: 400px;
  border: 1px solid var(--border-color2);
  z-index: 10;
  border-radius: 6px;
  background-color: white;
  box-shadow: rgba(0, 0, 0, 0.15) 0px 5px 15px 0px;
  right: 0;
  display: none;
  transition: all 0.3s ease;
}
.onOpen {
  display: block;
  transition: all 0.3s ease;
}
.sectionTitle {
  margin: 0;
  font-size: 1em;
  font-weight: 600;
  text-align: left;
}
.viewAllNotificationBtn {
  border: none;
  background-color: transparent;
  color: #2073f2;
  font-size: 0.9em;
  font-weight: 600;
}
.notificationRow {
  display: flex;
  justify-content: space-between;
  padding: 10px 0px;
  transition: all 0.3s ease;
  gap: 5px;
  cursor: pointer;
}
.notificationRow:hover {
  transform: scale(0.98);
  color: #2073f2;
  transition: all 0.3s ease;
}
.lineDivider {
  border-bottom: 1px solid var(--border-color2);
  margin: 5px 0px;
}
.description {
  font-size: 0.9em;
  font-weight: 600;
  padding-left: 10px;
}
.senderInfo {
  font-size: 0.8em;
  margin-top: 5px;
  opacity: 0.6;
  padding-left: 10px;
}
.profileImg {
  height: 40px;
  width: 40px;
  border-radius: 50%;
}
.newAlertNotice {
  font-size: 1.2em;
  color: #2073f2;
}
.notificationLoader {
  height: 45px;
  margin: 10px 0;
}
.emptyNotification {
  min-height: 10vh;
  font-weight: normal;
  font-size: 0.7rem;
  font-family: "Quicksand";
  color: #aaa;
  /* display: flex; */
  /* align-items: center; */
  /* justify-content: center; */
}
.emptyNotification h3 {
  font-size: 1.2em;
  font-weight: 600;
  opacity: 0.7;
}

.read {
  color: #ccc;
}
.unread {
  color: green;
}

/* Media Queries */
@media (max-width: 400.98px) {
  .dropDownCard {
    position: fixed;
    padding: 1.2em;
    left: 5%;
    width: 300px;
  }
}
@media (min-width: 401.98px) and (max-width: 650.98px) {
  .dropDownCard {
    position: fixed;
    padding: 1.2em;
    left: 6%;
    width: 350px;
  }
}
