.loaderRoot{
  height: 100%;
  padding: 25px;
}
.coverLoaderWrapper{
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.coverImageLoader{
  height: 200px;
  width: 200px;
}
.inputLoaderWrapper{
  display: flex;
  align-items: center;
  gap: 15px;
  margin-top: 25px;
}
.inputLoader{
  height: 60px;
}