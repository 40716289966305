.modal {
  padding: 40px;
  min-height: 300px;
  width: 35vw;
  position: absolute;
  top: 2%;
  left: 30%;
  background-color: #fff;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  z-index: 99999;
}

.scrollWrapper{
  height: 65vh;
  overflow: auto;
  scrollbar-width: none; /* Firefox */
  -ms-overflow-style: none;
}

.row {
  margin: 10px 0;
}
.close {
  position: absolute;
  top: 15px;
  right: 15px;
  cursor: pointer;
}
.action {
  display: flex;
  justify-content: flex-end;
}
.btn {
  margin: 10px 0;
  padding: 10px 20px;
  background-color: rgb(41, 52, 180);
  color: #fff;
  border: none;
  font-size: 0.9em;
  display: flex;
  gap: 5px;
  align-items: center;
}
.title {
  color: #cb743a;
  font-weight: 600;
  font-size: 1.1em;
}

.multiselect{
  border: 1px solid #000 !important;
  border-radius: 3px;
}

.d_flex{
  display: flex;
  align-items: center;
  gap: 5px;
}

.checkbox{
  width: 15px!important;
  height: 15px!important;
}
@media (max-width: 767.98px) {
.modal {
  padding: 20px;
  min-height: 300px;
  position: fixed;
  height: 100%;
  width: 100vw;
  top: 0%;
  left: 0%;
}
}