.viewPort{
  display: flex;
  height: 100vh;
  max-width: 100vw;
  width: 100vw;
  background-color: var(--bg-color1);
}
.rightView{
  width: 90%;
  flex: 1px;
  display: flex;
  flex-direction: column;
}
.mainPage{
  flex: 1;
  overflow: hidden scroll;
  width: 100%;
}
@media screen and (max-width: 968px){
  .appSide{
    display: none;
  }

  .status{
    padding-right: 30px;
  }
}