.bannerRoot{
    min-height: 250px;
  background: transparent linear-gradient(105deg, #5b57d5 0%, #312dad  100%) 0% 0% no-repeat padding-box;
  border-radius: 10px;
  color: #fff;
  position: relative;
  overflow: hidden;
}
.darkImgBanner{
  position: absolute;
  top: 0px;
  right: 0px;
  height: 100%;
  width: 37vw;
}
.pageTile{
  background-color: #000000;
  padding: 10px 20px;
  padding-left: 1em;
  margin-top: 25px;
  font-size: 1.2em;
  font-weight: 600;
  font-family: 'Acme';
  border: none;
  color: #fff;
  display: flex;
  align-items: center;
  gap: 10px;
}
.backIcon{
  font-size: 1.5em;
}
.bookStack{
  position: absolute;
  right: 10px;
  bottom: 20px;
  height: 240px;
}
.bannerSmallText{
  padding: 1.5em 2em;
  width: 40vw;
  font-size: 1.1em;
  font-weight: 500;
  font-family: 'Quicksand';
}