/* 
body {
  margin: 0;
      font-family: 'DM Sans', sans-serif,
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif, 'Inter';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: var(--color-light-black);
  transition: var(scroll-behavior);
  overflow: hidden scroll;
}
#root{
      max-width: 100vw;
}
.swiper{
  margin-left: 0px!important;
}
.swiper-slide {
  height: auto!important;
}
.swiper-wrapper{
  justify-content: stretch!important;
  align-items: stretch!important;
}
 */

@media screen  and ( max-width : 500px ){

  .exam-graph, .exam-graph .recharts-surface{
    width: 350px!important;
    height: 150px!important;
  }
}