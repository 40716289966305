.root {
  min-height: 100vh;
  background-color: #fff;
  position: relative;
}
.page {
  margin: 0;
  padding: 1em 5em;
}
.topBar {
  display: flex;
  justify-content: space-between;
  padding: 30px 60px 0 20px;
  margin-bottom: -20px;
  align-items: center;
}
.subjectComponent {
  margin-left: auto;
}

.card {
  box-shadow: 0 2px 4px 0 rgba(14, 30, 37, 0.12),
    0 2px 16px 0 rgba(14, 30, 37, 0.32);
  margin-left: 10px;
  padding: 20px 30px 30px 30px;
  background: linear-gradient(to left, #5555ff, #7482d8);
  margin-bottom: 20px;
  width: 100%;
  border-radius: 10px;
  position: relative;
  background-image: url(../../../assets/png/reviewCard.png);
  background-position: right bottom;
  background-repeat: no-repeat;
  display: flex;
  flex-direction: column;
}
.subject {
  flex: 1;
  justify-content: flex-end;
  padding: 5px 10px;
  border-radius: 60px;
  margin-left: auto;
  background-color: #ffffff;
  border: solid 1px #fcc39a;
  font-size: 0.7em;
  color: #000;
  font-weight: 800;
}

.cardTitle {
  display: flex;
  align-items: top;
}
.easy,
.medium,
.hard {
  display: flex;
  padding: 0 15px;
  border-radius: 60%;
  margin-right: 20px;
  background-color: rgba(42, 82, 48, 0.929);
  border: 1px solid #fffbec;
  color: #fff;
  align-items: center;
  height: 65px;
  font-weight: 800;
  text-transform: capitalize;
  font-size: 0.9em;
}
.medium {
  height: 85px;
  background-color: rgba(40, 61, 113, 0.929);
}
.hard {
  background-color: rgba(113, 45, 40, 0.929);
}
.cardRow {
  margin-bottom: 20px;
}
.cardRow label {
  color: #888;
  font-size: 0.8em;
}
.cardRow h4 {
  font-size: 1.2em;
}
.cardFooter {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
.cardFooter button {
  background-color: #343537;
  border-radius: 4px;
  padding: 5px 15px;
  font-size: 0.7em;
  color: #fff;
  border: solid 2px #000;
  font-weight: bold;
}

.cardFooter:hover button {
  background-color: #fff;
  color: #000;
  border: solid 2px #000;
}

button {
  color: #000;
}

.row {
  display: flex;
  align-items: center;
  gap: 15px;
}

.searchContainer {
  position: relative;
}

input.search {
  padding: 10px 8px;
  font-size: 0.85rem;
  border: solid 1px #eee;
  color: #888;
  margin-right: 5px;
  min-width: 300px;
}
.search:focus {
  outline: none;
}

.searchBtn {
  position: absolute;
  border: 1px solid #eee;
  background-color: #f9f9f9;
  color: #000;
  right: 10px;
  top: 5px;
  padding: 4px;
  font-size: 0.9em;
}
.search:focus.searchBtn {
  right: 50px;
}

.bucket_container {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 25px;
  margin: 50px 0;
}

@media screen and (max-width: 650px) {
  .page {
    padding: 2em 1.5em;
  }
  .bucket_container {
    grid-template-columns: 1fr;
  }
  .topBar {
    display: grid;
    grid-template-columns: 1fr;
  }
  .topBar > .row {
    display: grid;
    grid-template-columns: 1fr;
    margin: 10px 0;
  }
  .card {
    margin-left: 0;
  }
}

@media screen and (min-width: 651px) and (max-width: 1000px) {
  .page {
    padding: 2em 3.5em;
  }
  .bucket_container {
    grid-template-columns: 1fr 1fr;
  }

  .topBar {
    display: grid;
    grid-template-columns: 1fr;
  }
  .topBar > .row {
    margin: 10px 0;
  }
}
