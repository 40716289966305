.bio {
  display: flex;
  align-items: center;
}
.userImg {
  border-radius: 50%;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: bold;
  text-transform: uppercase;
  font-size: 0.9em;
}
