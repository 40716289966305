.customTooltip {
  background-color: #fff;
  font-size: 0.7rem;
  padding: 10px;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}
.customTooltip p {
  margin-bottom: 2px;
  font-weight: 600;
}
.row {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}
.upTrend,
.downTrend {
  padding: 2px 5px;
  border: solid 1px green;
  color: green;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5px;
  border-radius: 100px;
  font-size: 12px;
}
.downTrend {
  border: solid 1px red;
  color: red;
}
.count {
  font-weight: bold;
  font-size: 1.8em;
  margin-top: -8px;
}
.p20 {
  padding: 20px;
}
.p30 {
  padding: 30px;
  min-height: 320px;
}
.px20 {
  padding: 0 20px;
}

.bookCover {
  width: 40px;
  height: 45px;
  border-radius: 4px;
  padding: 2px;
  border: solid 2px #ddd;
}
.mostViewedContainer {
  max-height: 250px;
  overflow: auto;
}
.mostViewed {
  display: flex;
  align-items: center;
  gap: 20px;
  margin-top: 20px;
  margin-right: 20px;
  padding-top: 10px;
  border-top: dashed 1px #ddd;
}

.mostViewed:last-child {
  border-bottom: dashed 1px #ddd;
  padding-bottom: 10px;
}
.content {
  font-size: 0.8rem;
  color: #444;
  flex: 1;
}
.content p {
  margin-bottom: 0;
}
.topic {
  font-weight: bold;
  font-size: 1rem;
}
.views {
  font-size: 0.9rem;
  color: #777;
}
.expiryList {
  max-height: 450px;
  margin-bottom: 30px;
}
.renewalListContainer {
  max-height: 300px;
  overflow: auto;
}
.listItem,
.listItemHeader {
  display: flex;
  width: 95%;
  text-align: left;
  font-size: 0.9rem;
  color: #555;
}
.listItem {
  border-bottom: dashed 1px #ddd;
  padding: 10px 0;
}
.listItemHeader {
  font-weight: 600;
  color: #000;
  padding-bottom: 5px;
  margin-bottom: 10px;
  border-bottom: solid 1px #eee;
  margin-top: 20px;
}
.expiry_date {
  width: 15%;
}
.name {
  width: 30%;
}
.class {
  width: 30%;
}
.amount {
  width: 10%;
}
.status {
  width: 10%;
}
.actions {
  width: 25%;
  display: flex;
  gap: 30px;
}
.actions span {
  cursor: pointer;
  color: rgb(48, 48, 160);
}
.actions span:hover {
  color: rgb(82, 82, 172);
  transform: scale(1.1);
}
.courseStat {
  display: flex;
  align-items: center;
}
li.courseStatItem {
  font-size: 0.9em;
  margin-bottom: 5px;
}
.skeletonLogLoader {
  height: 60px;
  margin-top: 10px;
}
.emptyState{
  display: flex;
  align-items: center;
  justify-content: center;
  height: 200px;
  opacity: .8;
}
.heading{
  cursor: pointer;
}

.modalTableHead{
 margin-top: -60px;
 cursor: pointer;
}