.root {
  padding: 2em;
  display: flex;
  flex-direction: column;
  margin-top: 10%;
  align-items: center;
  height: 100vh;
  overflow: auto;
}
.formWrapper {
  margin: 0 3em 1em 3em;
}
.formWrapper h4 {
  font-weight: 700;
  color: #152151;
  font-size: 2em;
}
.formWrapper p {
  font-size: 0.85em;
  width: 80%;
  color: #777777;
  margin-bottom: 3em;
}
.formGroup {
  margin: 20px 0;
}
.resetSubmit {
  border-radius: 0;
}
.loginLink {
  width: 100%;
  text-align: left;
  margin-top: 1em;
  font-size: 0.9em;
  color: #777777;
}
.loginLink span {
  color: #152151;
}
.mobile {
  display: none;
}

/* Media Queries */
@media (max-width: 767.98px) {
  .root {
    padding: 1.5em;
  }
  .formWrapper {
    margin: 0 1.5em 1em 1.5em;
  }
  .formWrapper h4 {
    font-size: 1.8em;
  }
  .formWrapper p {
    width: 100%;
  }
  .resetSubmit {
    border-radius: 0;
  }
  .back {
    background-color: transparent;
    border: none;
    outline: 0;
    margin-bottom: 4em;
  }
  .mobile {
    display: block;
  }
}

@media (min-width: 768px) and (max-width: 960.98px) {
  .root {
    padding: 1.5em;
    margin-top: 15%;
  }
  .formWrapper {
    margin: 0 1.5em 1em 1.5em;
  }
  .formWrapper h4 {
    font-size: 1.8em;
  }
  .formWrapper p {
    width: 100%;
  }
  .resetSubmit {
    border-radius: 0;
  }
  .back {
    background-color: transparent;
    border: none;
    outline: 0;
    margin-bottom: 4em;
  }
  .mobile {
    display: block;
  }
}
@media (min-width: 961px) and (max-width: 1199.98px) {
  .root {
    padding: 2em 1em;
    margin-top: 25%;
  }
  .formWrapper {
    margin: 0 1.5em 1em 1.5em;
  }
  /* .mobile {
    display: none;
  } */
}

@media (min-width: 1200px) {
  .root {
    margin-top: 30%;
  }
}
