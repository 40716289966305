.root {
  position: absolute;
  /* top: 15%;
  right: 5%; */
  top: 50%;
  left: 50%;
  transform: translate(-50%,-50%);
  width: 270px;
  height: 350px;
  background-color: rgba(22, 23, 34, 0.941);
  box-shadow: 0px 7px 29px 0px rgba(178, 178, 217, 0.2);
  border-radius: 12px;
  padding: 20px;
}

.top {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.top h4 {
  font-size: 0.95em;
  margin-right: auto;
}

.icon {
  font-size: 1.3em;
  cursor: pointer;
}

.wrapper {
  height: 260px;
  overflow-y: auto;
  position: relative;
}

.content {
  display: flex;
  gap: 16px;
  flex-wrap: wrap;
}

.btn {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  font-size: 0.7em;
  padding: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: solid 2px transparent;
  color: #bdd2e7;
}

.btn.pending {
  background-color: #242538;
  border-color: #4f4f5e;
}

.btn.skipped,
.btn.review,
.btn.answered {
  background-color: transparent;
  border-color: rgb(153, 45, 45);
}

.btn.review {
  border-color: orange;
}

.btn.answered {
  border-color: rgb(71, 129, 71);
}

.answered {
  color: rgb(71, 129, 71);
}
.review {
  color: orange;
}
.skipped {
  color: rgb(153, 45, 45);
}
.pending {
  color: #4f4f4f;
}
ul.legend {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 10px;
  list-style: none;
  margin-bottom: 20px;
}

ul.legend li {
  font-size: 12px;
  color: #a9aaaa;
}
