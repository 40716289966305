.customTooltip {
  background-color: #fff;
  padding: 6px 10px;
  border-radius: 6px;
  font-size: 15px;
  border: solid 2px #aaa;
}
.customTooltip div:first-child {
  margin-bottom: 10px;
}
