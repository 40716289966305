.previewSize {
    width: 55%;
    top: 5%;
    left: 20%;
    height:auto;
    background: #fff;
}

.scrollWrapper {
    height: 65vh;
    overflow: auto;
    padding-top: 20px;
  }


  .questionCard {
    box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
    padding: 10px 20px 20px 20px;
    margin: 10px;
    width: calc(90% / 3);
    font-size: 14px;
  }
  
  .actions {
    display: flex;
    justify-content: flex-end;
  }
  .actions span {
    padding-left: 5px;
  }
  .actions span:hover {
    transform: scale(0.9);
  }
  .tooltip {
    font-size: 1.2em;
    text-transform: uppercase;
    letter-spacing: 0.09em;
  }
  .topic {
    font-size: 1.1em;
    font-weight: bolder;
    margin-top: 10px;
  }
  .topic,
  .question {
    hyphens: auto;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 4; /* number of lines to show */
    line-clamp: 4;
    -webkit-box-orient: vertical;
    margin: 10px 0 0px 0;
  }
  .question {
    border-left: solid 4px #969dc8;
    padding-left: 5px;
  }
  
  .sub_category {
    border-radius: 7px;
    font-weight: 400;
    margin: 10px 0 15px 0;
    color: red;
  }
  .bucketcard_header_container {
    gap: 0;
    align-items: center;
    justify-content: space-between;
  }
  
  .bucket_info {
    align-items: center;
    justify-content: space-between;
  }
  
  .bucket_content {
    align-items: center;
    gap: 3px;
  }
  .skeletonLogLoader {
    height: 175px;
    width: 180px;
  }

  /* .term{
    cursor: pointer;
    background: #000000;
    padding: 5px 10px;
    margin-right: 5px;
    border-radius: 5px;
    font-weight: 700;
    color: white;
    font-size: 12px;
  } */

  /* .second{
    background: #cdcdcd;
    color: black;
  } */

  .skeletonLogLoader {
    height: 175px;
    width: 180px;
  }
  
  .row {
    display: flex;
    flex-direction: row;
    align-items: center;
    flex: 1;
    gap: 5px;
  }

.term,
.second,
.thirdTerm {
  padding: 5px 10px;
  font-size: 0.7rem;
  background-color: #4f6486;
  color: #fff;
  cursor: pointer;
  /* text-transform: capitalize; */
  letter-spacing: 0.1em;
}
.second {
  background-color: #775dd0;
  color: #fff;
}
.third {
  background-color: #c5674a;
  color: #fff;
}