.pageRoot{
  padding: 5px 8%;
  height: 90%;
  display: flex;
  flex-direction: column;
}
.inputRow{
  display: flex;
  gap: 20px;
  margin-top: 20px;
}
.inputColumn{
  flex: 1px;
}
.materialsRow{
  display: flex;
  gap: 10px;
  margin: 10px 0;
}
.topicName{
  width: 300;
  border: 1px solid #000000;
  display: flex;
  align-items: center;
  padding: 15px;
  height: 46px;
}
.actionSection{
  width: 60px;
  display: flex;
  align-items: center;
  gap: 10px;
}
.addButton{
  height: 25px;
  width: 25px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #105FFB;
  color: #fff;
  font-size: 1.2em;
  font-weight: 600;
  border: none;
  border-radius: 50%;
}
.deleteRowButton{
  height: 25px;
  width: 25px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #FC0000;
  color: #fff;
  font-size: 1.1em;
  font-weight: 600;
  border: none;
  border-radius: 50%;
}
.inputLabel{
  font-size: 1em;
  font-weight: 600;
  margin-bottom: 5px;
}
.mainPageBody{
  flex: 1px;
  margin-bottom: 20px;
  overflow-y: scroll;
}
.saveButton{
  width: 150px;
}
.backIcon{
  font-size: 1.5em;
}
.backButton{
  border: none;
  background-color: transparent;
  padding: 5px 15px;
  display: flex;
  align-items: center;
  gap: 10px;
}
.footerActionWrap{
  display: flex;
  align-items: center;
  justify-content: space-between;
}