.boardHeader,
.boardBody {
  display: flex;
  padding: 10px 30px;
  font-size: 0.89em;
  margin: 0 20px;
  border-radius: 4px 4px 0 0;
  box-shadow: 0 0 1px 1px #ddd;
}
.boardHeader {
  font-weight: bold;
  font-size: 0.8em;
}
.boardBody {
  margin: 5px 20px 0 20px;
  border-radius: 0;
  border-left: 4px solid transparent;
}
.team {
  flex: 2;
  display: flex;
}

.pts {
  display: flex;
  gap: 30px;
  justify-content: space-evenly;
  align-items: center;
  width: 450px;
}
.pts > div {
  width: 50px;
  text-align: center;
}
a.team-link {
  text-decoration: none;
  color: #333;
}
.campionship {
  border-left-color: rgb(88, 147, 91);
}
.qualification {
  border-left-color: rgb(233, 184, 115);
}
.relegation {
  border-left-color: rgb(193, 103, 122);
}
.max {
  background-color: #304a7d;
  padding: 4px 8px;
  border-radius: 4px;
  color: #dde8ff;
  font-weight: bold;
}
