.pageRoot{
  display: flex;
  flex-direction: column;
  height: 100%;
}

 .root_header{
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 10px 0;
  }

  .root_header_title{
    display: flex;
    gap: 10px;
  }

  .root_header_title > h5{
    font-weight: bolder;
    text-transform: uppercase;
  }

  .actions {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: 12px;
    margin-right: 30px;
    font-size: 1.6em;
  }

.pageBody{
  flex: 1;
  overflow-y: scroll;
}
.pageFoot{
  height: 40px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 20px;
  margin-bottom: -20px;
  margin-top: 15px;
  padding: 3px 20px;
}
.saveButton{
  width: 140px !important;
  transition: .3s ease;
}
.saveButton:hover{
  transform: scale(.95);
}
.cancelButton{
  width: 100px !important;
  background-color: transparent !important;
  border: 1px solid #ccc;
  color: #212529;
  transition: .3s ease;
}
.cancelButton:hover{
    transform: scale(.95);
}
.createReviewWraper{
  border: 1px solid #d9e5f1;
  border-radius: 5px;
  padding: 20px 24px;
  background-color: #f9fafc;
  /* width: 400px; */
  cursor: pointer;
  transition: 0.3s ease-in-out;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.titleInput {
  border-radius: 2px;
  font-size: 1.2rem;
}
.textInputRow{
  display: flex;
  gap: 20px;
  padding: 20px;
  box-sizing: border-box;
}
.textInputRow label{
  margin-bottom: 5px;
  font-size: .85em;
  font-weight: 400;
}
.createReviewWraper:hover{
  transform: scale(.95);
}
.addTitle{
  font-size: 1em;
  font-weight: 600;
  display: flex;
  gap: 8px;
  align-items: center;
  color: #3c6dd6;
}
.buttonInfo{
  height: 50px;
  border: 1px dashed #cacacc;
  border-radius: 5px;
  margin-top: 15px;
  background-color: #fff;
  padding: 20px;
  display: flex;
  align-items: center;
  font-size: .9em;
  font-weight: 400;
}
.rowDivider{
  display: flex;
  align-items: center;
  gap: 15px;
  padding: 0 20px;
}
.dividerLine{
  flex: 1
}
.questionNumber{
  font-size: 1em;
  font-weight: 600;
}
.questionAndAnswerRow{
  display: flex;
  gap: 20px;
  padding: 20px;
}
.questionAndAnswerRow > *{
  flex: 1;
}
.cardWrapper{
  border: 1px solid #c7c8c9;
  border-radius: 7px;
  padding: 10px 20px;
  min-height: 250px;
  background-color: #f9fafb;
  position: relative;
}
.deleteButton{
  background-color: #eff6ff;
  border-radius: 50%;
  height: 30px;
  width: 30px;
  border: 1px solid #c7c8c9;
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: .7;
}
.cardWrapper textarea {
  padding: 20px;
  outline: none;
  box-shadow: none;
  border: solid 2px #eee;
  border-radius: 5px;
  width: 99%;
}
.cardWrapper textarea::placeholder{
  opacity: .6;
}
.mediaSection{
  display: flex;
  align-items: center;
  gap: 15px;
  margin-top: 7px;
}
.mediaSection img{
  object-fit: contain;
    width: 80px;
  height: 50px;
}
.mediaButton{
  font-size: 1.8em;
  width: 80px;
  height: 50px;
  border-radius: 3px;
  border: 1px solid #ccc;
  background-color: transparent;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: .3s ease;
  cursor: pointer;
  position: relative;
}
.mediaButton:hover{
  transform: scale(.97);
}
.loader{
  position: absolute;
}