.leaderboardcontainer {
  padding: 2em;
  display: flex;
  flex-direction: column;
  height: 90%;
}
.calender {
  display: flex;
  align-items: center;
  gap: 12px;
  margin-top: 12px;
  margin-bottom: 12px;
  cursor: pointer;
}
.control {
  font-size: 1.2em;
  color: #222;
}
.month {
  border-radius: 5px;
  color: #222;
  padding: 6px;
  font-size: 15px;
  font-family: "Noto Sans", sans-serif;
}
.month:hover {
  color: #ffff;
  background: #222;
}

.archivement {
  width: 20%;
}
.tableWrap {
  border-radius: 15px;
}
.table {
  font-family: arial, sans-serif;
  border-collapse: collapse;
  width: 100%;
}
.heading {
  border: 1px solid #dddddd;
  text-align: left;
  padding: 8px;
}
.calenderWrapper {
  position: relative;
  margin-bottom: 15px;
}
.calenderView {
  position: absolute;
  top: 0px;
  left: 0px;
  display: none;
  box-shadow: rgba(0, 0, 0, 0.25) 0px 14px 28px,
    rgba(0, 0, 0, 0.22) 0px 10px 10px;
}
.showCalender {
  display: block;
}

.board {
  /* background-color: white; */
  /* padding: 10px; */
  border: 1px solid #ddd;
  border-radius: 12px;
  height: 100vh;
  overflow: auto;
}
.pillMenu {
  border: none !important;
  display: flex;
  justify-content: flex-end;
  background-color: white !important;
  padding: 10px 10px 15px 10px;
}
.tab {
  background-color: transparent !important;
  border: none !important;
  /* color: #1038b1;
}

.active { */
  color: rgb(49, 49, 178) !important;
  border-bottom: 2px solid #5808d1 !important;
}
