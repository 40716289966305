@import url("https://fonts.googleapis.com/css2?family=Acme&family=Shantell+Sans&display=swap");

.imageCard {
  background-color: white;
  cursor: pointer;
  /* width: 100%; */
  width: 250px;
  border-radius: 4px;
  overflow: hidden;
  margin-bottom: 20px;
}
.shelfCard {
  background-color: white;
  cursor: pointer;
  display: flex;
  gap: 3em;
  margin-bottom: 20px;
}

.imageCard:hover {
  transform: scale(1.03);
  transition: all 0.3s ease;
}
.bookWrapper {
  height: 200px;
  padding: 2px;
}
.bookImg {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 4px 4px 0 0;
}
.bookContent {
  padding: 10px 15px;
}
.title {
  text-transform: capitalize;
  margin-bottom: 0px;
  font-weight: 700;
  font-size: 16px;
  font-family: "Asap", sans-serif;
}
.author {
  text-transform: capitalize;
  margin-bottom: 0px;
  font-size: 13px;
  font-family: "Quicksand", sans-serif;
}
.subject{
  text-transform: capitalize;
  margin-bottom: 0px;
  font-size: 13px;
  font-family: "Quicksand", sans-serif;
  /* margin-bottom: 0px;
  font-size: 10px;
  background: rgb(183, 214, 224);
  width: fit-content;
  padding: 5px;
  border-radius: 3px;
  font-weight: bolder; */
}

.ratingStar {
  margin-bottom: 0px;
  font-size: 16px;
}
.price {
  font-weight: 700;
  font-size: 14px;
  padding-top: 10px;
  font-family: "Acme", sans-serif;
  text-transform: uppercase;
}
.rateContent {
  display: flex;
  gap: 4px;
}
.starIcon {
  color: hsl(33, 85%, 53%);
}
.emptyStar {
  color: hsl(33, 85%, 53%);
}

.rightContainer {
  padding: 15px 15px 15px 0;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.bookTask {
  font-size: 1em;
  line-height: 1.285;
  white-space: nowrap;
  margin-bottom: 10px;
  font-family: "Asap", sans-serif;
}
.bookTime {
  font-size: 0.9em;
  line-height: 1.285;
  white-space: nowrap;
  margin-bottom: 10px;
  font-family: "Quicksand", sans-serif;
}
.borrowedBtn {
  background-color: #a0a0a0;
  border-radius: 3px;
  color: #ffffff;
  font-size: 15px;
  margin-bottom: 15px;
}
.returnBtn {
  background: #ffffff;
  color: var(--color-primary);
  border: 1px solid var(--color-primary);
  border-radius: 3px;
}
.ebookBtn {
  border-radius: 3px;
  background-color: transparent;
  border: 1px solid #ff6900;
  color: #ff6900;
  padding: 5px 20px;
  white-space: nowrap;
}
.returnWrapper {
  display: flex;
  margin-top: 20px;
}
.readBtn {
  background: #ffffff;
  color: var(--color-primary);
  border: 1px solid var(--color-primary);
  border-radius: 3px;
  border-top-right-radius: 0px;
  border-bottom-right-radius: 0px;
}
.audioBtn {
  background: #ffffff;
  color: var(--color-primary);
  border: 1px solid var(--color-primary);
  border-radius: 5px;
}
.headsetBtn {
  background: #ffffff;
  color: var(--color-primary);
  border: 1px solid var(--color-primary);
  border-radius: 3px;
  border-left: none;
  border-top-left-radius: 0px;
  border-bottom-left-radius: 0px;
}

.video {
  background: #ffffff;
  color: var(--color-primary);
  border: 1px solid var(--color-primary);
  border-radius: 3px;
  border-left: none;
  border-top-right-radius: 0px;
  border-top-left-radius: 0px;
  border-bottom-left-radius: 0px;
  border-bottom-right-radius: 0px;
}

.videoBtn {
  background: #ffffff;
  color: var(--color-primary);
  border: 1px solid var(--color-primary);
  border-radius: 3px;
  border-left: none;
  border-top-left-radius: 0px;
  border-bottom-left-radius: 0px;
}
.mediaTypeBtn {
  width: 40px;
  height: 30px;
  background-color: #303c47;
  color: #ffffff;
}
.mediaTypeBtn:hover {
  background-color: #707070;
}
.mediaIcon {
  font-size: 1.3em;
}


@media screen and (max-width: 600px) {

  .imageCard {
    width: 100%;
  }
}

@media screen and (min-width: 600px) and (max-width : 862px){

  .imageCard {
    width: 45%;
  }
}
