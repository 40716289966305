.pageRootWrap{
  padding: 20px;
  overflow-y: scroll;
  height: 100%;
}
.addCardBtn{
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px;
  border-radius: 6px;
  background-color: #fff;
  margin-bottom: 20px;
  margin-top: 10px;
  cursor: pointer;
}
.addCardItem{
  display: flex;
  gap: 10px;
  align-items: center;
  font-size: 1em;
  font-weight: 600;
}
.publishButton{
  padding: 5px 20px;
  font-weight: 600;
}
.sectionTitle{
  font-size: 1.1em;
  font-weight: 600;
    margin-bottom: 1em;
}
.templateBoxRow{
  display: flex;
  overflow-x: scroll;
  margin-bottom: 1em;
  flex: 1;
  padding: 10px 0;
  gap: 20px;
}
.isSelectedTemplate{
  border: 2px solid #011B60;
}
.templateWrap{
  height: 100%;
  width: 360px;
  display: flex;
  flex-direction: column;
  gap: 10px;
  border-radius: 5px;
  padding: 10px;
}
.templateImage{
  flex: 1px;
    background-color: rgb(243, 245, 245);
}
.templateName{
  text-align: center;
  font-size: 1em;
  font-weight: 600;
  text-transform: capitalize;
}