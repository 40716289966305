.courseBanner{
  min-height: 200px;
  background-color: #312dad;
  background-image: radial-gradient(#7a78a4  .7px, transparent 1px); /* Create a repeating radial gradient for the dots */
  background-size: 6px 6px; /* Adjust the size of the dots */
  border-radius: 7px;
  color: #fff;
  padding: 20px;
  position: relative;
  display: flex;
  justify-content: flex-end;
}
.bannerTextWrap{
  width: 70%;
  padding: 2em;
}

.courseTypeText{
  padding: 8px 20px;
  border: none;
  background-color: #100f0f;
  color: #fff;
  font-size: .8em;
  font-weight: 600;
  display: flex;
  align-items: center;
  gap: 5px;
  position: absolute;
  top: 10%;
  left: 0px;
}
.backIcon{
  font-size: 1.5em;
}