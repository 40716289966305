.rootPage {
  padding: 0 8em 0 5em;
  position: relative;
  min-height: 90vh;
}

ul.tabs,
ul.menu {
  list-style: none;
  display: flex;
}
ul.tabs {
  border-bottom: 1px solid #ccc;
  padding-top: 20px;
}
ul.tabs li {
  padding: 10px 20px;
  background-color: #f0eeee;
  border: solid 1px #ccc;
  border-bottom: none;
  border-radius: 5px 5px 0 0;
  cursor: pointer;
}

ul.tabs li:first-child {
  border-right: none;
}
ul.tabs li.active {
  background-color: #fff;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}
.content {
  border: 1px solid #ccc;
  border-top: none;
  margin-top: -15px;
  padding: 10px 20px;
}
li.tab {
  display: flex;
  align-items: center;
  gap: 10px;
}

ul.menu {
  gap: 20px;
  padding: 20px 0 10px 20px;
  align-items: center;
  position: relative;
}
ul.menu li {
  cursor: pointer;
  font-size: 0.95rem;
}
li.compose {
  display: flex;
  align-items: center;
  gap: 8px;
  position: absolute;
  right: 0;
  padding: 6px 20px;
  background-color: rgb(85, 18, 241);
  color: #fff;
  border-radius: 4px;
  font-size: 0.7rem;
  /* text-transform: uppercase; */
}
li.compose:hover {
  transform: scale(1.05);
}
li.checkbox {
  display: flex;
  align-items: center;
  gap: 5px;
}
input[type="checkbox"] {
  width: 20px;
  height: 20px;
}
.inbox {
  display: flex;
}
.messageList,
.messageReader {
  flex: 1;
  height: 55vh;
  overflow: auto;
  padding: 20px;
}

.messageList {
  flex: 0.8;
}
.notification {
  display: flex;
  gap: 20px;
  align-items: flex-start;
  background-color: #fff;
  padding: 40px 40px 20px 40px;
  position: relative;
  cursor: pointer;
}
/* .notification:nth-child(even) {
  background-color: #f1965b;
} */
.selectMsg {
  display: flex;
  align-items: center;
  position: absolute;
  top: 5px;
  right: 20px;
}
.left {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.initials,
.senderInitials {
  background-color: #f1965b;
  color: #000;
  border-radius: 100px;
  padding: 10px 16px;
  font-weight: bold;
}
.date {
  margin-top: 20px;
}
.date h4 {
  text-align: center;
  font-size: 14px;
  font-weight: 600;
  color: #777;
}
.read {
  color: #ccc;
}
.unread {
  color: green;
}
.right {
  padding-left: 10px;
}
.right .author {
  font-weight: 600;
}
.right .title,
.right .message {
  color: #777;
  margin-bottom: 10px;
}
.messageReader {
  padding: 20px 40px;
  background-color: #fff;
  margin-left: 20px;
}
.messageReadModal{
  padding: 10px 20px;
  height: 100%;
}
.messageModal{
  height: 100%;
}
.readerTitle {
  font-weight: 600;
  margin-bottom: 30px;
}
.senderInitials {
  margin: 20px 20px 20px 0;
}
.readerDetails {
  margin-bottom: 20px;
  display: flex;
  align-items: center;
}
.messageDate {
  font-size: .7em;
  font-style: italic;
  opacity: .6;
}
.messageReader .message {
  min-height: 10vh;
  margin: 0px 20px 20px 1rem;
  padding-bottom: 20px;
}
.delete {
  color: #b94d05;
}

.notification_active .date h4 {
  color: #333;
}
.notification_active .initials {
  background-color: #fff;
}
.notification_active {
  background-color: #f1965b;
}
.notification_active .right .title,
.notification_active .right .message {
  color: #333;
}
.dateOnMobileView{
  display: none;
}
@media (max-width: 767.98px) {
.rootPage{
  padding: 0 20px;
}
li.tab {
  gap: 5px;
}
.tab span {
 font-size: .8em;
}
ul.tabs li {
  padding: 7px 15px;
}
li.compose{
  position: fixed;
  bottom: 20px;
  right: 20px;
  height: 50px;
  width: 50px;
  border-radius: 100%;
  font-size: 1.7em;
  padding: 1px;
  justify-content: center;
  align-items: center;
}
li.compose span{
  display: none;
}
ul.menu {
  gap: 10px;
  padding: 5px 0 5px 0px;
}
ul.menu li {
  font-size: 0.8rem;
}
.notification {
  padding: 10px;
}
.messageList {
  flex: 1;
  padding: 0px;
}
.messageReader {
  display: none;
}
.right .author {
  font-weight: 600;
  font-size: .8em;
}
.right {
  padding-left: 0px;
}
.right .message {
   font-size: .8em;
}
.date {
display: none;
}
.dateOnMobileView{
  display: flex;
  justify-content: flex-end;
}
.dateOnMobileView h4 {
  text-align: end;
  font-size: .7em;
  font-weight: 400;
  font-style: italic;
}
.initials, .senderInitials {
  padding: 5px;
  height: 30px;
  width: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.notification{
  gap: 10px
}
.content{
  padding: 5px 10px;
}
.inbox {
  flex-direction: column;
}
.authorFullName{
  font-size: .8em;
}
}

@media screen and (min-width: 767.99px) and (max-width: 1000px) {}