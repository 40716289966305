.popupRoot {
  position: fixed;
  height: 100vh;
  width: 100vw;
  background-color: rgba(0, 0, 0, 0.379);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 999;
  overflow: hidden;
}
.childComponent {
  border-radius: 5px;
  padding: 10px 20px;
  min-width: 30vw;
  background-color: var(--color-white);
  color: var(--color-black);
  max-height: 80vh;
  overflow: scroll;
  /* scrollbar-width: none;
  scrollbar-width: none; */
}
/* .childComponent::-webkit-scrollbar {
  display: "none";
} */
.popupOpen {
  display: none;
}
.cancelBtnWrapper {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.cancelButton {
  background-color: transparent;
  border: none;
  padding: 5px 0px;
}
.cancelIcon {
  font-size: 23px;
}

/* Media Queries */
@media (max-width: 767.98px) {
  .childComponent {
    border-radius: 0;
    padding: 0;
    min-width: 100%;
    max-height: 100%;
    scrollbar-width: none;
    scrollbar-width: none;
  }
  .childComponent::-webkit-scrollbar {
    display: "none";
  }
  .cancelBtnWrapper {
    background-color: #fff;
    padding: 20px 20px 5px 0;
    position: fixed;
    width: 100%;
  }
  .container {
    margin-top: 2em;
    padding: 1.5em;
    width: 100%;
  }
}

@media (min-width: 768px) and (max-width: 991.98px) {
}

@media (min-width: 992px) and (max-width: 1199.98px) {
}

@media (min-width: 1200px) and (max-width: 1400px) {
}
