:root {
  --primary-color: rgba(53, 41, 99, 0.897);
  --secondary-color: rgba(53, 41, 99, 0.568);
  --light-color: #dae6ff;
  --accent-color: #ccab6b;
  --color1: #da7638;
  --color2: #f2ac80;
}
.flipCard {
  height: 450px;
  perspective: 1000px;
}

.flipCardInner {
  position: relative;
  width: 100%;
  height: 100%;
  transition: transform 0.8s;
  transform-style: preserve-3d;
}

.flipCard.flipped .flipCardInner {
  transform: rotateY(180deg);
}

.flipCardFront,
.flipCardBack {
  position: absolute;
  width: 100%;
  height: 100%;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
}

.flipCardFront {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--secondary-color);
  border-radius: 12px;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}

.flipCardBack {
  padding: 20px;
  background-color: var(--primary-color);
  border-radius: 12px;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  transform: rotateY(180deg);
  display: flex;
  flex-direction: column;
}

.flipCardInner button {
  padding: 20px 40px;
  background-color: transparent;
  color: var(--light-color);
  border: 2px solid var(--light-color);
}
.flipCardInner button:hover {
  border: solid 2px var(--color2);
  color: var(--color2);
}

.flipCardBack button {
  padding: 5px 10px;
  max-width: 200px;
  margin-left: 50%;
  transform: translate(-50%, 0);
}
.answer {
  font-size: 1.1rem;
  min-height: 340px;
  white-space: pre-wrap;
  word-wrap: break-word;
  overflow: auto;
}

/* Media Queries */
@media (max-width: 767.98px) {
  /* :root {
    --primary-color: rgb(53, 41, 99);
    --secondary-color: rgba(53, 41, 99, 0.568);
    --light-color: #dae6ff;
    --accent-color: #ccab6b;
    --color1: #da7638;
    --color2: #f2ac80;
  } */
  .flipCard {
    height: 90vh;
    perspective: 1000px;
  }

  /* .flipCardInner {
    position: relative;
    width: 100%;
    height: 100%;
    transition: transform 0.8s;
    transform-style: preserve-3d;
  } */

  .flipCard.flipped .flipCardInner {
    /* transform: rotateY(180deg); */
  }

  .flipCardFront,
  .flipCardBack {
    /* position: absolute;
    width: 100%;
    height: 100%;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden; */
  }

  .flipCardFront {
    /* display: flex; */
    /* align-items: center; */
    /* justify-content: center; */
    /* background-color: var(--secondary-color); */
    /* border-radius: 0; */
    /* box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px; */
  }

  .flipCardBack {
    padding: 20px;
    background-color: rgb(53, 41, 99);
    border-radius: 0;
    /* box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px; */
    /* transform: rotateY(180deg); */
    display: flex;
    flex-direction: column;
  }

  .flipCardInner button {
    /* padding: 20px 40px;
    background-color: transparent;
    color: var(--light-color);
    border: 2px solid var(--light-color); */
  }
  .flipCardInner button:hover {
    /* border: solid 2px var(--color2);
    color: var(--color2); */
  }

  .flipCardBack button {
    /* padding: 5px 10px;
    max-width: 200px;
    margin-left: 50%;
    transform: translate(-50%, 0); */
  }
  .answer {
    /* font-size: 1.1rem;
    min-height: 340px;
    white-space: pre-wrap;
    word-wrap: break-word;
    overflow: auto; */
  }
}

@media (min-width: 768px) and (max-width: 991.98px) {
}

@media (min-width: 992px) and (max-width: 1199.98px) {
}

@media (min-width: 1200px) and (max-width: 1400px) {
}
