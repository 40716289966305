.pageRoot{
  display: flex;
  flex-direction: column;
  height: 100%;
  padding: 0 1.5em;
  padding-bottom: 10px;
}
.pageBody{
  flex: 1px;
  /* overflow-y: scroll; */
  margin-top: 3em;
}
.catalogItems{
  display: grid;
  grid-template-columns: repeat(3, minmax(0, 1fr));
    grid-gap: 20px;
}
.searchWrapper{
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px 0;
}
.searchWrapper >*{
  width: 60%;
}
.skeletonLogLoader{
  height: 250px;
}
.bannerBox{
  background: transparent linear-gradient(105deg, #f68104 0%, #da7202  100%) 0% 0% no-repeat padding-box !important;
}

@media screen and (max-width: 968px) {
.catalogItems{
  display: grid;
  grid-template-columns: repeat(2, minmax(0, 1fr));
    grid-gap: 20px;

}
}
@media screen and (max-width: 600px) {
.catalogItems{
  display: grid;
  grid-template-columns: repeat(1, minmax(0, 1fr));
    grid-gap: 20px;
}
.searchWrapper >*{
  width: 100%;
}
}