.switchWrapper {
  display: flex;
  justify-content: center;
  background-color: #f4f4f4;
  padding: 3;
  border-radius: 50;
  width: 400px;
  overflow: hidden;
}

.overlay {
  transition: all 250ms cubic-bezier(0.6, 0.52, 0.59, 1.39);
  background-color: #3b43d0!important;
  /* width: 50%; */
  /* height: 100%; */
  border-radius: 40px;
  /* position: absolute; */
}

.buttContainer {
  background-color: #f4f4f4;
  display: flex;
  justify-content: space-between;
  height: 38;
  width: 100%;
  position: relative;
}

.button {
  /* width: 50%; */
  border-radius: 20px;
  font-family: "Avenir";
  font-weight: 200;
  font-size: .8em;
  background-color: transparent;
  white-space: nowrap;
  cursor: pointer;
  z-index: 1;
  color: #000;
  font-size: .6em;
  font-weight: 600;
}

;

.button:hover {
  background-color: transparent;
}