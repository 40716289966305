/* Style the tooltip container */
.tooltip {
  position: relative;
  cursor: pointer;
}

.tooltip:hover::after {
  content: attr(
    data-tooltip
  ); /* Get tooltip text from data-tooltip attribute */
  display: block;
  position: absolute;
  background-color: rgba(13, 13, 13, 0.849);
  color: #fff;
  padding: 5px 10px;
  bottom: 100%; /* Position above the trigger element */
  left: 50%;
  transform: translateX(-50%); /* Center horizontally */
  opacity: 0; /* Initially hidden */
  transition: opacity 0.3s; /* Fade-in animation */
  max-width: 180px;
  text-align: center;
  opacity: 1;
  font-size: 0.6em;
}
