.bookWrapper {
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
  padding: 20px 40px;
  margin-top: 20px;
}
.scrollWrapper {
  height: 400px;
  overflow: auto;
}
.bookCover {
  width: 40px;
  height: 45px;
  border-radius: 5px;
  margin-top: 10px;
}

table.bookList {
  width: 100%;
  /* margin-top: 20px; */
}

.bookList thead {
  height: 60px;
}
.actions i {
  font-size: 1.3em;
  cursor: pointer;
}

.actions {
  display: flex;
  gap: 20px;
  padding-top: 10px;
}

.actions i:hover {
  color: rgb(178, 89, 5);
}
.skeletonLogLoader {
  height: 175px;
  width: 180px;
}
