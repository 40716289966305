.close,
.content {
  display: flex;
}
.close {
  justify-content: flex-end;
}
.close button {
  border: none;
  background-color: transparent;
  font-size: 2.5em;
  padding-right: 10px;
  color: #777;
}
.content {
  padding: 20px 20px 20px 60px;
  gap: 40px;
}
.left {
  flex: 2;
}
.left p {
  color: #777;
}
.right {
  flex: 1;
}
.rightScroll {
  height: 350px;
  overflow-y: auto;
  padding: 10px 5px;
}
.right p {
  color: #444;
}

.topicsContainer {
  width: 100%;
  padding: 20px;
  border-radius: 6px;
}
/* .topicsContainer h5 {
  color: #444;
} */
.topics {
  max-height: 300px;
  overflow-y: auto;
  padding: 2px 20px 2px 2px;
}
ul.topics {
  list-style: none;
}

ul.topics li {
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  padding: 20px;
  margin-bottom: 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
li span {
  text-align: right;
  letter-spacing: 2px;
  font-weight: 600;
}

@media screen  and (max-width : 700px){
  .content {
    display: block;
    padding: 20px;
  }
  .rightScroll {
    height: 250px;
  }
}

@media screen  and (min-width : 601px) and (max-width : 860px){
  .content {
    display: block;
    padding: 20px;
  }
  .rightScroll {
    height: 250px;
  }

  .rightScroll > div > div {
    display: flex;
    gap: 30px;
  }
}