.pageRoot {
  display: flex;
  height: 100vh;
  width: 100vw;
  overflow: hidden;
}
.backHomeButton {
  display: flex;
  align-items: center;
  gap: 7px;
  border: none;
  background-color: transparent;
  color: #fff;
  margin-top: 1.5em;
  padding: 5px 15px;
  font-size: 1.2em;
}
.aside {
  flex: 2;
  min-width: 62%;
  height: 100vh;
  background-image: url(../../assets/png/login.png);
  background-repeat: no-repeat;
}
.content {
  flex-grow: 1;
}

/* -----------------------------------------------------------------------------
                          MEDIA QUARY
----------------------------------------------------------------------------- */

@media (max-width: 960px) {
  .pageRoot {
    flex-direction: column;
  }
  .aside {
    display: none;
    /* height: 200px; */
    width: 100%;
  }
}

/* Media Queries  */
@media (max-width: 767.98px) {
}

@media (min-width: 768px) and (max-width: 991.98px) {
}

@media (min-width: 992px) and (max-width: 1199.98px) {
}

@media (min-width: 1200px) and (max-width: 1400px) {
}
