.pageRoot {
  height: 100vh;
  position: relative;
}
.courseBanner {
  min-height: 200px;
  background: transparent linear-gradient(105deg, #5b57d5 0%, #312dad 100%) 0%
    0% no-repeat padding-box;

  color: #fff;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.courseBanner h2 {
  font-weight: 800;
}
.courseBanner p {
  color: #cfcdfe;
}

.cardWrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 20px;
  flex: 1;
  height: 60vh;
  padding: 2em 2em 4em 2em;
}

/* Media Queries */
@media (max-width: 767.98px) {
  .courseBanner {
    padding: 2em;
  }

  .cardWrapper {
    display: grid;
    grid-template-columns: 1fr;
    height: auto;
  }

  .cardWrapper > div {
    max-width: 100% !important;
    height: auto !important;
  }
}

@media (min-width: 768px) {
  .courseBanner {
    align-items: center;
    text-align: center;
  }
}
