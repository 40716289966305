.cardWrapper {
  padding: 15px;
  border: 1px solid #0000003b;
  max-width: 300px;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  border-radius: 5px;
  cursor: pointer;
  height: 260px;
  justify-content: center;
}
.cardWrapper:hover {
  transform: scale(0.97);
  border: 1px solid #f2611f;
}
.icon {
  font-size: 1.7em;
}
.cardTitle {
  font-size: 1.7em;
  font-weight: 600;
  margin-top: 1em;
  font-family: "Acme";
}
.cardDescription {
  font-size: 1em;
  font-weight: 600;
  margin-top: 1.2em;
  font-family: "Quicksand";
}
