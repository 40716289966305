.mainRoot{
  height: 100%;
  position: relative;
  padding: 10px;
}
.overlayBackground{
   background-color: #ffffff9e;
   border-radius: 6px;
   position: absolute;
   top: 0;
   left: 0;
   height: 100%;
   width: 100%;
   z-index: 0;
   box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
}
.rootWrapper{
  height: 100%;
  background-color: #fff;
  /* padding: 20px; */
  border-radius: 5px;
  z-index: 2;
  position: relative;
  overflow: hidden;
}
.acctionBtnWrapper{
  display: flex;
  gap: 15px;
  margin-top: 1.2em;
  justify-content: flex-end;
  align-items: center;

}
.cancelPlanBtn{
  width: 80px;
  background-color: #F4F5F9;
  color: #000;
  font-size: 12px !important;
  height: 30px !important;
}
.selectPlanBtn{
  width: 80px;
  font-size: 12px !important;
  height: 30px !important;
}
.pageBody{
  flex: 1;
  display: flex;
  height: 100%;
}
.allPlansSection{
  flex: 1;
  background-color: #F4F5F9;
  height: 100%;
  padding: 20px 0px 20px 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.planCard{
  padding: 15px 20px;
  cursor: pointer;
  border-radius: 2px;
  position: relative;
}
.pointer{
  position: absolute;
  font-size: 2em;
  right: 0;
  top: 35%;
  margin-right: -20px;
  color: #3b43d0;
  display: none;
}
.planCard:hover{
  background-color: #3b43d0;
  color: #fff;
}
.activePlan{
  background-color: #3b43d0;
  color: #fff;
}
.planName{
  font-size: 20px;
  font-weight: 200;
  text-transform: capitalize;
}
.planPrice{
  font-size: 1.3em;
  font-weight: 800;
}
.planDuration{
  font-size: .9em;
  font-weight: 300;
}
.selectedPlanSection{
  flex: 1;
  display: flex;
  flex-direction: column;
  height: 100%;
  padding: 20px;
  box-sizing: border-box;
}
.selectedPlanInfo{
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 10px 30px;
}
.selectedPlanName{
  font-size: 1.3em;
  font-weight: 800;
  text-transform: capitalize;
}
.selectedPlanUnits{
   font-size: 1em;
  font-weight: 200;
}
.selectedPlanPrice{
  font-size: 1.6em;
  font-weight: 800;
  color: #3b43d0;
  margin-top: 10px;
}
.selectedPlanWrapper{
  line-height: 27px;
}
.plansLoader{
  height: 70px;
  margin: 10px 0;
}
/* -----------------------------------------------------------------------------
                          MEDIA QUARY
----------------------------------------------------------------------------- */

@media  (max-width: 960px) {
.rootWrapper{
  width: 70vw;
}
}