  :root {
    --color-white: #ffffff;
    --color-neutral-gray: rgb(196, 196, 196);
    --color-neutral-gray-dark: #52575c;
    --color-primary: #3b43d0;
    --color-secondary: #6348b7;
    --color-neutral-gray-light: #cacccf;
    --color-neutral-black: #25282b;
    --color-light-black: #505050;
    --color-dark-brown: #262626;
    --color-black: #000000;
    --color-transpirent-black: rgba(0, 0, 0, 0.797);
    --color-1: #222169;
    --color-2: #c6c5fa;
    --color-3: #eaf6fd;
    --color-4: #7d2ae8;
    --color-5: #9352a4;
    --color-6: #f3fcfd;
    --color-transpirent-white: #fff;
    scroll-behavior: smooth;
    --text-color: #505050;
    --bg-color: #ffffff;
    --bg-color1: #f4f5f9;
    --border-color2: #e0e0e0;
    --delete-color:hsl(355, 96%, 55%);
    --breakpoint-xs: 350px;
    --breakpoint-sm: 600px;
    --breakpoint-md: 960px;
    --breakpoint-lg: 1280px;
    --breakpoint-xl: 1920px;
    --breakpoint-lgCustom: 1200px;
    --speed: 0.5s

  }