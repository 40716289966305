.compWrapper{
  height: 100%;
  display: flex;
  flex-direction: column;
}
.videoPlay{
  flex: 1px;
}
.buttonWrapper{
  justify-self: flex-end;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 150px;
}
.playPauseButton{
  background-color: transparent;
  height: 50px;
  width: 50px;
  border: 2px solid #000000b7;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  font-size: 2em;
}