
.imageWrapper{
    position: relative;
    width: 100%;
    height: 100%;
}
.loaderWraper{
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #999999;
}
.imageWrapper img{
    position: absolute;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
}