@import url('https://fonts.googleapis.com/css2?family=Indie+Flower&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Red+Hat+Display&display=swap');

.cardRoot{
  height: 150px;
  background-color: #ffffff;
  padding: 10px 10px;
  border-radius: 5px;
  display: flex;
  gap: 20px;
  cursor: pointer;
}
.courseCover{
  width: 120px;
  height: 100%;
}
.cardRight{
  height: 100%;
  flex: 1px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.imgPlaceHolder{
  height: 100%;
  background-color: #1e3164;
  border-radius: 5px;
  color: #FFF;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 3em;
  font-weight: 600;
  font-family: 'Indie Flower', cursive;
  text-transform: uppercase;
}
.bookCoverImage{
  width: 100%;
  object-fit: cover;
  height: 100%;
  border-radius: 7px;
}
.courseTitle{
  font-size: 1.3em;
  font-weight: 600;
  font-family: 'Red Hat Display', sans-serif;
}
.courseDescription{
  font-size: .9em;
  font-weight: 500;
  font-family: 'Red Hat Display', sans-serif;
}
.praticeQuestion{
  display: flex;
  align-items: center;
  gap: 5px;
  background-color: #2e3856;
  color: #FFF;
  padding: 3px 10px;
  border-radius: 20px;
  font-size: .8em;
  font-weight: 600;
  border: none;
}
.subjectWrapper{
  display: flex;
  align-items: center;
  gap: 5px;
  font-size: .8em;
}
.cardTopWraper{
  display: flex;
  gap: 15px;
}
.deleteBtn{
  height: 30px;
  width: 30px;
  border: 1px solid rgb(172, 20, 20);
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.2em;
  color: rgb(172, 20, 20);
  background-color: transparent;
  margin-right: 10px;
}
.badgesWrapper{
  display: flex;
  align-items: center;
  gap: 8px;
}