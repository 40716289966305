.root {
  padding: 20px 20px 10px 20px;
  margin: 1em;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
}
.frame {
  padding: 2em;
  height: 70vh;
  overflow-y: auto;
  overflow-x: hidden;
}
.title {
  margin-bottom: 20px;
}
.fluid {
  display: flex;
  gap: 20px;
  flex-wrap: wrap;
  margin-bottom: 20px;
  margin-left: 5px;
}
.formGroup {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
.difficulty {
  z-index: 100;
}
.questionType {
  z-index: 95;
}
.topic {
  z-index: 90;
}
.hasParagraph {
  z-index: 85;
}
.paragraph {
  width: 70vw;
  padding: 20px;
}
.actionGroup {
  justify-content: flex-end;
  margin-right: 30px;
}
.btn {
  border: 1px solid #444;
  padding: 5px 15px;
  cursor: pointer;
  border-radius: 4px;
  margin: 10px 0;
}
.btn:hover {
  transform: scale(1.1);
  background-color: #444;
  color: #fff;
}
/* cover image */
.image {
  position: relative;
  margin-right: 20px;
  width: 240px;
}
.coverLbl {
  position: absolute;
  top: 10px;
  left: 10px;
}
.img {
  width: 240px;
  height: 240px;
  object-fit: cover;
}
.imageUpload {
  position: absolute;
  top: 0;
  right: 15px;
  font-size: 1.5rem;
}
.imageUpload label {
  cursor: pointer;
  background-color: rgba(70, 28, 159, 0.477);
  color: #fff;
  padding: 2px 10px 5px 10px;
  border-radius: 0 0 4px 4px;
}

/* Option */
.options {
  flex: 1;
}
.optionGroup {
  display: flex;
  gap: 20px;
  align-items: center;
  padding: 20px;
  background-color: #fff;
  margin: 10px 0;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
}
.optionGroup .label {
  font-size: 1.1em;
  font-weight: bold;
}
.optionGroup .input {
  flex: 1;
  padding: 10px;
  border: solid 1px #eee;
}
.optionGroup .input:active {
  border: 1px solid #eee;
  outline: none;
}
.optionGroup input[type="checkbox"] {
  width: 30px;
  height: 30px;
  accent-color: rgb(70, 28, 159);
}
.optionGroup .deleteOption {
  font-size: 2em;
  margin-top: -6px;
  color: rgb(125, 57, 57);
  cursor: pointer;
}
.optionGroup .deleteOption:hover {
  transform: scale(0.9);
}

.questionUI {
  width: 100%;
  /* box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px; */
  position: relative;
  /* background-color: white; */
  padding: 40px;
  height: 80vh;
  overflow: auto;
}
.fullpage {
  height: 100%;
  padding: 0px;
}
.question {
  width: 100%;
}
.card {
  border: 1px solid #ddd;
  padding: 20px;
  margin: 20px;
}
.questionText {
  padding-left: 20px;
  padding-top: 40px;
}
.label {
  padding: 10px 20px;
  margin-right: 20px;
}
.check {
  background-color: green;
  color: floralwhite;
}
.actions {
  display: flex;
  justify-content: flex-end;
  gap: 20px;
}
.actions span:hover {
  transform: scale(0.9);
  cursor: pointer;
}
