.backdrop {
  height: 100vh;
  width: 100vw;
  position: fixed;
  top: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.892);
  z-index: 1000;
}
.modal {
  position: absolute;
  top: 5%;
  right: 15%;
  left: 15%;
  background-color: #f2f5f8;
  padding: 10px 20px;
}
.action {
  display: flex;
  justify-content: flex-end;
  margin-bottom: 20px;
}
.action i {
  cursor: pointer;
  font-size: 2em;
}

/* Media Queries */
@media (max-width: 1004.98px) {
  .backdrop {
    height: 100vh;
    width: 100vw;
    top: 0;
    right: 0;
  }
  .modal {
    top: 0;
    right: 0;
    left: 0;
    padding: 0;
  }
  .action {
    margin: 10px 15px;
  }
  .action i {
    font-size: 1.8em;
  }
}
