.container{
    padding: 0 20px;
}

.row {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.header_title{
    font-weight: bolder;
}