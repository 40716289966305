.pageRoot{
  padding: 2em 7%;
}
.bannerLoader{
  height: 220px;
}
.infoLoader{
  margin-top: 2em;
  height: 100px;
  margin-bottom: 2em;
}
.topicsLoader{
  height: 70px;
  margin-top: 15px;
}