.manageNoteRoot{
  padding: 20px 2em;
  height: 100%;
  display: flex;
  flex-direction: column;
}
.editorWrapper{
  background-color: var(--color-white);
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  display: flex;
  flex-direction: column;
  padding: 20px 2em;
  flex: 1px;
  overflow-y: scroll;
}
.blockTextEditor{
  flex: 1px;
  height: 100%;
}
.sendNoteBtnWrapper{
  display: flex;
  align-items: center;
  justify-content: flex-end;
  border: 1px solid #ccc;
  border-top: none;
  padding: 0 10px;
  height: 60px;
}
.loader{
  flex: 1px;
  width: 100%;
}
.actionbuttonWrapper{
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 15px;
}
.actionBtn{
  border: none;
  background-color: transparent;
  font-size: 1.5em;
}
.saveNoteButton{
  font-size: 1.2em;
  width: 40px;
  height: 25px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.noteTitleInput{
  border-bottom: none !important;
  border: 1px solid #ccc;
}
.backBtn{
  background-color: transparent;
  border: none;
  padding: 5px 15px;
  padding-left: 0px;
  margin-bottom: 1em;
  width: 100px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 6px;
}
.backBtn > *{
  font-family: 'Gantari', sans-serif;
}
.backIcon{
  font-size: 1.5em;
  font-weight: 100;
}
