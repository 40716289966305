/* General styles */
:root {
  --primary-color: rgba(53, 41, 99, 0.897);
  --secondary-color: rgba(53, 41, 99, 0.568);
  --light-color: #dae6ff;
  --accent-color: #ccab6b;
  --color1: #da7638;
  --color2: #f2ac80;
}

/* Root page */
.rootPage {
  padding: 0 15px;
  background-color: var(--primary-color);
  background-image: url(../../../../assets/png/reviewCard.png);
  background-position: right bottom;
  background-repeat: no-repeat;
  color: var(--light-color);
  height: 99.9%;
  border-radius: 15px;
  margin: 1px;
  box-shadow: rgba(147, 143, 143, 0.35) 0px 5px 15px;
}

/* Close section */
.close {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: 10px;
}
.close button {
  border: none;
  background-color: transparent;
  font-size: 1.5em;
  color: #ccc;
}

/* Content section */
.content {
  display: flex;
  position: relative;
  height: 85%;
}
.pre {
  white-space: pre-wrap;
  word-wrap: break-word;
  font-size: 1.3rem;
}
.left,
.right {
  flex: 1;
  padding: 10px;
  display: flex;
  flex-direction: column;
  height: 100%;
  gap: 20px;
}
.left h3 {
  color: var(--accent-color);
  font-weight: bold;
  margin-bottom: 10px;
}

/* Footer */
.footer {
  display: flex;
  gap: 10px;
}
.footer button {
  padding: 5px 20px;
  border: solid 2px var(--color5);
  background-color: transparent;
  color: var(--light-color);
  text-transform: uppercase;
  font-size: 1.5em;
  display: flex;
  align-items: center;
}
.footer button:hover {
  color: var(--color2);
  border: solid 2px var(--color2);
}
button.submit {
  background-color: var(--color1);
  border: solid 2px var(--color1);
  color: var(--color6);
  font-size: 0.9em;
}

button:hover.submit {
  background-color: transparent;
  border: solid 2px var(--color2);
  color: var(--color2);
  font-size: 0.9em;
}
.bucket {
  font-size: 14px;
  margin-bottom: 20px;
  display: flex;
  justify-content: space-between;
}
button.showAnswerMobile {
  display: none;
}
/* Media Queries */
@media (max-width: 767.98px) {
  .rootPage {
    padding: 0;
    height: 100%;
    border-radius: 0;
    margin: 0;
    box-shadow: none;
  }

  /* Content section */
  .content {
    display: grid;
    grid-template-columns: 1fr;
    height: 90%;
    overflow: scroll;
    /* padding: 0 0.3em; */
  }
  .left,
  .right {
    grid-column: 1/2;
    grid-row: 1;
    background-color: none;
    /* max-height: 100%; */
    /* flex: 1; */
    /* padding: 10px;
    display: flex;
    flex-direction: column;
    height: 100%;
    gap: 20px; */
  }
  .hide {
    display: none;
  }
  .left {
    padding: 0 1em;
  }
  .right {
    padding: 0;
  }
  .left h3 {
    color: var(--accent-color);
    font-weight: bold;
    margin-bottom: 10px;
  }

  .pre {
    white-space: pre-wrap;
    word-wrap: break-word;
    font-size: 1.1rem;
  }

  /* Footer */
  .footer {
    display: flex;
    gap: 10px;
  }
  .footer button {
    padding: 5px 20px;
    border: solid 2px var(--color5);
    background-color: transparent;
    color: var(--light-color);
    text-transform: uppercase;
    font-size: 1.5em;
    display: flex;
    align-items: center;
  }
  .footer button:hover {
    color: var(--color2);
    border: solid 2px var(--color2);
  }
  .footer button.showAnswerMobile {
    display: inline-block;
    padding: 5px 20px;
    font-size: 1em;
    background-color: rgb(129, 241, 131);
    color: #000;
    border: none;
  }
  .footer button:hover.showAnswerMobile {
    color: #000;
    border: none;
  }
}

@media (min-width: 768px) and (max-width: 991.98px) {
}

@media (min-width: 992px) and (max-width: 1199.98px) {
}

@media (min-width: 1200px) and (max-width: 1400px) {
}
